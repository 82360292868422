export default {
  fields: {
    amount: {
      model: "amount",
      type: "input",
      wrapper: {
        label: "申请金额"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入申请金额"
      },
      decorator: {
        rules: [{
          required: true,
          message: "必填项"
        }]
      }
    },
    loanType: {
      model: "loanType",
      type: "select",
      wrapper: {
        label: "担保方式"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择担保方式",
        options: [{
          value: 1,
          label: "信用/免担保"
        }, {
          value: 2,
          label: "抵押担保"
        }, {
          value: 3,
          label: "组合(含保证，不含保证)"
        }, {
          value: 4,
          label: "质押担保"
        }]
      },
      decorator: {
        initialValue: null
      }
    },
    deadline: {
      model: "deadline",
      type: "input",
      wrapper: {
        label: "申请期限"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入申请期限"
      },
      decorator: {
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    interestRate: {
      model: "interestRate",
      type: "input",
      wrapper: {
        label: "利率"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入利率"
      },
      decorator: {
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    loanToDo: {
      model: "loanToDo",
      type: "select",
      wrapper: {
        label: "借款用途"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择",
        options: [{
          value: 1,
          label: "一般消费"
        }, {
          value: 2,
          label: "生意周转"
        }, {
          value: 3,
          label: "创业投入"
        }, {
          value: 4,
          label: "投资"
        }, {
          value: 5,
          label: "购置资产"
        }, {
          value: 6,
          label: "其他"
        }]
      },
      decorator: {
        initialValue: null
      }
    },
    loanReason: {
      model: "loanReason",
      type: "textarea",
      wrapper: {
        label: "借款原因说明"
      },
      customize: {
        allowClear: true,
        placeholder: "借款原因说明"
      },
      decorator: {
        initialValue: null
      }
    },
    fundMeasure: {
      model: "fundMeasure",
      type: "input",
      wrapper: {
        label: "资金测算"
      },
      customize: {
        allowClear: true,
        placeholder: "资金测算"
      },
      decorator: {
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    loanAmount: {
      model: "loanAmount",
      type: "textarea",
      wrapper: {
        label: "你认为该客户授信额度度多少，说明理由"
      },
      customize: {
        allowClear: true,
        placeholder: ""
      },
      decorator: {
        initialValue: null
      }
    },
    riskControlMethod: {
      model: "riskControlMethod",
      type: "textarea",
      wrapper: {
        label: "风控控制方法1"
      },
      customize: {
        allowClear: true,
        placeholder: ""
      },
      decorator: {
        initialValue: null
      }
    },
    riskControlMethod2: {
      model: "riskControlMethod2",
      type: "textarea",
      wrapper: {
        label: "风控控制方法2"
      },
      customize: {
        allowClear: true,
        placeholder: ""
      },
      decorator: {
        initialValue: null
      }
    },
    loanToDoDetail: {
      model: "loanToDoDetail",
      type: "input",
      wrapper: {
        label: "贷款投向"
      },
      customize: {
        allowClear: true,
        placeholder: "贷款投向"
      },
      decorator: {
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    returnResource: {
      model: "returnResource",
      type: "input",
      wrapper: {
        label: "第一还款来源"
      },
      customize: {
        allowClear: true,
        placeholder: ""
      },
      decorator: {
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    operateRisk: {
      model: "operateRisk",
      type: "textarea",
      wrapper: {
        label: "经营风险"
      },
      customize: {
        allowClear: true,
        placeholder: ""
      },
      decorator: {
        initialValue: null
      }
    },
    guaranteeRisk: {
      model: "guaranteeRisk",
      type: "textarea",
      wrapper: {
        label: "抵质押物变现风险"
      },
      customize: {
        allowClear: true,
        placeholder: ""
      },
      decorator: {
        initialValue: null
      }
    },
    moralRisk: {
      model: "moralRisk",
      type: "textarea",
      wrapper: {
        label: "道德风险"
      },
      customize: {
        allowClear: true,
        placeholder: ""
      },
      decorator: {
        initialValue: null
      }
    },
    conclusion: {
      model: "conclusion",
      type: "textarea",
      wrapper: {
        label: "总结"
      },
      customize: {
        allowClear: true,
        placeholder: ""
      },
      decorator: {
        initialValue: null
      }
    }
  },
  config: {}
};