import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/web.timers";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
import { retrieveReq, customerInfos as createReq, allGroupAndFlowByCompanyUser, allCanUseByFlow, getFlowProcessByApproInfo, userAppros, orderInfos, userApprosList, listAll, fieldSetsListAll, defaultListAll, fieldRules } from "../api";
import RecordForm from "@/views/orderMange/Form.json";
import { CurdMixin } from "xqjr-plugin-common";
import { formatDate } from "@/utils/date"; // ----------------------------------------------------- 构建表格配置，包括基本项、操作

var columns = RecordForm.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    key: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
});
columns.push({
  width: 200,
  title: "操作",
  dataIndex: "crudAction",
  scopedSlots: {
    customRender: "crudAction"
  },
  type: "action",
  fixed: "right"
}); // ----------------------------------------------------- 生成 mixin

var mixin = new CurdMixin({
  retrieveReq: retrieveReq,
  createReq: createReq,
  RecordForm: RecordForm,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

export default {
  components: {},
  filters: {
    filterType: function filterType(val) {
      if (val === 1) {
        return "分销";
      } else if (val === 2) {
        return "直销";
      } else {
        return "";
      }
    }
  },
  mixins: [mixin],
  props: {},
  data: function data() {
    return {
      confirmLoading: false,
      sexList: [{
        value: 1,
        label: "男"
      }, {
        value: 2,
        label: "女"
      }],
      evelList: [{
        label: "初中及以下",
        value: 1
      }, {
        label: "高中及中等专业学校",
        value: 2
      }, {
        label: "大专",
        value: 3
      }, {
        label: "本科",
        value: 4
      }, {
        label: "硕士",
        value: 5
      }, {
        label: "博士及以上",
        value: 6
      }],
      rules: {
        productId: [{
          required: true,
          message: "请选择产品",
          trigger: "change"
        }]
      },
      confirmAlertsl: false,
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 16
      },
      isDisabled: false,
      confirmAlert: false,
      confirmAlerts: false,
      confirmAlertNo: false,
      confirmAlertNos: false,
      rejectReasonDetail: "",
      peoApplit: [],
      formData: [],
      sourceType: "",
      goodForm: {
        productId: null,
        dataList: [],
        detaileList: []
      },
      approvalInfo: [],
      option: [],
      id: "",
      pageSize: 20,
      orderInfoId: "",
      obj: {},
      productList: [],
      detailedList: [],
      ruleGroupId: null
    };
  },
  computed: {
    dynamicParams: function dynamicParams() {
      return {
        enumOptions: {
          merchantId: localStorage.getItem("lesseeId")
        }
      };
    },
    retrieveParams: function retrieveParams() {
      return {
        request: {
          approvalStatus: "未建审批"
        }
      };
    },
    createParams: function createParams() {
      return {
        approvalStatus: "未建审批",
        channelId: localStorage.getItem("lesseeId"),
        source_type: 2,
        dispose: 1
      };
    }
  },
  created: function created() {
    var _this = this;

    listAll().then(function (res) {
      _this.productList = res.data;
    });
  },
  mounted: function mounted() {},
  methods: {
    detailed: function detailed(record) {
      this.confirmAlertsl = true;
      this.detailedList = record;
    },
    showEditModals: function showEditModals() {
      var _this2 = this;

      this.confirmAlertNos = true;
      this.productId = null;
      this.goodForm.dataList = [];
      this.goodForm.detaileList = [];
      this.obj = {};
      this.$nextTick(function () {
        _this2.$refs["ruleForm"].resetFields();
      });
      this.goodForm.dataList.map(function (item) {
        return item.value = "";
      });
    },
    confirmHandleCreate: function confirmHandleCreate() {
      var _this3 = this;

      this.confirmLoading = true;
      setTimeout(function () {
        _this3.confirmLoading = false;
      }, 2000);

      if (this.goodForm.dataList.length !== 0) {
        this.goodForm.dataList.map(function (item) {
          var list = [];
          list.push(item.fieldEnglishName);

          for (var _i = 0, _list = list; _i < _list.length; _i++) {
            var value = _list[_i];
            _this3.obj[value] = item.value;
          }
        });
      } else {
        this.goodForm.detaileList.map(function (item) {
          var list = [];
          list.push(item.fieldEnglishName);

          for (var _i2 = 0, _list2 = list; _i2 < _list2.length; _i2++) {
            var value = _list2[_i2];
            _this3.obj[value] = item.value;
          }
        });
      }

      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this3.obj.productId = _this3.goodForm.productId;
          var channelId = localStorage.getItem("lesseeId");
          _this3.obj.channelId = channelId;
          createReq(_this3.obj).then(function (res) {
            _this3.$message.success("操作成功");

            _this3.confirmAlertNos = false;

            _this3.retrieveRecord();
          });
        } else {
          return false;
        }
      });
    },
    handleChange: function handleChange() {},
    handleChange1: function handleChange1() {},
    handleChange2: function handleChange2(e) {
      var _this4 = this;

      if (e === undefined) {
        this.goodForm.dataList = [];
        this.goodForm.detaileList = [];
        this.$message.error("请选择产品");
      } else {
        var id = e;
        this.goodForm.dataList = [];
        this.productList.map(function (item) {
          if (item.id === id) {
            _this4.ruleGroupId = item.ruleGroup;
          }
        });
        fieldRules({
          ruleGroupId: this.ruleGroupId
        }).then(function (res) {
          if (res.data.length !== 0) {
            _this4.goodForm.dataList = res.data;

            _this4.goodForm.dataList.map(function (item) {
              _this4.$set(item, "value", null);
            });
          } else {
            defaultListAll().then(function (res) {
              _this4.goodForm.detaileList = res.data;

              _this4.goodForm.detaileList.map(function (item) {
                _this4.$set(item, "value", null);
              });
            });
          }
        });
      }
    },
    // 拒绝审批
    noSumbit: function noSumbit(record) {
      this.confirmAlertNo = true;
      this.rejectReasonDetail = "";
      this.orderInfoId = record.id;
    },
    confirmHandleNo: function confirmHandleNo() {
      var _this5 = this;

      if (this.rejectReasonDetail !== "") {
        var no = {
          id: this.orderInfoId,
          approvalStatus: "拒绝该审批",
          auditStatus: 4,
          rejectReasonDetail: this.rejectReasonDetail
        };
        orderInfos(no).then(function (res) {
          _this5.$message.success("操作成功");

          _this5.confirmAlertNo = false;

          _this5.retrieveRecord();
        });
      } else {
        this.$message.error("请输入拒绝原因");
      }
    },
    // 提交审批
    sumbit: function sumbit(record) {
      var _this6 = this;

      this.confirmAlerts = true;
      this.orderInfoId = record.id;
      this.isDisabled = false;
      allCanUseByFlow(record.approveId).then(function (res) {
        _this6.formData = res.data;
      });
    },
    // 获取审批分组
    // getGroups() {
    //   this.peoApplit = [];
    //   const companyId = localStorage.getItem("lesseeId");
    //   allGroupAndFlowByCompanyUser(`${companyId}`, `${process.env.VUE_APP_SUBJECT_TYPE}`).then(res => {
    //     res.data.forEach(item => {
    //       if (item.approFlowList.length >= 1) {
    //         this.peoApplit.push(item);
    //         this.id = this.peoApplit[0].approFlowList[0].id;
    //       }
    //     });
    //     if (this.id) {
    //       allCanUseByFlow(this.id).then(res => {
    //         this.formData = res.data;
    //       });
    //     }
    //   });
    // },
    // 提交审批
    confirmHandles: function confirmHandles() {
      var _this7 = this;

      this.isDisabled = true;
      setTimeout(function () {
        _this7.isDisabled = false;
      }, 5000);
      this.approvalInfo = this.formData.map(function (val) {
        return {
          formId: val.id,
          value: val.value
        };
      });
      var order = {
        id: this.orderInfoId,
        approvalStatus: "已建审批未领办"
      };
      orderInfos(order).then(function (res) {
        if (res.code === 200) {
          getFlowProcessByApproInfo({
            moduleType: process.env.VUE_APP_SUBJECT_TYPE,
            flowId: _this7.formData[0].flowId,
            userApproInfoList: _this7.approvalInfo
          }).then(function (res) {
            if (res.success) {
              _this7.submit = false;
              _this7.flowNodeList = res.data;
              _this7.flowId = res.data[0].flowId;
              _this7.rebackData = res.data;
              var userApproAddDTO = {
                moduleType: process.env.VUE_APP_SUBJECT_TYPE,
                flowNodeList: _this7.rebackData,
                flowId: _this7.flowId,
                props: _this7.orderInfoId,
                userApproInfoList: _this7.approvalInfo
              };
              userAppros(userApproAddDTO).then(function (data) {
                if (data.code === 200) {
                  var add = {
                    userApproId: data.data.id,
                    surveyReceive: 2,
                    presidentReceive: 2,
                    managerReceive: 2
                  };
                  userApprosList(add).then(function (res) {
                    if (res.code === 200) {
                      _this7.confirmAlerts = false;

                      _this7.$message.success("提交成功");

                      _this7.retrieveRecord(); // const order = {
                      //   id: this.orderInfoId,
                      //   approvalStatus: "已建审批未领办"
                      // };
                      // orderInfos(order).then(res => {
                      //   this.confirmAlerts = false;
                      //   this.$message.success("提交成功");
                      //   this.retrieveRecord();
                      // });

                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }
};