var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setpage" },
    [
      _c(
        "a-card",
        { attrs: { size: "small", title: "模板", bordered: false } },
        [
          _c(
            "a-button",
            {
              attrs: { slot: "extra", type: "primary", size: "small" },
              on: { click: _vm.caertaImg1 },
              slot: "extra"
            },
            [_vm._v(" 添加 ")]
          ),
          _c(
            "div",
            { staticClass: "prolist temlist" },
            _vm._l(_vm.proTemplist, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "template" },
                [
                  _c("img", { attrs: { src: item.background, alt: "" } }),
                  _c("a-icon", {
                    staticClass: "close",
                    attrs: { type: "close" },
                    on: {
                      click: function($event) {
                        return _vm.deleteTemplate(item)
                      }
                    }
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(item.name !== null ? item.name : "模板" + index)
                    )
                  ])
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "a-card",
        { attrs: { size: "small", title: "h5展示页设置", bordered: false } },
        [
          _c(
            "a-button",
            {
              attrs: { slot: "extra", type: "primary", size: "small" },
              on: { click: _vm.caertaImg },
              slot: "extra"
            },
            [_vm._v(" 添加 ")]
          ),
          _c(
            "a-button",
            {
              directives: [{ name: "onceClick", rawName: "v-onceClick" }],
              staticStyle: { "margin-left": "20px" },
              attrs: { slot: "extra", type: "primary", size: "small" },
              on: { click: _vm.saveHtmlPage },
              slot: "extra"
            },
            [_vm._v(" 保存 ")]
          ),
          _c(
            "a-button",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { slot: "extra", type: "primary", size: "small" },
              on: { click: _vm.resetting },
              slot: "extra"
            },
            [_vm._v(" 重置 ")]
          ),
          _c(
            "a-radio-group",
            {
              staticClass: "prolist temlist",
              model: {
                value: _vm.enableH5Template,
                callback: function($$v) {
                  _vm.enableH5Template = $$v
                },
                expression: "enableH5Template"
              }
            },
            _vm._l(_vm.htmlPagelist, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "template" },
                [
                  _c("img", { attrs: { src: item.background, alt: "" } }),
                  _c("a-icon", {
                    staticClass: "close",
                    attrs: { type: "close" },
                    on: {
                      click: function($event) {
                        return _vm.deletePageList(item)
                      }
                    }
                  }),
                  _c(
                    "a-radio",
                    { style: _vm.radioStyle, attrs: { value: item.id } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            item.name !== null ? item.name : "模板" + index
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            visible: _vm.proTempVisible,
            destroyOnClose: true,
            title: "添加推广模板"
          },
          on: {
            cancel: function($event) {
              _vm.proTempVisible = false
            }
          }
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.addTempForm,
                labelCol: { span: 6 },
                wrapperCol: { span: 14 }
              }
            },
            [
              _c(
                "a-form-item",
                { attrs: { colon: false, label: "模板名称: " } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "name",
                          { rules: [{ required: true, message: "请输入" }] }
                        ],
                        expression:
                          "['name', { rules: [{ required: true, message: '请输入' }] }]"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { colon: false, label: "模板图片: " } },
                [
                  _c(
                    "div",
                    { staticClass: "clearfix" },
                    [
                      _c(
                        "a-upload",
                        {
                          attrs: {
                            customRequest: function(p) {
                              return _vm.uploadFiles(p, "productphoto", true)
                            },
                            listType: "picture-card",
                            fileList: _vm.photoList
                          },
                          on: {
                            preview: _vm.handlePreview,
                            change: _vm.handleChange
                          }
                        },
                        [
                          _vm.photoList.length < 1
                            ? _c(
                                "div",
                                [_c("a-icon", { attrs: { type: "plus" } })],
                                1
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "a-modal",
                        {
                          attrs: {
                            maskClosable: false,
                            visible: _vm.previewVisible,
                            footer: null
                          },
                          on: { cancel: _vm.handleCancel }
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { alt: "example", src: _vm.previewImage }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("XqModalFooter", {
            attrs: { slot: "footer" },
            on: {
              ok: _vm.addTemplate,
              cancel: function($event) {
                _vm.proTempVisible = false
              }
            },
            slot: "footer"
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            visible: _vm.proTempVisibles,
            destroyOnClose: true,
            title: "添加展示页"
          },
          on: {
            cancel: function($event) {
              _vm.proTempVisibles = false
            }
          }
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.addTempForm,
                labelCol: { span: 6 },
                wrapperCol: { span: 14 }
              }
            },
            [
              _c(
                "a-form-item",
                { attrs: { colon: false, label: "展示名称: " } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "name",
                          { rules: [{ required: true, message: "请输入" }] }
                        ],
                        expression:
                          "['name', { rules: [{ required: true, message: '请输入' }] }]"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { colon: false, label: "展示图片: " } },
                [
                  _c(
                    "div",
                    { staticClass: "clearfix" },
                    [
                      _c(
                        "a-upload",
                        {
                          attrs: {
                            customRequest: function(p) {
                              return _vm.uploadFiles(p, "productphoto", true)
                            },
                            listType: "picture-card",
                            fileList: _vm.photoList
                          },
                          on: {
                            preview: _vm.handlePreview,
                            change: _vm.handleChange
                          }
                        },
                        [
                          _vm.photoList.length < 1
                            ? _c(
                                "div",
                                [_c("a-icon", { attrs: { type: "plus" } })],
                                1
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "a-modal",
                        {
                          attrs: {
                            maskClosable: false,
                            visible: _vm.previewVisible,
                            footer: null
                          },
                          on: { cancel: _vm.handleCancel }
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { alt: "example", src: _vm.previewImage }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("XqModalFooter", {
            attrs: { slot: "footer" },
            on: {
              ok: _vm.addTemplateImg,
              cancel: function($event) {
                _vm.proTempVisibles = false
              }
            },
            slot: "footer"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }