import request from "@/utils/request";
export function uploadGeneralImg(data) {
  return request({
    url: "/file/api/v1/general/uploadImg",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
} // 需要登录不校验权限的图片上传
// 上传图片

export function uploadFile(data) {
  return request({
    url: "/file/api/v1/general/uploadImg",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}
export function uploadGeneralFile(data) {
  return request({
    url: "/file/api/v1/general/upload/attachment",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}
export function uploadSiteImg(data) {
  return request({
    url: "/file/api/v1/site/uploadImg",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}
export function uploadSiteFile(data) {
  return request({
    url: "/file/api/v1/site/upload/attachment",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}