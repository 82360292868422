import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
import { listUserApprosApprovedByReceiverMe as retrieveReq, search } from "./api";
import { formatDate } from "./date";
import RecordForm from "./FormSetting.json";
import { CurdMixin } from "xqjr-plugin-common";
import IDCardCell from "xqjr-plugin-common/AutoGenerate/TableCell/IDCardCell";
import axios from "axios"; // ----------------------------------------------------- 构建表格配置，包括基本项、操作

var columns = RecordForm.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
});
columns.push({
  width: 80,
  title: "操作",
  dataIndex: "crudAction",
  scopedSlots: {
    customRender: "crudAction"
  },
  type: "action",
  fixed: "right"
}); // ----------------------------------------------------- 生成 mixin

var mixin = new CurdMixin({
  retrieveReq: retrieveReq,
  RecordForm: RecordForm,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

export default {
  components: {},
  filters: {
    filterType: function filterType(val) {
      if (val === 1) {
        return "分销";
      } else if (val === 2) {
        return "直销";
      } else {
        return "";
      }
    }
  },
  mixins: [mixin],
  props: {},
  data: function data() {
    return {
      name: "",
      phone: "",
      pageSize: 20
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          moduleType: process.env.VUE_APP_SUBJECT_TYPE,
          hasAppro: false
        }
      };
    }
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // 查看审批节点
    statusDetail: function statusDetail(record, status) {
      this.$router.push("/Customer/".concat(record.orderInfoVO.customInfoId, "/").concat(record.orderInfoVO.id, "/").concat(record.userApproNodeInfoVO.userApproId, "/").concat(record.userApproNodeInfoVO.userApproverId, "/").concat(status));
    },
    serchEnter: function serchEnter() {
      var _this = this;

      var params = {
        moduleType: process.env.VUE_APP_SUBJECT_TYPE,
        hasAppro: false,
        customerName: this.name,
        phone: this.phone
      };
      search(params).then(function (res) {
        _this.tableData = res.data.entities;
        _this.total = _this.tableData.length;
      });
    }
  }
};