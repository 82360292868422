var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { staticClass: "xq-auto-wrapper", attrs: { spinning: _vm.spinning } },
    [
      _c(
        "div",
        { staticClass: "xq-auto-search" },
        [
          _vm._v(" 所属员工 : "),
          _c("a-input", {
            staticStyle: {
              width: "200px",
              "margin-right": "20px",
              "margin-bottom": "20px"
            },
            attrs: { placeholder: "请输入", allowClear: "" },
            on: { change: _vm.serchEnter },
            model: {
              value: _vm.userName,
              callback: function($$v) {
                _vm.userName = $$v
              },
              expression: "userName"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "xq-auto-action" }, [
        _c(
          "div",
          { staticClass: "xq-auto-action-left" },
          [
            _vm.RecordForm.config.customize.batch
              ? _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.showEditModal()
                      }
                    }
                  },
                  [_vm._v(" 生成推广码 ")]
                )
              : _vm._e(),
            _vm.RecordForm.config.customize.batch
              ? _c(
                  "a-popconfirm",
                  {
                    attrs: {
                      disabled: _vm.selectedRowKeys.length <= 0,
                      title: "确认要删除选中的记录吗？",
                      okText: "确认",
                      cancelText: "取消"
                    },
                    on: {
                      confirm: function($event) {
                        return _vm.deleteBatchRecord()
                      }
                    }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          disabled: _vm.selectedRowKeys.length <= 0,
                          type: "primary"
                        }
                      },
                      [_vm._v(" 批量删除 ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "xq-auto-content" },
        [
          _c(
            "a-table",
            {
              staticClass: "xq-auto-content-table",
              attrs: {
                columns: _vm.columns,
                "data-source": _vm.tableData,
                pagination: _vm.pagination,
                rowSelection: _vm.RecordForm.config.customize.batch
                  ? {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onSelectChange
                    }
                  : null,
                rowKey: "id"
              },
              on: { change: _vm.onTableChange },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.columns, function(col, index) {
                    return {
                      key: col.dataIndex,
                      fn: function(text, record) {
                        return [
                          col.dataIndex === "crudAction"
                            ? _c(
                                "span",
                                { key: index },
                                [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:copy",
                                          value:
                                            _vm.uploadUrl + record.extendLink,
                                          expression:
                                            "uploadUrl + record.extendLink",
                                          arg: "copy"
                                        },
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:success",
                                          value: _vm.onCopy,
                                          expression: "onCopy",
                                          arg: "success"
                                        },
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:error",
                                          value: _vm.onError,
                                          expression: "onError",
                                          arg: "error"
                                        }
                                      ]
                                    },
                                    [_vm._v("复制")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" }
                                  }),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.checkReq(record)
                                        }
                                      }
                                    },
                                    [_vm._v("预览")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" }
                                  }),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.showEditModal(record)
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  )
                                ],
                                1
                              )
                            : col.dataIndex === "userId"
                            ? _c(
                                "span",
                                {
                                  key: index,
                                  staticClass:
                                    "xq-auto-content-table-cell clickable"
                                },
                                [_vm._v(" " + _vm._s(record.userName))]
                              )
                            : col.dataIndex === "extendLink"
                            ? _c(
                                "span",
                                {
                                  key: index,
                                  staticClass:
                                    "xq-auto-content-table-cell clickable",
                                  staticStyle: { width: "100%" }
                                },
                                [_vm._v(" " + _vm._s(text) + " ")]
                              )
                            : col.dataIndex === "enabled"
                            ? _c(
                                "span",
                                {
                                  key: index,
                                  staticClass:
                                    "xq-auto-content-table-cell clickable"
                                },
                                [
                                  _c("a-switch", {
                                    attrs: {
                                      checkedChildren: "是",
                                      unCheckedChildren: "否",
                                      defaultChecked: record.enabled
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeUsed($event, record)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("table-cell", {
                                key: index,
                                attrs: {
                                  text: text,
                                  record: record,
                                  column: col,
                                  dynamicData: _vm.dynamicData
                                }
                              })
                        ]
                      }
                    }
                  })
                ],
                null,
                true
              )
            },
            [
              _vm._l(_vm.columns, function(col, index) {
                return _c("template", { slot: "custom_" + col.dataIndex }, [
                  _c(
                    "span",
                    { key: index },
                    [
                      col.help
                        ? _c(
                            "a-tooltip",
                            { attrs: { title: col.help } },
                            [
                              _c("a-icon", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { type: "question-circle" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" " + _vm._s(col.customTitle) + " ")
                    ],
                    1
                  )
                ])
              })
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "xq-auto-content-pagination" },
            [
              _c("div", { staticClass: "xq-auto-content-pagination-total" }, [
                _vm._v("共 " + _vm._s(_vm.total) + " 条")
              ]),
              _c("a-pagination", {
                attrs: {
                  pageSize: _vm.pageSize,
                  total: _vm.total,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ["20", "50", "100"]
                },
                on: {
                  change: _vm.onPageChange,
                  showSizeChange: _vm.onPageSizeChange
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                title: _vm.editModalType === "create" ? "创建" : "编辑",
                width: 900
              },
              on: { cancel: _vm.editCancel, ok: _vm.editOk },
              model: {
                value: _vm.editModalVisible,
                callback: function($$v) {
                  _vm.editModalVisible = $$v
                },
                expression: "editModalVisible"
              }
            },
            [
              _vm.jsonData
                ? _c("k-form-build", {
                    ref: "kfb",
                    attrs: {
                      value: _vm.jsonData,
                      dynamicData: _vm.dynamicData
                    },
                    on: {
                      change: function($event) {
                        return _vm.onFormChange(
                          arguments[0],
                          arguments[1],
                          "kfb"
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                destroyOnClose: "",
                width: "368px",
                top: "100px",
                title: "提示",
                visible: _vm.updateLinkVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.updateLinkVisible = $event
                },
                cancel: _vm.cancel
              }
            },
            [
              _c("p", [_vm._v("是否确定更新推广链接？")]),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("a-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "a-button",
                    {
                      staticClass: "adjust-button",
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmUpdateLink }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                destroyOnClose: "",
                top: "100px",
                title: "预览",
                visible: _vm.checkVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.checkVisible = $event
                },
                cancel: _vm.cancel
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "300px",
                    height: "600px",
                    "margin-left": "18%"
                  },
                  attrs: { id: "qrbox" }
                },
                [
                  _c("img", {
                    staticClass: "imgs",
                    staticStyle: { width: "300px", height: "600px" },
                    attrs: { src: _vm.recordBackground }
                  }),
                  _c("div", { attrs: { id: "qrcode" } })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("a-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "a-button",
                    {
                      staticClass: "adjust-button",
                      attrs: { type: "primary" },
                      on: { click: _vm.savecanvas }
                    },
                    [_vm._v(" 下载 ")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                title: "批量修改模板",
                width: 540,
                visible: _vm.batchEditVisible
              }
            },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.batchEditForm, rules: _vm.rules }
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "模板: ", prop: "background" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "a-select",
                            {
                              model: {
                                value: _vm.batchEditForm.background,
                                callback: function($$v) {
                                  _vm.$set(_vm.batchEditForm, "background", $$v)
                                },
                                expression: "batchEditForm.background"
                              }
                            },
                            _vm._l(_vm.templetelist, function(temp, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: temp.id } },
                                [_vm._v(" " + _vm._s(temp.name) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("a-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "a-button",
                    {
                      staticClass: "adjust-button",
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmBatchEdit }
                    },
                    [_vm._v(" 确定 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }