import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.to-fixed";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { statiStics } from "./api";
export default {
  data: function data() {
    return {
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false,
      chenkoutNum1: [],
      chenkoutNum2: [],
      chenkoutNum3: [],
      chenkoutNum4: [],
      groupOrder: {},
      individualOrder: {},
      dataList: [],
      order: [],
      getTime: [],
      groupOrderList: [],
      groupMoneyList: [],
      manyIndividualMoneyList: [],
      manyIndividualOrderList: [],
      day: 0,
      countNum1: 0,
      countNum2: 0,
      countNum3: 0,
      countNum4: 0,
      columns: [{
        title: "序号",
        dataIndex: "id",
        key: "id"
      }, {
        title: "名称",
        dataIndex: "name",
        key: "name"
      }, {
        title: " 百分比",
        dataIndex: "num",
        key: "num",
        scopedSlots: {
          customRender: "num"
        }
      }, {
        title: "数值",
        dataIndex: "value",
        key: "value"
      }]
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.createList();
  },
  methods: {
    day1: function day1() {
      this.statiList(15);
    },
    day2: function day2() {
      this.statiList(30);
    },
    day3: function day3() {
      this.statiList(0);
    },
    statiList: function statiList(day) {
      var _this = this;

      statiStics({
        day: day
      }).then(function (res) {
        if (res.code === 200) {
          var trendDayMoney = res.data.trendDayMoney;
          var trendDayOrder = res.data.trendDayOrder;
          _this.dataList = [];
          _this.getTime = [];
          _this.order = [];
          trendDayMoney.map(function (item) {
            _this.dataList.unshift(item.money);

            _this.getTime.unshift(item.day);
          });
          trendDayOrder.map(function (item) {
            _this.order.unshift(item.num);
          });

          _this.$nextTick(function () {
            _this.myChart();
          });
        }
      });
    },
    createList: function createList() {
      var _this2 = this;

      statiStics({
        day: this.day
      }).then(function (res) {
        if (res.code === 200) {
          _this2.groupOrder = res.data.groupOrder;
          _this2.individualOrder = res.data.individualOrder;
          var trendDayMoney = res.data.trendDayMoney;
          var trendDayOrder = res.data.trendDayOrder;
          var manyGroupOrder = res.data.manyGroupOrder;
          var manyGroupMoney = res.data.manyGroupMoney;
          var manyIndividualMoney = res.data.manyIndividualMoney;
          var manyIndividualOrder = res.data.manyIndividualOrder;
          trendDayMoney.map(function (item) {
            _this2.dataList.unshift(item.money);

            _this2.getTime.unshift(item.day);
          });
          trendDayOrder.map(function (item) {
            _this2.order.unshift(item.num);
          });
          manyGroupOrder.map(function (item, index) {
            _this2.chenkoutNum1.push({
              name: item.name,
              value: item.num,
              num: null,
              id: index + 1
            });

            _this2.countNum1 += item.num;

            if (index < 5) {
              _this2.groupOrderList.push({
                name: item.name,
                value: item.num
              });
            }
          });
          manyGroupMoney.map(function (item, index) {
            _this2.chenkoutNum2.push({
              name: item.name,
              value: item.num,
              num: null,
              id: index + 1
            });

            _this2.countNum2 += item.num;

            if (index < 5) {
              _this2.groupMoneyList.push({
                name: item.name,
                value: item.num
              });
            }
          });

          if (manyIndividualMoney == null || manyIndividualMoney.length === 0) {
            _this2.manyIndividualMoneyList = [];
          } else {
            manyIndividualMoney.map(function (item, index) {
              _this2.chenkoutNum4.push({
                name: item.name,
                value: item.money,
                num: null,
                id: index + 1
              });

              _this2.countNum4 += item.money;

              if (index < 5) {
                _this2.manyIndividualMoneyList.push({
                  name: item.name,
                  value: item.money
                });
              }
            });
          }

          if (manyIndividualOrder == null || manyIndividualOrder.length === 0) {
            _this2.manyIndividualOrderList = [];
          } else {
            manyIndividualOrder.map(function (item, index) {
              _this2.chenkoutNum3.push({
                name: item.name,
                value: item.num,
                num: null,
                id: index + 1
              });

              _this2.countNum3 += item.num;

              if (index < 5) {
                _this2.manyIndividualOrderList.push({
                  name: item.name,
                  value: item.num
                });
              }
            });
          }

          _this2.myChart(); // this.myChart1();
          // this.myChart2();


          _this2.myChart3();

          _this2.myChart4();
        }
      });
    },
    myChart: function myChart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("myChart"));
      var option = {
        color: ["#FF8C4B", "#1890FF"],
        // 通过color修改颜色
        tooltip: {
          trigger: "axis"
        },
        legend: {
          top: "5%",
          right: "10%"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          borderColor: "#cccccc",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.getTime
        },
        yAxis: [{
          type: "value",
          position: "left",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: "#cccccc",
              type: "dashed"
            }
          }
        }, {
          type: "value",
          position: "right",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: "#cccccc",
              type: "dashed"
            }
          }
        }],
        series: [{
          name: "每日单量",
          type: "line",
          stack: "总量",
          yAxisIndex: 1,
          data: this.order
        }, {
          name: "每日放款额",
          type: "line",
          yAxisIndex: 0,
          stack: "总量",
          data: this.dataList
        }]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    myChart1: function myChart1() {
      var myChart1 = this.$echarts.init(document.getElementById("myChart1"));
      var option = {
        title: {
          text: "渠道总单量统计",
          left: 130
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          top: 50,
          right: 40,
          itemWidth: 10,
          itemHeight: 10,
          formatter: function formatter(params) {
            var data = option.series[0].data;
            var total = 0;
            var tarValue = 0;

            for (var i = 0, l = data.length; i < l; i++) {
              total += data[i].value;

              if (data[i].name === params) {
                tarValue = data[i].value;
              }
            }

            var p = (tarValue / total * 100).toFixed(2);

            if (tarValue === 0) {
              return params + " |" + " " + 0 + "%" + " | " + tarValue;
            } else {
              return params + " |" + " " + p + "%" + " | " + tarValue;
            }
          }
        },
        series: [{
          name: "访问来源",
          type: "pie",
          color: ["#1890FF", "#FAD337", "#F04864", "#6DD8D8", "#ffc0cb"],
          radius: ["55%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center"
          },
          labelLine: {
            show: false
          },
          data: this.groupOrderList
        }]
      };
      myChart1.setOption(option);
      window.addEventListener("resize", function () {
        myChart1.resize();
      });
    },
    myChart2: function myChart2() {
      var myChart2 = this.$echarts.init(document.getElementById("myChart2"));
      var option = {
        title: {
          text: "渠道放款额统计",
          left: 40
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          top: 50,
          right: 80,
          itemWidth: 10,
          itemHeight: 10,
          formatter: function formatter(params) {
            var data = option.series[0].data;
            var total = 0;
            var tarValue = 0;

            for (var i = 0, l = data.length; i < l; i++) {
              total += data[i].value;

              if (data[i].name === params) {
                tarValue = data[i].value;
              }
            }

            var p = (tarValue / total * 100).toFixed(2);

            if (tarValue === 0) {
              return params + " |" + " " + 0 + "%" + " | " + tarValue;
            } else {
              return params + " |" + " " + p + "%" + " | " + tarValue;
            }
          }
        },
        series: [{
          name: "访问来源",
          type: "pie",
          color: ["#1890FF", "#FAD337", "#F04864", "#6DD8D8", "#ffc0cb"],
          radius: ["55%", "70%"],
          center: ["37%", "50%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center"
          },
          labelLine: {
            show: false
          },
          data: this.groupMoneyList
        }]
      };
      myChart2.setOption(option);
      window.addEventListener("resize", function () {
        myChart2.resize();
      });
    },
    myChart3: function myChart3() {
      var myChart3 = this.$echarts.init(document.getElementById("myChart3"));
      var option = {
        title: {
          text: "个人单量统计",
          left: 130
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          top: 50,
          right: 35,
          itemWidth: 10,
          itemHeight: 10,
          formatter: function formatter(params) {
            var data = option.series[0].data;
            var total = 0;
            var tarValue = 0;

            for (var i = 0, l = data.length; i < l; i++) {
              total += data[i].value;

              if (data[i].name === params) {
                tarValue = data[i].value;
              }
            }

            var p = (tarValue / total * 100).toFixed(2);

            if (tarValue === 0) {
              return params + " |" + " " + 0 + "%" + " | " + tarValue;
            } else {
              return params + " |" + " " + p + "%" + " | " + tarValue;
            }
          }
        },
        series: [{
          name: "访问来源",
          type: "pie",
          color: ["#1890FF", "#FAD337", "#F04864", "#6DD8D8", "#ffc0cb"],
          radius: ["55%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center"
          },
          labelLine: {
            show: false
          },
          data: this.manyIndividualOrderList
        }]
      };
      myChart3.setOption(option);
      window.addEventListener("resize", function () {
        myChart3.resize();
      });
    },
    myChart4: function myChart4() {
      var myChart4 = this.$echarts.init(document.getElementById("myChart4"));
      var option = {
        title: {
          text: "个人放款额统计",
          left: 40
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          top: 50,
          right: 80,
          itemWidth: 10,
          itemHeight: 10,
          formatter: function formatter(params) {
            var data = option.series[0].data;
            var total = 0;
            var tarValue = 0;

            for (var i = 0, l = data.length; i < l; i++) {
              total += data[i].value;

              if (data[i].name === params) {
                tarValue = data[i].value;
              }
            }

            var p = (tarValue / total * 100).toFixed(2);

            if (tarValue === 0) {
              return params + " |" + " " + 0 + "%" + " | " + tarValue;
            } else {
              return params + " |" + " " + p + "%" + " | " + tarValue;
            }
          }
        },
        series: [{
          name: "访问来源",
          type: "pie",
          color: ["#1890FF", "#FAD337", "#F04864", "#6DD8D8", "#ffc0cb"],
          radius: ["55%", "70%"],
          center: ["37%", "50%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center"
          },
          labelLine: {
            show: false
          },
          data: this.manyIndividualMoneyList
        }]
      };
      myChart4.setOption(option);
      window.addEventListener("resize", function () {
        myChart4.resize();
      });
    }
  }
};