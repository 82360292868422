import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { ruleGroupsListAll, fieldSetsListAll, fieldSetsCreate, fieldRules, fieldRulesDelete, fieldSetsUpdate } from "../api";
import newGroup from "./new-groups";
export default {
  components: {
    newGroup: newGroup
  },
  filters: {
    filterType: function filterType(val) {
      if (val === "1") {
        return "初中及以下";
      } else if (val === "2") {
        return "高中及中等专业学校";
      } else if (val === "3") {
        return "大专";
      } else if (val === "4") {
        return "本科";
      } else if (val === "5") {
        return "硕士";
      } else if (val === "6") {
        return "博士及以上";
      } else {
        return "";
      }
    },
    filterTypeSex: function filterTypeSex(val) {
      if (val === "1") {
        return "男";
      } else if (val === "2") {
        return "女";
      } else {
        return "";
      }
    }
  },
  data: function data() {
    return {
      data: [],
      newGroupOptions: null,
      visible: false,
      groupList: [],
      fieldList: [],
      type: 0,
      id: "",
      ruleGroupId: "",
      title: "",
      fieldName: "",
      changeType: 0,
      num: "",
      selectedKeys: [],
      show: true,
      list: [{
        name: "范围",
        id: 1
      }, {
        name: "枚举",
        id: 2
      }],
      evelList: [{
        name: "初中及以下",
        id: "1"
      }, {
        name: "高中及中等专业学校",
        id: "2"
      }, {
        name: "大专",
        id: "3"
      }, {
        name: "本科",
        id: "4"
      }, {
        name: "硕士",
        id: "5"
      }, {
        name: "博士及以上",
        id: "6"
      }],
      sexList: [{
        name: "男",
        id: "1"
      }, {
        name: "女",
        id: "2"
      }],
      paginationOpt: {
        current: 1,
        // 默认当前页数
        pageSize: 10,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        } // 显示总数

      },
      form: this.$form.createForm(this, {
        name: "coordinated"
      })
    };
  },
  created: function created() {
    this.group();
    this.field();
    this.getAllList();
  },
  methods: {
    // 获取总的列表
    getAllList: function getAllList() {
      var _this = this;

      this.ruleGroupId = "";
      this.num = "";
      fieldRules({
        fieldName: this.fieldName,
        pageSize: this.paginationOpt.pageSize,
        pageNo: this.paginationOpt.current
      }).then(function (res) {
        if (res.code === 200) {
          _this.data = res.data.entities;
          _this.paginationOpt.total = res.data.entityCount;
        }
      });
    },
    // 获取每个产品下的列表
    getApprovalList: function getApprovalList(item, index) {
      var _this2 = this;

      this.paginationOpt.current = 1;
      this.paginationOpt.pageSize = 10;
      this.num = item.id;
      this.fieldName = "";
      this.ruleGroupId = item.id;
      fieldRules({
        ruleGroupId: this.ruleGroupId,
        pageSize: this.paginationOpt.pageSize,
        pageNo: this.paginationOpt.current
      }).then(function (res) {
        if (res.code === 200) {
          _this2.data = res.data.entities;
          _this2.paginationOpt.total = res.data.entityCount;
        }
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      var _this3 = this;

      if (this.ruleGroupId === "") {
        this.paginationOpt.current = pagination.current;
        this.paginationOpt.pageSize = pagination.pageSize;
        this.getAllList();
      } else {
        this.paginationOpt.current = pagination.current;
        this.paginationOpt.pageSize = pagination.pageSize;
        fieldRules({
          ruleGroupId: this.ruleGroupId,
          pageSize: pagination.pageSize,
          pageNo: pagination.current
        }).then(function (res) {
          if (res.code === 200) {
            _this3.data = res.data.entities;
            _this3.paginationOpt.total = res.data.entityCount;
          }
        });
      }
    },
    // 获取分组列表
    group: function group() {
      var _this4 = this;

      ruleGroupsListAll().then(function (res) {
        _this4.groupList = res.data;
      });
    },
    // 查询字段设置
    field: function field() {
      var _this5 = this;

      fieldSetsListAll().then(function (res) {
        if (res.code === 200) {
          _this5.fieldList = res.data;
          _this5.fieldList = _this5.fieldList.filter(function (res) {
            return res.rule !== false;
          });
        }
      });
    },
    // 新增规则
    createApproval: function createApproval() {
      this.show = true;
      this.visible = true;
      this.title = "新增";
      this.type = 0;
      this.form.resetFields();
    },
    handleSelectChange: function handleSelectChange(value) {
      this.type = value;
    },
    handleSelectChange1: function handleSelectChange1(value) {
      this.changeType = value;
    },
    // 编辑字段
    updateRules: function updateRules(text) {
      var _this6 = this;

      this.show = false;
      this.visible = true;
      this.title = "编辑";
      this.id = text.id;
      this.type = text.type;

      if (text.fieldName === "学历") {
        this.changeType = 14;
      } else if (text.fieldName === "性别") {
        this.changeType = 2;
      } else {
        this.changeType = 0;
      }

      this.$nextTick(function () {
        if (text.professions === null) {
          _this6.form.setFieldsValue({
            fieldId: text.fieldId,
            type: text.type,
            profession: text.profession,
            max: text.max,
            min: text.min
          });
        } else {
          _this6.form.setFieldsValue({
            fieldId: text.fieldId,
            type: text.type,
            professions: text.professions,
            max: text.max,
            min: text.min
          });
        }
      });
    },
    // 新增字段
    handleOk: function handleOk() {
      var _this7 = this;

      this.form.validateFields(function (err, values) {
        if (!err) {
          _this7.fieldList.map(function (res) {
            if (res.fieldId === values.fieldId) {
              values.fieldSetId = res.id;
            }
          });

          if (values.professions) {
            values.professions = values.professions.join(",");
          }

          fieldSetsCreate(values).then(function (res) {
            if (res.code === 200) {
              _this7.$message.success("新增成功");

              _this7.num = "";

              _this7.getAllList();

              _this7.visible = false;
            }
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      var _this8 = this;

      this.form.validateFields(function (err, values) {
        if (!err) {
          values.id = _this8.id;

          _this8.fieldList.map(function (res) {
            if (res.fieldId === values.fieldId) {
              values.fieldSetId = res.id;
            }
          });

          if (values.professions) {
            values.professions = values.professions.join(",");
          }

          fieldSetsUpdate(values).then(function (res) {
            if (res.code === 200) {
              _this8.$message.success("修改成功");

              _this8.getAllList();

              _this8.visible = false;
            }
          });
        }
      });
    },
    // 删除字段
    deleteFieldRules: function deleteFieldRules(text) {
      var _this9 = this;

      this.$confirm({
        title: "确定删除?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: function onOk() {
          fieldRulesDelete(text.id).then(function (res) {
            if (res.code === 200) {
              _this9.$notification["success"]({
                message: "删除成功"
              });

              fieldRules({
                ruleGroupId: _this9.ruleGroupId,
                pageSize: _this9.paginationOpt.pageSize,
                pageNo: 1
              }).then(function (res) {
                if (res.code === 200) {
                  _this9.data = res.data.entities;
                  _this9.paginationOpt.current = 1;
                  _this9.paginationOpt.total = res.data.entityCount;
                }
              });
            }
          });
        }
      });
    },
    deleteField: function deleteField(text) {
      var _this10 = this;

      this.$confirm({
        title: "确定删除?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: function onOk() {
          fieldRulesDelete(text.id).then(function (res) {
            if (res.code === 200) {
              _this10.$notification["success"]({
                message: "删除成功"
              });

              _this10.paginationOpt.current = 1;

              _this10.getAllList();
            }
          });
        }
      });
    },
    // 搜索
    onSearch: function onSearch() {
      var _this11 = this;

      fieldRules({
        ruleGroupId: this.ruleGroupId,
        fieldName: this.fieldName
      }).then(function (res) {
        if (res.code === 200) {
          _this11.data = res.data.entities;
          _this11.paginationOpt.total = res.data.entityCount;
        }
      });
    },
    onSearchName: function onSearchName() {
      this.getAllList();
    },
    // 添加分组弹窗
    newGroup: function newGroup() {
      this.newGroupOptions = {
        show: true,
        obj: {
          title: "新建产品规则组",
          subTitle: "新建产品规则组名称",
          moduleType: process.env.VUE_APP_SUBJECT_TYPE
        }
      };
    },
    // 打开每个产品的设置
    setting: function setting(item) {
      this.newGroupOptions = {
        show: true,
        obj: _objectSpread({
          title: "修改产品规则组",
          subTitle: "修改产品规则组名称"
        }, item)
      };
    }
  }
};