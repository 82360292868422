import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderInfos, orderInfoOver, finish } from "../api";
import { formatDate } from "@/utils/date";
var columns = [{
  title: "客户姓名",
  dataIndex: "name",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "手机号",
  dataIndex: "phone",
  scopedSlots: {
    customRender: "phone"
  }
}, {
  title: "申请产品",
  dataIndex: "productName",
  scopedSlots: {
    customRender: "productName"
  }
}, {
  title: "客户地址",
  dataIndex: "currentAddressDetail",
  scopedSlots: {
    customRender: "currentAddressDetail"
  }
}, {
  title: "推广人",
  dataIndex: "spreadName",
  scopedSlots: {
    customRender: "spreadName"
  }
}, {
  title: "创建时间",
  dataIndex: "gmtCreate",
  scopedSlots: {
    customRender: "gmtCreate"
  }
}, {
  title: "操作",
  dataIndex: "",
  key: "x",
  scopedSlots: {
    customRender: "action"
  }
}];
export default {
  data: function data() {
    var _this = this;

    return {
      columns: columns,
      dataList: [],
      id: "",
      confirmAlerts: false,
      from: {
        customerName: "",
        customerPhone: "",
        riskAndHousing: true
      },
      paginationOpt: {
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 10,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;

          _this.list(); // 显示列表的接口名称

        },
        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;

          _this.list();
        }
      }
    };
  },
  created: function created() {
    this.list();
  },
  methods: {
    list: function list() {
      var _this2 = this;

      finish(_objectSpread(_objectSpread({}, this.from), {}, {
        pageNo: this.paginationOpt.defaultCurrent,
        pageSize: this.paginationOpt.defaultPageSize
      })).then(function (res) {
        _this2.dataList = res.data.entities;
        _this2.paginationOpt.total = res.data.entityCount;
      });
    },
    search: function search() {
      this.list();
    },
    // 更换图标
    expandIcon: function expandIcon(props) {
      var h = this.$createElement;

      if (props.record) {
        if (props.expanded) {
          // 有数据-展开时候图标
          return h("a", {
            "style": "color: 'black',margin-right:0px",
            "on": {
              "click": function click(e) {
                props.onExpand(props.record, e);
              }
            }
          }, [h("a-icon", {
            "attrs": {
              "type": "down"
            }
          }), " "]);
        } else {
          // 有数据-未展开时候图标
          return h("a", {
            "style": "color: 'black' ,margin-right:0px",
            "on": {
              "click": function click(e) {
                props.onExpand(props.record, e);
              }
            }
          }, [h("a-icon", {
            "attrs": {
              "type": "right"
            }
          })]);
        }
      }
    },
    // 已完结
    overTo: function overTo(record) {
      this.confirmAlerts = true;
      this.id = record.id;
    },
    confirmHandles: function confirmHandles() {
      var _this3 = this;

      var order = {
        id: this.id,
        approvalStatus: "已完结",
        loanStatus: 1
      };
      orderInfoOver(order).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success("操作成功");

          _this3.confirmAlerts = false;

          _this3.list();
        }
      });
    }
  }
};