var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setpage" },
    [
      _c(
        "a-card",
        { attrs: { size: "small", title: "滞纳金设置", bordered: false } },
        [
          _c(
            "a-button",
            {
              attrs: { slot: "extra", type: "primary", size: "small" },
              on: { click: _vm.createList },
              slot: "extra"
            },
            [_vm._v(" 添加 ")]
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.formulasColumns,
              rowKey: function(record) {
                return record.id
              },
              "data-source": _vm.formulaslist,
              pagination: false,
              size: "middle",
              bordered: ""
            },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function(text, record) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              return _vm.editFormula(record)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              return _vm.delFormula(record)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          }),
          _c("a-pagination", {
            staticStyle: { "margin-top": "20px", float: "right" },
            attrs: {
              pageSize: _vm.pageSize,
              total: _vm.total,
              showTotal: function(total) {
                return "共 " + total + " 条"
              },
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["20", "50", "100"]
            },
            on: {
              change: _vm.onPageChange,
              showSizeChange: _vm.onPageSizeChange
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "a-card",
        { attrs: { size: "default", title: "热门板块设置", bordered: false } },
        [
          _c(
            "a",
            { attrs: { slot: "extra", href: "#" }, slot: "extra" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveHotPro }
                },
                [_vm._v(" 保存 ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "hotProbox" },
            [
              _c(
                "div",
                { staticClass: "protitle" },
                [
                  _c(
                    "a-checkbox",
                    {
                      attrs: {
                        indeterminate: _vm.newAllIndeterminate,
                        checked: _vm.newAllCheck
                      },
                      on: {
                        change: function($event) {
                          return _vm.checkItem()
                        }
                      }
                    },
                    [_vm._v(" 产品列表 ")]
                  )
                ],
                1
              ),
              _vm._l(_vm.newTypeProductList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "proRow" },
                  [
                    _c(
                      "a-checkbox",
                      {
                        staticClass: "typetitle",
                        attrs: {
                          indeterminate: item.status === "indeterminate",
                          checked: item.status === "checked"
                        },
                        on: {
                          change: function($event) {
                            return _vm.checkItem(index)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(item.productName) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "options" },
                      _vm._l(item.list, function(subItem, subIndex) {
                        return _c(
                          "a-checkbox",
                          {
                            key: subIndex,
                            attrs: { checked: subItem.hotProduct },
                            on: {
                              change: function($event) {
                                return _vm.checkItem(index, subIndex)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(subItem.name) + " ")]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ]
      ),
      _c(
        "a-card",
        { attrs: { size: "default", title: "产品模板", bordered: false } },
        [
          _c(
            "a-button",
            {
              attrs: { slot: "extra", type: "primary", size: "small" },
              on: { click: _vm.createVisible },
              slot: "extra"
            },
            [_vm._v(" 添加 ")]
          ),
          _c(
            "div",
            { staticClass: "prolist temlist" },
            _vm._l(_vm.proTemplist, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "template" },
                [
                  _c("img", { attrs: { src: item.background, alt: "" } }),
                  _c("a-icon", {
                    staticClass: "close",
                    attrs: { type: "close" },
                    on: {
                      click: function($event) {
                        return _vm.deleteTemplate(item)
                      }
                    }
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(item.name !== null ? item.name : "模板" + index)
                    )
                  ])
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            title: _vm.showTitle,
            visible: _vm.formulasVisible,
            confirmLoading: _vm.confirmLoading
          },
          on: {
            ok: _vm.addFormula,
            cancel: function($event) {
              _vm.formulasVisible = false
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form",
                { attrs: { form: _vm.formulasForm } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { colon: true, label: "公式模板名称" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "name",
                              { rules: [{ required: true, message: "请输入" }] }
                            ],
                            expression:
                              "['name', { rules: [{ required: true, message: '请输入' }] }]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { colon: true, label: "计算公式" } },
                    [
                      _vm._v(" 剩余本金 x 日利率 x ( 1 + "),
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "formula",
                              { rules: [{ required: true, message: "请输入" }] }
                            ],
                            expression:
                              "['formula', { rules: [{ required: true, message: '请输入' }] }]"
                          }
                        ],
                        staticStyle: { width: "80px" }
                      }),
                      _vm._v(" % ) x 逾期天数 = 滞纳金 ")
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { colon: true, label: "起始计算日" } },
                    [
                      _vm._v(" 到期日第 "),
                      _c("a-input-number", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "condition",
                              { rules: [{ required: true, message: "请输入" }] }
                            ],
                            expression:
                              "['condition', { rules: [{ required: true, message: '请输入' }] }]"
                          }
                        ],
                        staticStyle: { width: "50px" },
                        attrs: { step: "1", min: 1, max: 300 }
                      }),
                      _vm._v(" 日 ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            title: "添加模板",
            visible: _vm.proTempVisible,
            confirmLoading: _vm.confirmLoading
          },
          on: {
            ok: _vm.addTemplate,
            cancel: function($event) {
              _vm.proTempVisible = false
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form",
                {
                  attrs: {
                    form: _vm.addTempForm,
                    labelCol: { span: 6 },
                    wrapperCol: { span: 14 }
                  }
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { colon: false, label: "模板名称: " } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "name",
                              { rules: [{ required: true, message: "请输入" }] }
                            ],
                            expression:
                              "['name', { rules: [{ required: true, message: '请输入' }] }]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { colon: false, label: "产品模板图片: " } },
                    [
                      _c(
                        "div",
                        { staticClass: "clearfix" },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                customRequest: function(p) {
                                  return _vm.uploadGeneralFile(
                                    p,
                                    "productphoto",
                                    true
                                  )
                                },
                                listType: "picture-card",
                                fileList: _vm.photoList,
                                beforeUpload: _vm.beforeUpload
                              },
                              on: {
                                preview: _vm.handlePreview,
                                change: _vm.handleChange
                              }
                            },
                            [
                              _vm.photoList.length < 1
                                ? _c(
                                    "div",
                                    [_c("a-icon", { attrs: { type: "plus" } })],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }