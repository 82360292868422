import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.splice";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { siteConfigs, updateSiteConfig } from "@/api/channel";
import { getTemplateList as _getTemplateList, addTemplate as _addTemplate, delTemplate } from "@/api/client";
import { uploadFile } from "@/api/file";
export default {
  data: function data() {
    return {
      htmlPagelist: [],
      proTemplist: [],
      prolist: [],
      typelist: [],
      proTempVisible: false,
      proTempVisibles: false,
      previewVisibles: false,
      previewVisible: false,
      addTempForm: this.$form.createForm(this),
      showTitle: "添加",
      previewImage: "",
      photoList: [],
      template: 3,
      productphoto: "",
      radioStyle: {
        display: "flex",
        alignItems: "center",
        margin: "4px",
        height: "30px"
      },
      enableH5Template: ""
    };
  },
  created: function created() {
    this.getTemplateList();
    this.gethtmlPagelist(); // this.getSiteConfigs();
  },
  methods: {
    caertaImg1: function caertaImg1() {
      var _this = this;

      this.proTempVisible = true;
      this.productphoto = "";
      this.photoList = [];
      this.$nextTick(function () {
        _this.addTempForm.setFieldsValue({
          name: ""
        });
      });
    },
    caertaImg: function caertaImg() {
      var _this2 = this;

      this.proTempVisibles = true;
      this.photoList = [];
      this.productphoto = "";
      this.$nextTick(function () {
        _this2.addTempForm.setFieldsValue({
          name: ""
        });
      });
    },
    getTemplateList: function getTemplateList() {
      var _this3 = this;

      _getTemplateList({
        type: 1
      }).then(function (res) {
        _this3.proTemplist = res.data;
      });
    },
    gethtmlPagelist: function gethtmlPagelist() {
      var _this4 = this;

      _getTemplateList({
        type: 3
      }).then(function (res) {
        _this4.htmlPagelist = res.data;

        if (res.data.length > 0) {
          res.data.forEach(function (element) {
            if (element.spreadStyle === 1) {
              _this4.enableH5Template = element.id;
            }
          });
        }
      });
    },
    // getSiteConfigs() {
    //   siteConfigs().then(res => {
    //     this.enableH5Template = res.data[0].enableH5Template;
    //   });
    // },
    // 重置
    resetting: function resetting() {
      var _this5 = this;

      var siteConfigUpdateDTO = {
        id: this.enableH5Template,
        channelId: localStorage.getItem("lesseeId"),
        spreadStyle: 0
      };
      updateSiteConfig(siteConfigUpdateDTO).then(function (res) {
        _this5.$message.success("重置成功 ");

        _this5.enableH5Template = "";
      });
    },
    saveHtmlPage: function saveHtmlPage() {
      var _this6 = this;

      var siteConfigUpdateDTO = {
        id: this.enableH5Template,
        channelId: localStorage.getItem("lesseeId"),
        spreadStyle: 1
      };
      updateSiteConfig(siteConfigUpdateDTO).then(function (res) {
        _this6.$message.success("执行成功 ");

        _this6.gethtmlPagelist();
      });
    },
    addTemplate: function addTemplate() {
      var _this7 = this;

      var that = this;
      this.addTempForm.validateFields(function (err, values) {
        if (!err) {
          values.background = that.productphoto;

          var templateAddDTO = _objectSpread(_objectSpread({}, values), {}, {
            type: 1 // 1: 二维码 2:产品 3:h5样式模板

          });

          _addTemplate(templateAddDTO).then(function (res) {
            _this7.proTempVisible = false;

            _this7.$message.success("添加成功");

            _this7.addTempForm.resetFields();

            _this7.getTemplateList();
          });
        }
      });
    },
    addTemplateImg: function addTemplateImg() {
      var _this8 = this;

      var that = this;
      this.addTempForm.validateFields(function (err, values) {
        if (!err) {
          values.background = that.productphoto;

          var templateAddDTO = _objectSpread(_objectSpread({}, values), {}, {
            type: 3,
            // 1: 二维码 2:产品 3:h5样式模板
            template: _this8.template
          });

          _addTemplate(templateAddDTO).then(function (res) {
            _this8.proTempVisibles = false;

            _this8.$message.success("添加成功");

            _this8.addTempForm.resetFields();

            _this8.gethtmlPagelist();
          });
        }
      });
    },
    deleteTemplate: function deleteTemplate(record) {
      var that = this;
      this.$confirm({
        title: "删除公式",
        content: "此操作将删除此产品模板, 是否继续?",
        onOk: function onOk() {
          delTemplate(record.id).then(function (res) {
            that.$message.success("删除成功");
            that.getTemplateList();
          });
        },
        onCancel: function onCancel() {}
      });
    },
    deletePageList: function deletePageList(record) {
      var that = this;
      this.$confirm({
        title: "删除公式",
        content: "此操作将删除此产品模板, 是否继续?",
        onOk: function onOk() {
          delTemplate(record.id).then(function (res) {
            that.$message.success("删除成功");
            that.gethtmlPagelist();
          });
        },
        onCancel: function onCancel() {}
      });
    },
    handleCancel: function handleCancel(isPending) {
      this.previewVisible = false;
    },
    handlePreview: function handlePreview(file, isPending) {
      var images = file.url || file.thumbUrl;

      if (isPending) {
        this.pendingPreviewImage = images;
        this.pendingPreviewVisible = true;
      } else {
        this.previewImage = images;
        this.previewVisible = true;
      }
    },
    handleChange: function handleChange(_ref) {
      var file = _ref.file,
          fileList = _ref.fileList;

      if (file.status === "removed") {
        this.photoList = [];
        this.productphoto = "";
      }

      this.photoList = fileList.splice(1);
    },
    uploadFiles: function uploadFiles(params, type, bool) {
      var that = this;
      var formData = new FormData();
      formData.append("files", params.file);
      uploadFile(formData).then(function (res) {
        that[type.substr(7) + "List"].push({
          uid: res.data[0],
          name: res.data[0],
          status: "done",
          url: res.data[0]
        });

        if (bool) {
          that[type] = res.data[0];
        } else {
          that[type].push(res.data[0]);
        }
      });
    }
  }
};