var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _vm._v(" 客户姓名 : "),
          _c("a-input", {
            staticStyle: { width: "200px", "margin-right": "20px" },
            attrs: { placeholder: "请输入", allowClear: "" },
            on: { change: _vm.search },
            model: {
              value: _vm.from.customerName,
              callback: function($$v) {
                _vm.$set(_vm.from, "customerName", $$v)
              },
              expression: "from.customerName"
            }
          }),
          _vm._v(" 手机号 : "),
          _c("a-input", {
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入", allowClear: "" },
            on: { change: _vm.search },
            model: {
              value: _vm.from.customerPhone,
              callback: function($$v) {
                _vm.$set(_vm.from, "customerPhone", $$v)
              },
              expression: "from.customerPhone"
            }
          })
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          expandIcon: _vm.expandIcon,
          columns: _vm.columns,
          "data-source": _vm.dataList,
          rowKey: "id",
          pagination: _vm.paginationOpt
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function(text, record) {
              return _c("span", {}, [
                _vm._v(_vm._s(record.customerInfosVO.customerVO.name))
              ])
            }
          },
          {
            key: "phone",
            fn: function(text, record) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    record.customerInfosVO.customerVO.phone.substr(0, 3) +
                      "****" +
                      record.customerInfosVO.customerVO.phone.substr(7)
                  )
                )
              ])
            }
          },
          {
            key: "currentAddressDetail",
            fn: function(text, record) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(record.customerInfosVO.customerVO.currentAddressDetail)
                )
              ])
            }
          },
          {
            key: "gmtCreate",
            fn: function(text, record) {
              return _c("span", {}, [
                _vm._v(_vm._s(_vm._f("formatDate")(record.gmtCreate)))
              ])
            }
          },
          {
            key: "expandedRowRender",
            fn: function(record) {
              return _c(
                "div",
                { staticClass: "nav" },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "bold"
                          },
                          attrs: { span: 24 }
                        },
                        [_vm._v(" 房屋估值 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        "a-col",
                        {
                          staticStyle: { "font-size": "15px" },
                          attrs: { span: 8 }
                        },
                        [
                          _vm._v(
                            " 区域名 : " +
                              _vm._s(
                                record.housingValuationVO
                                  ? record.housingValuationVO.areaText
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-col",
                        {
                          staticStyle: { "font-size": "15px" },
                          attrs: { span: 8 }
                        },
                        [
                          _vm._v(
                            " 地址 : " +
                              _vm._s(
                                record.housingValuationVO
                                  ? record.housingValuationVO.projectName
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-col",
                        {
                          staticStyle: { "font-size": "15px" },
                          attrs: { span: 8 }
                        },
                        [
                          _vm._v(
                            " 楼栋 : " +
                              _vm._s(
                                record.housingValuationVO
                                  ? record.housingValuationVO.buildingName
                                  : ""
                              ) +
                              _vm._s(
                                record.housingValuationVO
                                  ? record.housingValuationVO.houseName
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        "a-col",
                        {
                          staticStyle: { "font-size": "15px" },
                          attrs: { span: 8 }
                        },
                        [
                          _vm._v(
                            " 面积 : " +
                              _vm._s(
                                record.housingValuationVO
                                  ? record.housingValuationVO.buildArea
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-col",
                        {
                          staticStyle: { "font-size": "15px" },
                          attrs: { span: 8 }
                        },
                        [
                          _vm._v(
                            " 单价 : " +
                              _vm._s(
                                record.housingValuationVO
                                  ? record.housingValuationVO.unitPrice
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-col",
                        {
                          staticStyle: { "font-size": "15px" },
                          attrs: { span: 8 }
                        },
                        [
                          _vm._v(
                            " 总价 : " +
                              _vm._s(
                                record.housingValuationVO
                                  ? parseFloat(
                                      record.housingValuationVO.buildArea *
                                        record.housingValuationVO.unitPrice
                                    ).toFixed(2)
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "margin-top": "10px"
                          },
                          attrs: { span: 24 }
                        },
                        [
                          record.housingValuationVO
                            ? _c("span", [
                                _vm._v(
                                  " 更新时间 : " +
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        record.housingValuationVO.gmtModified
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _c("span", [_vm._v(" 更新时间 : --- ")])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("a-divider"),
                  _c(
                    "a-row",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        "a-col",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "bold"
                          },
                          attrs: { span: 24 }
                        },
                        [_vm._v(" 风控查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticStyle: { margin: "10px 0 15px 0" } },
                    [
                      _c(
                        "a-col",
                        {
                          staticStyle: { "font-size": "15px" },
                          attrs: { span: 8 }
                        },
                        [
                          _vm._v(
                            " 客户姓名 : " +
                              _vm._s(
                                record.customerInfosVO.customerVO.name
                                  ? record.customerInfosVO.customerVO.name
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-col",
                        {
                          staticStyle: { "font-size": "15px" },
                          attrs: { span: 8 }
                        },
                        [
                          _vm._v(
                            " 手机号 : " +
                              _vm._s(
                                record.customerInfosVO.customerVO.phone
                                  ? record.customerInfosVO.customerVO.phone.substr(
                                      0,
                                      3
                                    ) +
                                      "****" +
                                      record.customerInfosVO.customerVO.phone.substr(
                                        7
                                      )
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      ),
                      record.riskControlVO
                        ? _c(
                            "a-col",
                            {
                              staticStyle: { "font-size": "15px" },
                              attrs: { span: 8 }
                            },
                            [
                              _vm._v(
                                " 更新时间 : " +
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      record.riskControlVO.gmtModified
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _c(
                            "a-col",
                            {
                              staticStyle: { "font-size": "15px" },
                              attrs: { span: 8 }
                            },
                            [_vm._v(" 更新时间 : ")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            title: "提示",
            visible: _vm.confirmAlerts
          },
          on: {
            ok: _vm.confirmHandles,
            cancel: function($event) {
              _vm.confirmAlerts = false
            }
          }
        },
        [_c("p", { staticClass: "f500" }, [_vm._v("确定要完结该订单吗!")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }