import { render, staticRenderFns } from "./new-group.vue?vue&type=template&id=e822c040&scoped=true&"
import script from "./new-group.vue?vue&type=script&lang=js&"
export * from "./new-group.vue?vue&type=script&lang=js&"
import style0 from "./new-group.vue?vue&type=style&index=0&id=e822c040&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e822c040",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/prod-e-loan/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e822c040')) {
      api.createRecord('e822c040', component.options)
    } else {
      api.reload('e822c040', component.options)
    }
    module.hot.accept("./new-group.vue?vue&type=template&id=e822c040&scoped=true&", function () {
      api.rerender('e822c040', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/approval/new-group.vue"
export default component.exports