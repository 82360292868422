var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          bordered: "",
          "data-source": _vm.dataSource,
          columns: _vm.columns
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function(text, record) {
              return [
                _c("editable-cell", {
                  attrs: { text: text },
                  on: {
                    change: function($event) {
                      return _vm.onCellChange(record.key, "name", $event)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "operation",
            fn: function(text, record) {
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.downloadRecord(record)
                      }
                    }
                  },
                  [_vm._v("下载")]
                ),
                _c("a-divider", { attrs: { type: "vertical" } }),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.downloadPreview(record)
                      }
                    }
                  },
                  [_vm._v("预览")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "a-modal",
        {
          attrs: { maskClosable: false, width: "1000px", footer: "" },
          model: {
            value: _vm.editModalVisible,
            callback: function($$v) {
              _vm.editModalVisible = $$v
            },
            expression: "editModalVisible"
          }
        },
        [
          _c("iframe", {
            attrs: {
              src:
                "https://view.officeapps.live.com/op/view.aspx?src=" + _vm.src,
              width: "100%",
              height: "700px",
              frameborder: "1"
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }