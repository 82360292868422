import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { allBySite, allCanUseByFlow, getFlowProcessByApproInfo, listAll, roles, userAppros, customerInfos, allGroupAndFlowByCompanyUser } from "../api";
import { mapState } from "vuex";
export default {
  name: "",
  inject: ["reload"],
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      title: "",
      // 角色
      rolesList: [],
      // 员工
      userList: [],
      // 选择的员工
      choseUsers: [],
      // 默认审批人
      defaultValue: [],
      // 审批人
      approvalEr: [],
      // 抄送人
      notifEr: [],
      // 返回数据
      rebackData: [],
      flowId: "",
      peoApplit: [],
      formData: [],
      postData: {},
      remark: "",
      jsonData: {
        list: [],
        config: {
          layout: "horizontal",
          labelCol: {
            span: 4
          },
          wrapperCol: {
            span: 18
          },
          hideRequiredMark: false,
          customStyle: ""
        }
      },
      inner: false,
      visible: false,
      flowNodeList: [],
      subBtn: {
        type: "button",
        label: "提交",
        options: {
          type: "primary",
          handle: "submit",
          disabled: false
        },
        key: "button_1577880512173"
      },
      name: "",
      phone: "",
      submit: true,
      id: "",
      approvalInfo: [],
      keys: {}
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  watch: {
    show: function show(val) {
      if (val) {
        this.getGroups();
        this.getDepPeo();
        this.getRoles();
      }
    },
    jsonData: {
      handler: function handler(val, oldVal) {},
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    /* 新建表单 */
    createItem: function createItem(item) {
      var _this = this;

      this.title = item.typeName;
      this.$emit("update:show", false);
      this.inner = true;
      allCanUseByFlow(item.id).then(function (res) {
        res.data.forEach(function (item) {
          item.value = "";
        });
        _this.formData = res.data;

        _this.transSourceToJson();
      });
    },
    // 转换数据
    transSourceToJson: function transSourceToJson() {
      var list = [];
      this.formData.map(function (item) {
        if (item.props) {
          list.push(JSON.parse(item.props));
        }
      });
      list.push(this.subBtn);
      this.jsonData.list = list;
    },
    // 按钮提交
    submitData: function submitData(getData) {
      var _this2 = this;

      this.approvalInfo = this.formData.map(function (val) {
        return {
          formId: val.id,
          value: val.value
        };
      });
      getFlowProcessByApproInfo({
        moduleType: process.env.VUE_APP_SUBJECT_TYPE,
        flowId: this.formData[0].flowId,
        userApproInfoList: this.approvalInfo
      }).then(function (res) {
        if (res.success) {
          _this2.submit = false;
          _this2.flowNodeList = res.data;
          _this2.flowId = res.data[0].flowId;
          _this2.rebackData = res.data;
          var userApproAddDTO = {
            moduleType: process.env.VUE_APP_SUBJECT_TYPE,
            flowNodeList: _this2.rebackData,
            flowId: _this2.flowId,
            userApproInfoList: _this2.approvalInfo
          };
          customerInfos({
            customerName: _this2.name,
            phone: _this2.phone,
            remark: _this2.remark,
            userApproAddDTO: userApproAddDTO
          }).then(function (data) {
            _this2.id = data.data.id;

            _this2.$message.success("创建成功");

            _this2.inner = false;

            _this2.reload();
          });
        }
      });
    },
    // 选择角色
    handleChangeROle: function handleChangeROle(v, e) {
      e.roleIdList = v.toString();
    },
    // 选择人员
    handleChangeUser: function handleChangeUser(v, e) {
      e.userIdList = v.toString();
    },
    // 选择审批人
    handleChangeApp: function handleChangeApp(v, e) {
      e.userIdList = v.toString();
    },
    // 获取 部门员工
    getDepPeo: function getDepPeo() {
      var _this3 = this;

      listAll().then(function (res) {
        res.data.forEach(function (item) {
          item.select = false;
          item.values = item.id;
        });
        _this3.userList = res.data;
      });
    },
    // 获取角色
    getRoles: function getRoles() {
      var _this4 = this;

      roles({
        subjectType: process.env.VUE_APP_SUBJECT_TYPE
      }).then(function (res) {
        res.data.entities.forEach(function (item) {
          item.select = false;
          item.values = item.id;
        });
        _this4.rolesList = res.data;
      });
    },
    // 获取审批分组
    getGroups: function getGroups() {
      var _this5 = this;

      this.peoApplit = [];
      var companyId = localStorage.getItem("lesseeId");
      allGroupAndFlowByCompanyUser("".concat(companyId), "".concat(process.env.VUE_APP_SUBJECT_TYPE)).then(function (res) {
        res.data.forEach(function (item) {
          if (item.approFlowList.length >= 1) {
            _this5.peoApplit.push(item);
          }
        });
      });
    }
  }
};