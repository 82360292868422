export default {
  fields: {
    amount: {
      model: "amount",
      type: "input",
      wrapper: {
        label: "申贷金额(元)"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入申贷金额"
      },
      decorator: {
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    productId: {
      model: "productId",
      type: "select",
      wrapper: {
        label: "申贷产品"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择申贷产品"
      },
      decorator: {
        initialValue: null
      },
      extend: {
        dynamic: true,
        dynamicKey: "repaymentTypeList"
      }
    },
    deadline: {
      model: "deadline",
      type: "input",
      wrapper: {
        label: "贷款期限(期)"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入贷款期限"
      },
      decorator: {
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    repaymentType: {
      model: "repaymentType",
      type: "select",
      wrapper: {
        label: "还款方式"
      },
      customize: {
        allowClear: true,
        placeholder: "还款方式",
        options: [{
          value: 1,
          label: "等额本金"
        }, {
          value: 2,
          label: "等额本息"
        }, {
          value: 3,
          label: "先息后本"
        }]
      },
      decorator: {
        initialValue: null
      }
    },
    loanToDo: {
      model: "loanToDo",
      type: "select",
      wrapper: {
        label: "贷款投向"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择贷款投向",
        options: [{
          value: 1,
          label: "一般消费"
        }, {
          value: 2,
          label: "生意周转"
        }, {
          value: 3,
          label: "创业投入"
        }, {
          value: 4,
          label: "投资"
        }, {
          value: 5,
          label: "购置资产"
        }, {
          value: 6,
          label: "其他"
        }]
      },
      decorator: {
        initialValue: null
      }
    },
    remark: {
      model: "remark",
      type: "textarea",
      wrapper: {
        label: "客户情况简介"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入客户情况简介"
      },
      decorator: {
        initialValue: null
      }
    }
  },
  config: {}
};