import request from "@/utils/request"; // 获取统计数据

export function statiStics(params) {
  return request({
    url: "/finance/api/v1/statistics",
    method: "GET",
    params: params
  });
} // 获取所有产品

export function productsListAll(params) {
  return request({
    url: "/finance/api/v1/products/listAll",
    method: "GET",
    params: params
  });
} // 查询全部首页汇总信息

export function collect(params) {
  return request({
    url: "/finance/api/v1/statistics/collect",
    method: "GET",
    params: params
  });
} // 查询个人首页汇总信息

export function user(params) {
  return request({
    url: "/finance/api/v1/statistics/collect/user",
    method: "GET",
    params: params
  });
} // 查询推广排名

export function productRanking(params) {
  return request({
    url: "/finance/api/v1/statistics/productRanking",
    method: "GET",
    params: params
  });
} // 管理员查询直销推广统计

export function direct(params) {
  return request({
    url: "/finance/api/v1/statistics/spread/direct",
    method: "GET",
    params: params
  });
} // 管理员查询分销推广统计

export function distribution(params) {
  return request({
    url: "/finance/api/v1/statistics/spread/distribution",
    method: "GET",
    params: params
  });
} // 管理员查询业绩统计

export function performance(params) {
  return request({
    url: "/finance/api/v1/statistics/performance",
    method: "GET",
    params: params
  });
} // 个人查询推广统计

export function spreadUser(params) {
  return request({
    url: "/finance/api/v1/statistics/spread/user",
    method: "GET",
    params: params
  });
} // 个人查询推广统计

export function performanceUser(params) {
  return request({
    url: "/finance/api/v1/statistics/performance/user",
    method: "GET",
    params: params
  });
}