import "core-js/modules/es.array.concat";
var channelId = localStorage.getItem("lesseeId");
export default {
  fields: {
    name: {
      model: "name",
      type: "input",
      wrapper: {
        label: "推广名"
      },
      customize: {
        placeholder: "请输入推广名",
        allowClear: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      }
    },
    productIds: {
      model: "productIds",
      type: "select",
      wrapper: {
        label: "选择产品"
      },
      customize: {
        allowClear: true,
        mode: "multiple",
        placeholder: "请选择产品"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "enumOptions2",
        dynamicUrl: "/finance/api/v1/products/listAll/valid",
        dynamicTail: "data",
        dynamicValueKey: "id",
        dynamicLabelKey: "name"
      }
    },
    contactPhone: {
      model: "contactPhone",
      type: "input",
      wrapper: {
        label: "联系人电话"
      },
      customize: {
        placeholder: "请输入",
        allowClear: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      }
    },
    backgroundSpread: {
      model: "backgroundSpread",
      type: "select",
      wrapper: {
        label: "推广模板"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择模板"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "backgroundSpreadList",
        dynamicUrl: "/finance/api/v1/templates/listAll?type=1&source=".concat(process.env.VUE_APP_SOURCE, "&channelId=").concat(channelId),
        dynamicTail: "data",
        dynamicValueKey: "id",
        dynamicLabelKey: "name"
      }
    },
    template: {
      model: "template",
      type: "select",
      wrapper: {
        label: "H5模板"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择模板"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "templateList",
        dynamicUrl: "/finance/api/v1/templates/listAll?type=3&source:".concat(process.env.VUE_APP_SOURCE, "&channelId=").concat(channelId),
        dynamicTail: "data",
        dynamicValueKey: "id",
        dynamicLabelKey: "name"
      }
    },
    remark: {
      model: "remark",
      type: "textarea",
      wrapper: {
        label: "备注"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入备注"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    }
  },
  validators: {
    deadlineMax: "validateDeadlineMax",
    amountMax: "validateAmountMax",
    interestRateMax: "validateInterestRateMax"
  },
  config: {
    form: {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 18
      }
    },
    createLoanProd: {
      fieldKeys: ["name", "productIds", "deadlineMax", "contactPhone", "backgroundSpread", "template", "remark"],
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    updateLoanProd: {
      fieldKeys: ["name", "productIds", "deadlineMax", "contactPhone", "backgroundSpread", "template", "remark"],
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    search: {
      fieldKeys: ["name"],
      customize: {
        layout: "inline",
        labelCol: {
          span: 8
        },
        wrapperCol: {
          span: 16
        }
      }
    },
    tableLoanProd: {
      fields: [{
        model: "name",
        title: "产品名称"
      }, {
        model: "deadlineMin",
        title: "最低期限(月)"
      }, {
        model: "deadlineMax",
        title: "最高期限(月)"
      }, {
        model: "interestRateMin",
        title: "最低年利率(%)"
      }, {
        model: "interestRateMax",
        title: "最高年利率(%)"
      }, {
        model: "amountMin",
        title: "最低额度(元)"
      }, {
        model: "amountMax",
        title: "最高额度(元)"
      }],
      customize: {
        rowKey: "id",
        pagination: {
          pageSize: 20
        }
      },
      extend: {
        batch: true,
        action: {
          width: 110
        }
      }
    },
    tableDepositProd: {
      fields: [{
        model: "name",
        title: "产品名称"
      }, {
        model: "deadlineMin",
        title: "最低期限(月)"
      }, {
        model: "deadlineMax",
        title: "最高期限(月)"
      }, {
        model: "interestRateMin",
        title: "最低年利率(%)"
      }, {
        model: "interestRateMax",
        title: "最高年利率(%)"
      }, {
        model: "amountMin",
        title: "最低额度(元)"
      }, {
        model: "amountMax",
        title: "最高额度(元)"
      }],
      customize: {
        rowKey: "id",
        pagination: {
          pageSize: 20
        }
      },
      extend: {
        batch: true,
        action: {
          width: 110
        }
      }
    }
  }
};