var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    [
      _c(
        "a-spin",
        { staticClass: "xq-auto-wrapper", attrs: { spinning: _vm.spinning } },
        [
          _c(
            "div",
            { staticClass: "xq-auto-search" },
            [
              _c("search-form", {
                attrs: { sourceForm: _vm.RecordForm },
                on: { search: _vm.filterRecord }
              })
            ],
            1
          ),
          _c("div", { staticClass: "center" }, [
            _c("div", { staticClass: "xq-auto-action" }, [
              _c("div", { staticClass: "xq-auto-action-left" }),
              _c(
                "div",
                { staticClass: "xq-auto-action-right" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.showEditModals()
                        }
                      }
                    },
                    [_vm._v(" + 添加客户 ")]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "xq-auto-content" },
              [
                _c(
                  "a-table",
                  {
                    staticClass: "xq-auto-content-table",
                    attrs: {
                      columns: _vm.columns,
                      "data-source": _vm.tableData,
                      pagination: _vm.pagination,
                      rowKey: "id"
                    },
                    on: { change: _vm.onTableChange },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.columns, function(col, index) {
                          return {
                            key: col.dataIndex,
                            fn: function(text, record) {
                              return [
                                col.dataIndex === "id"
                                  ? _c("span", { key: index }, [
                                      _vm._v(" " + _vm._s(record.id) + " ")
                                    ])
                                  : col.dataIndex === "customerName"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.customerInfosVO.customerVO
                                              .name
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "phone"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.customerInfosVO.customerVO.phone.substr(
                                              0,
                                              3
                                            ) +
                                              "****" +
                                              record.customerInfosVO.customerVO.phone.substr(
                                                7
                                              )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "currentAddressDetail"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.customerInfoVO
                                              .currentAddressDetail
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "conform"
                                  ? _c("span", { key: index }, [
                                      record.conform === true
                                        ? _c("div", [_vm._v("符合")])
                                        : _c(
                                            "div",
                                            [
                                              _c(
                                                "a-tooltip",
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "title" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            record.inconformityInfo
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "conformNo"
                                                    },
                                                    [
                                                      _vm._v(" 不符合 "),
                                                      _c("a-icon", {
                                                        attrs: {
                                                          type:
                                                            "question-circle"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                    ])
                                  : col.dataIndex === "sourceType"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("filterType")(
                                              record.sourceType
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "gmtCreate"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              record.gmtCreate
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "crudAction"
                                  ? _c(
                                      "span",
                                      { key: index },
                                      [
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.sumbit(record)
                                              }
                                            }
                                          },
                                          [_vm._v("提交审批")]
                                        ),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" }
                                        }),
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.noSumbit(record)
                                              }
                                            }
                                          },
                                          [_vm._v("拒绝")]
                                        ),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" }
                                        }),
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.detailed(record)
                                              }
                                            }
                                          },
                                          [_vm._v("详情")]
                                        )
                                      ],
                                      1
                                    )
                                  : _c("table-cell", {
                                      key: index,
                                      attrs: {
                                        text: text,
                                        record: record,
                                        column: col,
                                        dynamicData: _vm.dynamicData
                                      }
                                    })
                              ]
                            }
                          }
                        })
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._l(_vm.columns, function(col, index) {
                      return _c(
                        "template",
                        { slot: "custom_" + col.dataIndex },
                        [
                          _c(
                            "span",
                            { key: index },
                            [
                              col.help
                                ? _c(
                                    "a-tooltip",
                                    { attrs: { title: col.help } },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: { type: "question-circle" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" " + _vm._s(col.customTitle) + " ")
                            ],
                            1
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "xq-auto-content-pagination" },
                  [
                    _c(
                      "div",
                      { staticClass: "xq-auto-content-pagination-total" },
                      [_vm._v("共 " + _vm._s(_vm.total) + " 条")]
                    ),
                    _c("a-pagination", {
                      attrs: {
                        pageSize: _vm.pageSize,
                        total: _vm.total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ["20", "50", "100"]
                      },
                      on: {
                        change: _vm.onPageChange,
                        showSizeChange: _vm.onPageSizeChange
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                title: "提示",
                visible: _vm.confirmAlerts
              },
              on: {
                cancel: function($event) {
                  _vm.confirmAlerts = false
                }
              }
            },
            [
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: {
                        click: function($event) {
                          _vm.confirmAlerts = false
                        }
                      }
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary", disabled: _vm.isDisabled },
                      on: { click: _vm.confirmHandles }
                    },
                    [_vm._v(" 确定 ")]
                  )
                ],
                1
              ),
              _c("p", { staticClass: "f500" }, [_vm._v("确定要提交该审批吗!")])
            ],
            2
          ),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                visible: _vm.confirmAlertsl,
                title: "客户详情",
                footer: ""
              },
              on: {
                cancel: function($event) {
                  _vm.confirmAlertsl = false
                }
              }
            },
            [
              _c(
                "a-card",
                { staticStyle: { width: "100%" }, attrs: { bordered: false } },
                [
                  _c(
                    "a-row",
                    { staticClass: "rows" },
                    [
                      _c("a-col", { attrs: { span: 6 } }, [_vm._v("姓名：")]),
                      _c("a-col", { attrs: { span: 18 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailedList.customerInfosVO
                              ? _vm.detailedList.customerInfosVO.customerVO.name
                              : "---"
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticClass: "rows" },
                    [
                      _c("a-col", { attrs: { span: 6 } }, [_vm._v("手机号 :")]),
                      _c("a-col", { attrs: { span: 18 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailedList.customerInfosVO
                              ? _vm.detailedList.customerInfosVO.customerVO.phone.substr(
                                  0,
                                  3
                                ) +
                                  "****" +
                                  _vm.detailedList.customerInfosVO.customerVO.phone.substr(
                                    7
                                  )
                              : "---"
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticClass: "rows" },
                    [
                      _c("a-col", { attrs: { span: 6 } }, [
                        _vm._v("申请产品 ：")
                      ]),
                      _c("a-col", { attrs: { span: 18 } }, [
                        _vm._v(_vm._s(_vm.detailedList.productName))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticClass: "rows" },
                    [
                      _c("a-col", { attrs: { span: 6 } }, [
                        _vm._v("订单编号 ：")
                      ]),
                      _c("a-col", { attrs: { span: 18 } }, [
                        _vm._v(_vm._s(_vm.detailedList.incomingNum))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                title: "提示",
                visible: _vm.confirmAlertNo
              },
              on: {
                ok: _vm.confirmHandleNo,
                cancel: function($event) {
                  _vm.confirmAlertNo = false
                }
              }
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "请输入拒绝原因" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: "请输入",
                      autoSize: { minRows: 3, maxRows: 5 }
                    },
                    model: {
                      value: _vm.rejectReasonDetail,
                      callback: function($$v) {
                        _vm.rejectReasonDetail = $$v
                      },
                      expression: "rejectReasonDetail"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                title: "新增",
                confirmLoading: _vm.confirmLoading,
                visible: _vm.confirmAlertNos,
                width: 650
              },
              on: {
                ok: _vm.confirmHandleCreate,
                cancel: function($event) {
                  _vm.confirmAlertNos = false
                }
              }
            },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    labelAlign: "left",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    model: _vm.goodForm,
                    rules: _vm.rules
                  }
                },
                [
                  _vm.goodForm.dataList.length === 0
                    ? _vm._l(_vm.goodForm.detaileList, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            item.fieldName === "客户名称"
                              ? [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        prop: "detaileList." + index + ".value",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入",
                                            trigger: ["blur", "change"]
                                          }
                                        ],
                                        label: item.fieldName
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: {
                                          allowClear: "",
                                          placeholder: "请输入"
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            item.fieldName === "年龄"
                              ? [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        prop: "detaileList." + index + ".value",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入",
                                            trigger: ["blur", "change"]
                                          }
                                        ],
                                        label: item.fieldName
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: {
                                          allowClear: "",
                                          min: 0,
                                          max: 100,
                                          type: "number",
                                          placeholder: "请输入"
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            item.fieldName === "手机号"
                              ? [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        prop: "detaileList." + index + ".value",
                                        rules: [
                                          {
                                            required: true,
                                            pattern: "^1[3-9]\\d{9}$",
                                            message: "请输入正确的手机号",
                                            trigger: ["blur", "change"]
                                          }
                                        ],
                                        label: item.fieldName
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: {
                                          allowClear: "",
                                          min: 0,
                                          max: 100,
                                          type: "number",
                                          placeholder: "请输入"
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      })
                    : _vm._e(),
                  _vm._l(_vm.goodForm.dataList, function(item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        !(index % 2 == 0)
                          ? _c(
                              "div",
                              [
                                item.fieldType === 2
                                  ? [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            prop:
                                              "dataList." + index + ".value",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入",
                                                trigger: ["blur", "change"]
                                              }
                                            ],
                                            label: item.fieldName
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              allowClear: "",
                                              placeholder: "请输入"
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                item.fieldType === 1 &&
                                item.fieldName !== "手机号"
                                  ? [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            prop:
                                              "dataList." + index + ".value",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入",
                                                trigger: ["blur", "change"]
                                              }
                                            ],
                                            label: item.fieldName
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              allowClear: "",
                                              min: 0,
                                              max: 100,
                                              type: "number",
                                              placeholder: "请输入"
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                item.fieldType === 1 &&
                                item.fieldName === "手机号"
                                  ? [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            prop:
                                              "dataList." + index + ".value",
                                            rules: [
                                              {
                                                required: true,
                                                pattern: "^1[3-9]\\d{9}$",
                                                message: "请输入正确的手机号",
                                                trigger: ["blur", "change"]
                                              }
                                            ],
                                            label: item.fieldName
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              allowClear: "",
                                              min: 0,
                                              max: 100,
                                              type: "number",
                                              placeholder: "请输入"
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        index % 2 == 0
                          ? _c(
                              "div",
                              [
                                item.fieldType === 2
                                  ? [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            prop:
                                              "dataList." + index + ".value",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入",
                                                trigger: ["blur", "change"]
                                              }
                                            ],
                                            label: item.fieldName
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              allowClear: "",
                                              placeholder: "请输入"
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                item.fieldType === 1 &&
                                item.fieldName !== "手机号"
                                  ? [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            prop:
                                              "dataList." + index + ".value",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入",
                                                trigger: ["blur", "change"]
                                              }
                                            ],
                                            label: item.fieldName
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              allowClear: "",
                                              min: 0,
                                              max: 100,
                                              type: "number",
                                              placeholder: "请输入"
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                item.fieldType === 1 &&
                                item.fieldName === "手机号"
                                  ? [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            prop:
                                              "dataList." + index + ".value",
                                            rules: [
                                              {
                                                required: true,
                                                pattern: "^1[3-9]\\d{9}$",
                                                message: "请输入正确的手机号",
                                                trigger: ["blur", "change"]
                                              }
                                            ],
                                            label: item.fieldName
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              allowClear: "",
                                              min: 0,
                                              max: 100,
                                              type: "number",
                                              placeholder: "请输入"
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        item.fieldType === 4 && item.fieldName === "性别"
                          ? [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: item.fieldName,
                                    prop: "dataList." + index + ".value",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择",
                                        trigger: ["change"]
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: { allowClear: "" },
                                      on: { change: _vm.handleChange },
                                      model: {
                                        value: item.value,
                                        callback: function($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value"
                                      }
                                    },
                                    _vm._l(_vm.sexList, function(sexLi, idx) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: idx,
                                          attrs: { value: sexLi.value }
                                        },
                                        [_vm._v(_vm._s(sexLi.label))]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        item.fieldType === 4 && item.fieldName === "学历"
                          ? [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: item.fieldName,
                                    prop: "dataList." + index + ".value",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择",
                                        trigger: ["blur", "change"]
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        allowClear: "",
                                        placeholder: "请选择"
                                      },
                                      on: { change: _vm.handleChange1 },
                                      model: {
                                        value: item.value,
                                        callback: function($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value"
                                      }
                                    },
                                    _vm._l(_vm.evelList, function(evelLi, idx) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: idx,
                                          attrs: { value: evelLi.value }
                                        },
                                        [_vm._v(_vm._s(evelLi.label))]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "产品", prop: "productId" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { allowClear: "", placeholder: "请选择" },
                          on: {
                            change: function($event) {
                              return _vm.handleChange2($event)
                            }
                          },
                          model: {
                            value: _vm.goodForm.productId,
                            callback: function($$v) {
                              _vm.$set(_vm.goodForm, "productId", $$v)
                            },
                            expression: "goodForm.productId"
                          }
                        },
                        _vm._l(_vm.productList, function(item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }