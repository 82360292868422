//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import FormJson from "./FormJson";
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import Extendform from "./extendForm";
import { getProductlistReq as retrieveReq, addProductReq as createReq, deleteProduct as deleteReq, batchDeleteProduct as deleteBatchReq, editProductReq as updateReq } from "./api";
var curdMixin = new XqCurdMixin({
  createReq: createReq,
  retrieveReq: retrieveReq,
  updateReq: updateReq,
  deleteReq: deleteReq,
  FormJson: FormJson,
  tableConfigKey: "tableLoanProd"
});
export default {
  name: "",
  components: {
    Extendform: Extendform
  },
  mixins: [curdMixin],
  data: function data() {
    return {
      pageSize: 20
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          kind: 2,
          merchantId: localStorage.getItem("lesseeId")
        }
      };
    },
    createParams: function createParams() {
      return {
        kind: 2,
        code: 2,
        merchantId: localStorage.getItem("lesseeId")
      };
    },
    dynamicParams: function dynamicParams() {
      return {
        backgroundList: {
          type: 2,
          channelId: localStorage.getItem("lesseeId")
        },
        approveIdList: {
          moduleType: process.env.VUE_APP_SUBJECT_TYPE
        }
      };
    }
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    formatValues: function formatValues(values) {
      values["logo"] = this.formatUploadValue(values["logo"]);
      values["photo"] = this.formatUploadValue(values["photo"]);
      values["address"] = this.formatAreaValue(values["address"]);
      return values;
    },
    formatDataSource: function formatDataSource(record) {
      record["lateFeeRule"] = record["lateFeeRule"] ? parseInt(record["lateFeeRule"]) : record["lateFeeRule"];
      return record;
    }
  }
};