import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { updateOrderStatusReq // eslint-disable-line no-unused-vars
, detailReq, orderInfoReq, rejectOrder // eslint-disable-line no-unused-vars
, pass, reject, updateOrderInfos, userApproDetails, productsListAll } from "@/views/Customer/api";
import Intention from "./intention";
import Baseinfo from "./baseinfo";
import Order from "./order";
import CustomerFile from "@/views/CustomerFile";
import ContractList from "./contractList";
import RistQueryTable from "@/views/BigData/RiskQuery/ristQueryTable";
import HousingValuation from "@/views/BigData/HousingValuation/valuation";
import Report from "./report";
import { mapGetters } from "vuex"; // eslint-disable-line no-unused-vars

export default {
  components: {
    Intention: Intention,
    Order: Order,
    Baseinfo: Baseinfo,
    CustomerFile: CustomerFile,
    Report: Report,
    RistQueryTable: RistQueryTable,
    HousingValuation: HousingValuation,
    ContractList: ContractList
  },
  props: {},
  data: function data() {
    return {
      order: {},
      record: {},
      option: [],
      customId: "",
      orderInfoIds: "",
      visible: false,
      title: "",
      path: "",
      whether: false,
      offsetTop: 0,
      offsetHeight: 0,
      confirmAlert: false,
      form: {
        rejectReason: "",
        rejectReasonDetail: "",
        id: "",
        orderStatus: null
      },
      activeKey: "",
      nodeName: "",
      editable: false,
      status: this.$route.params.status
    };
  },
  computed: {
    orderInfoId: function orderInfoId() {
      return this.$route.params.orderInfoId;
    },
    key: function key() {
      if (this.$route.params.status === 3) {
        return "baseinfo";
      }

      return "intention";
    }
  },
  watch: {},
  created: function created() {
    this.products();
  },
  mounted: function mounted() {
    this.loadOrderInfo();
    this.loadCustomerInfo();
    this.editorData(); // 禁用tab键

    window.addEventListener("keydown", function (event) {
      if (event.keyCode === 9) {
        event.preventDefault();
      }
    });
  },
  destroyed: function destroyed() {
    window.removeEventListener("keydown", function (event) {
      event.preventDefault();
    });
  },
  methods: {
    products: function products() {
      var _this = this;

      productsListAll().then(function (res) {
        if (res.code === 200) {
          _this.option = res.data;
        }
      });
    },
    // 子父传值，用于更新
    intentionData: function intentionData(data) {
      var _this2 = this;

      this.order.amount = data.amount;
      this.option.map(function (item) {
        if (item.id === data.productId) {
          _this2.order.productName = item.name;
        }
      });
    },
    reportDatas: function reportDatas(data) {
      this.order.amount = data.amount;
    },
    baseInfoDatas: function baseInfoDatas(data) {
      this.record.customerVO.name = data.customerVO.name;
      this.record.customerVO.phone = data.customerVO.phone;
    },
    editorData: function editorData() {
      var _this3 = this;

      if (this.$route.params.status !== "3") {
        userApproDetails(this.$route.params.userApproId).then(function (res) {
          var list = res.data.userApproNodeList.filter(function (item) {
            return item.approStatus === 1;
          });

          if (list.length !== 0) {
            _this3.nodeName = list[0].nodeName;
          }
        });
      }
    },
    handleCancel: function handleCancel() {
      this.visible = false;
    },
    disagreeRisk: function disagreeRisk() {
      this.form = {};
      this.title = "拒绝";
      this.visible = true;
    },
    handleOk: function handleOk() {
      var _this4 = this;

      var remark = "";
      reject(this.$route.params.userApproverId, {
        remark: remark
      }).then(function (res) {
        if (res.code === 200) {
          updateOrderInfos({
            id: _this4.$route.params.orderInfoId,
            auditStatus: 4,
            approvalStatus: _this4.nodeName,
            rejectReason: _this4.form.rejectReason,
            rejectReasonDetail: _this4.form.rejectReasonDetail
          }).then(function (res) {
            if (res.code === 200) {
              _this4.visible = false;

              _this4.$message.success("操作成功");

              _this4.goBack();
            }
          });
        }
      });
    },
    handleReject: function handleReject() {},
    goBack: function goBack() {
      var num = this.$route.params.status;
      this.$router.push({
        name: "审批列表",
        query: {
          status: num
        }
      });
    },
    goToBack: function goToBack() {
      this.$router.go(-1);
    },
    agreeRisk: function agreeRisk() {
      this.confirmAlert = true;
    },
    confirmHandle: function confirmHandle() {
      var _this5 = this;

      var remark = "";
      pass(this.$route.params.userApproverId, {
        remark: remark
      }).then(function (res) {
        if (res.code === 200) {
          updateOrderInfos({
            id: _this5.$route.params.orderInfoId,
            auditStatus: 2,
            approvalStatus: _this5.nodeName
          }).then(function (res) {
            if (res.code === 200) {
              _this5.$message.success("操作成功");

              _this5.confirmAlert = false;

              _this5.goBack();
            }
          });
        }
      });
    },
    loadOrderInfo: function loadOrderInfo() {
      var _this6 = this;

      this.customId = this.$route.params.id;
      this.orderInfoIds = this.$route.params.orderInfoId;
      orderInfoReq(this.$route.params.orderInfoId).then(function (res) {
        if (res.data) {
          _this6.order = res.data;
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    loadCustomerInfo: function loadCustomerInfo() {
      var _this7 = this;

      detailReq(this.$route.params.id).then(function (res) {
        if (res.data) {
          _this7.record = res.data;
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};