import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.to-fixed";
import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { productsListAll, collect, productRanking, direct, performance, distribution, user, spreadUser, performanceUser } from "./api";
import { mapGetters } from "vuex";
export default {
  data: function data() {
    return {
      id: undefined,
      option: [],
      // 产品数据
      visible: false,
      visibles: false,
      visible1: false,
      visibles2: false,
      newSumList: [],
      // 汇总统计数据
      directList: [],
      directNumList: [],
      performanceList: [],
      productRankingList: [],
      performanceNumList: [],
      columnList: [],
      column1List: [],
      systemDate: "",
      systemDates: "",
      dateNum: "",
      currentIndex: 0,
      achievementIndex: 0,
      gatherIndex: 1,
      countNum: 0,
      countNum1: 0,
      show: Boolean,
      columns: [{
        title: "序号",
        dataIndex: "id",
        key: "id"
      }, {
        title: "名称",
        dataIndex: "name",
        key: "name"
      }, {
        title: " 百分比",
        dataIndex: "num",
        key: "num",
        scopedSlots: {
          customRender: "num"
        }
      }, {
        title: "数值",
        dataIndex: "value",
        key: "value"
      }],
      columns1: [{
        title: "序号",
        dataIndex: "id",
        key: "id"
      }, {
        title: "名称",
        dataIndex: "name",
        key: "name"
      }, {
        title: "单量",
        dataIndex: "value",
        key: "value"
      }, {
        title: "金额",
        dataIndex: "money",
        key: "money"
      }],
      img: require("../../assets/tx.png"),
      date: [{
        name: "本月"
      }, {
        name: "今日"
      }],
      date1: [{
        name: "今日"
      }, {
        name: "本月"
      }],
      options: [{
        name: "直销",
        id: "2"
      }, {
        name: "分销",
        id: "1"
      }],
      data: [{
        name: "暂无数据",
        value: 0
      }]
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permissionStringList"])), {}, {
    name: function name() {
      return this.$store.getters.userInfo ? this.$store.getters.userInfo.name : "管理员";
    },
    avatar: function avatar() {
      return this.$store.getters.avatar && this.$store.getters.avatar !== "" ? this.$store.getters.userInfo.avatar : this.img;
    }
  }),
  watch: {
    permissionStringList: {
      handler: function handler(newVal) {
        if (newVal && newVal.length > 0) {
          var loan = "loan:dashboard:dashboard_all";

          var _distribution = newVal.filter(function (res) {
            return res === loan;
          });

          if (_distribution.length > 0) {
            this.dataList();
            this.show = true;
          } else {
            this.show = false;
            this.ruleList();
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    // const userName =  localStorage.getItem("userName")
    this.products();
    this.addDate();
  },
  mounted: function mounted() {},
  methods: {
    // 汇总个人推广量弹窗
    chakan: function chakan() {
      if (this.show) {
        this.visible = true;
      } else {
        this.visible1 = true;
      }
    },
    // 汇总个人业绩量弹窗
    chakanAll: function chakanAll() {
      if (this.show) {
        this.visibles = true;
      } else {
        this.visibles2 = true;
      }
    },
    // 跳转
    go: function go() {
      this.$router.push("/orderMange/order");
    },
    go1: function go1() {
      this.$router.push("/approvals/index");
    },
    go2: function go2() {
      if (this.show) {
        this.$router.push({
          name: "审批列表",
          query: {
            status: "2"
          }
        });
      } else {
        this.$router.push({
          name: "审批列表",
          query: {
            status: "1"
          }
        });
      }
    },
    // 获取全部首页汇总信息
    dataList: function dataList() {
      var _this = this;

      collect().then(function (res) {
        _this.newSumList = res.data;
      });
      productRanking().then(function (res) {
        _this.productRankingList = res.data.slice(0, 5);
      });
      direct().then(function (res) {
        var manyGroupOrder = res.data.slice(0, 8);
        var order = res.data;
        order.map(function (res, index) {
          _this.countNum += res.orderNum;

          _this.directNumList.push({
            id: index + 1,
            name: res.userName,
            value: res.orderNum
          });
        });
        manyGroupOrder.map(function (res, i) {
          if (i < 8) {
            _this.directList.push({
              name: res.userName,
              value: res.orderNum
            });
          }
        });

        _this.myChart();
      });
      performance().then(function (res) {
        var performanceOrder = res.data.slice(0, 8);
        var order = res.data;
        order.map(function (res, index) {
          _this.performanceNumList.push({
            name: res.userName,
            value: res.orderNum,
            money: res.userMoney,
            id: index + 1
          });
        });
        performanceOrder.map(function (res) {
          _this.performanceList.push({
            name: res.userName,
            value: res.orderNum,
            money: res.userMoney
          });
        });

        _this.myChart1();
      });
    },
    // 获取个人首页汇总信息
    ruleList: function ruleList() {
      var _this2 = this;

      user().then(function (res) {
        _this2.newSumList = res.data;
      });
      productRanking().then(function (res) {
        _this2.productRankingList = res.data.slice(0, 5);
      });
      spreadUser().then(function (res) {
        var manyGroupOrder = res.data.slice(0, 8);
        var order = res.data;
        order.map(function (res, index) {
          _this2.countNum1 += res.orderNum;

          _this2.columnList.push({
            id: index + 1,
            name: res.spreadName,
            value: res.orderNum
          });
        });
        manyGroupOrder.map(function (res, i) {
          if (i < 8) {
            _this2.directList.push({
              name: res.spreadName,
              value: res.orderNum
            });
          }
        });

        _this2.myChart();
      });
      performanceUser().then(function (res) {
        var performanceOrder = res.data.slice(0, 8);
        var order = res.data;
        order.map(function (res, index) {
          _this2.column1List.push({
            id: index + 1,
            name: res.productName,
            value: res.orderNum,
            money: res.productMoney
          });
        });
        performanceOrder.map(function (res) {
          _this2.performanceList.push({
            name: res.productName,
            value: res.orderNum,
            money: res.productMoney
          });
        });

        _this2.myChart1();
      });
    },
    // 分销获取接口
    group: function group() {
      var _this3 = this;

      distribution().then(function (res) {
        _this3.directList = [];
        var manyGroupOrder = res.data;
        manyGroupOrder.map(function (res, i) {
          if (i < 8) {
            _this3.directList.push({
              name: res.channelName,
              value: res.orderNum
            });
          }
        });

        _this3.myChart();
      });
    },
    // 直销获取接口
    order: function order() {
      var _this4 = this;

      this.directList = [];
      direct().then(function (res) {
        var manyGroupOrder = res.data;
        manyGroupOrder.map(function (res, i) {
          if (i < 8) {
            _this4.directList.push({
              name: res.userName,
              value: res.orderNum
            });
          }
        });

        _this4.myChart();
      });
    },
    // 直销分销事件
    handleChange: function handleChange(e) {
      this.id = e;

      if (e === "1") {
        this.group();
      } else if (e === "2" || e === undefined) {
        this.order();
      }
    },
    // 推广今日本月点击事件
    popularize: function popularize(index) {
      this.currentIndex = index;

      if (this.currentIndex === 0) {
        this.popularizeYue();
      } else {
        this.popularizeJin();
      }
    },
    // 推广统计今
    popularizeJin: function popularizeJin() {
      var _this5 = this;

      if (this.show) {
        if (this.id === "2" || this.id === undefined) {
          this.directList = [];
          direct({
            beginDate: this.systemDate,
            finishDate: this.systemDates
          }).then(function (res) {
            var manyGroupOrder = res.data.slice(0, 8);
            manyGroupOrder.map(function (res, i) {
              if (i < 8) {
                _this5.directList.push({
                  name: res.userName,
                  value: res.orderNum
                });
              }
            });

            _this5.myChart();
          });
        } else {
          distribution({
            beginDate: this.systemDate,
            finishDate: this.systemDates
          }).then(function (res) {
            _this5.directList = [];
            var manyGroupOrder = res.data.slice(0, 8);
            manyGroupOrder.map(function (res, i) {
              if (i < 8) {
                _this5.directList.push({
                  name: res.userName,
                  value: res.orderNum
                });
              }
            });

            _this5.myChart();
          });
        }
      } else {
        this.directList = [];
        spreadUser({
          beginDate: this.systemDate,
          finishDate: this.systemDates
        }).then(function (res) {
          var manyGroupOrder = res.data;
          manyGroupOrder.map(function (res, i) {
            if (i < 8) {
              _this5.directList.push({
                name: res.spreadName,
                value: res.orderNum
              });
            }
          });

          _this5.myChart();
        });
      }
    },
    // 推广统计月
    popularizeYue: function popularizeYue() {
      var _this6 = this;

      if (this.show) {
        if (this.id === "2" || this.id === undefined) {
          this.directList = [];
          direct({
            beginDate: this.dateNum,
            finishDate: this.systemDates
          }).then(function (res) {
            var manyGroupOrder = res.data.slice(0, 8);
            manyGroupOrder.map(function (res, i) {
              if (i < 8) {
                _this6.directList.push({
                  name: res.userName,
                  value: res.orderNum
                });
              }
            });

            _this6.myChart();
          });
        } else {
          distribution({
            beginDate: this.dateNum,
            finishDate: this.systemDates
          }).then(function (res) {
            _this6.directList = [];
            var manyGroupOrder = res.data.slice(0, 8);
            manyGroupOrder.map(function (res, i) {
              if (i < 8) {
                _this6.directList.push({
                  name: res.userName,
                  value: res.orderNum
                });
              }
            });

            _this6.myChart();
          });
        }
      } else {
        this.directList = [];
        spreadUser({
          beginDate: this.dateNum,
          finishDate: this.systemDates
        }).then(function (res) {
          var manyGroupOrder = res.data;
          manyGroupOrder.map(function (res, i) {
            if (i < 8) {
              _this6.directList.push({
                name: res.spreadName,
                value: res.orderNum
              });
            }
          });

          _this6.myChart();
        });
      }
    },
    // 推广统计时间检索
    onChange: function onChange(date, dateString) {
      var _this7 = this;

      if (this.show) {
        if (this.id === "2" || this.id === "") {
          this.directList = [];
          direct({
            beginDate: dateString[0],
            finishDate: dateString[1]
          }).then(function (res) {
            var manyGroupOrder = res.data.slice(0, 8);
            manyGroupOrder.map(function (res, i) {
              if (i < 8) {
                _this7.directList.push({
                  name: res.userName,
                  value: res.orderNum
                });
              }
            });

            _this7.myChart();
          });
        } else {
          distribution({
            beginDate: dateString[0],
            finishDate: dateString[1]
          }).then(function (res) {
            _this7.directList = [];
            var manyGroupOrder = res.data.slice(0, 8);
            manyGroupOrder.map(function (res, i) {
              if (i < 8) {
                _this7.directList.push({
                  name: res.channelName,
                  value: res.orderNum
                });
              }
            });
            console.log("debug log --> ", _this7.directList);

            _this7.myChart();
          });
        }
      } else {
        spreadUser({
          beginDate: dateString[0],
          finishDate: dateString[1]
        }).then(function (res) {
          var manyGroupOrder = res.data;
          manyGroupOrder.map(function (res, i) {
            if (i < 8) {
              _this7.directList.push({
                name: res.spreadName,
                value: res.orderNum
              });
            }
          });

          _this7.myChart();
        });
      }
    },
    // 第一个饼图
    myChart: function myChart() {
      var myChart = this.$echarts.init(document.getElementById("myChart"));
      var option = {
        title: {
          text: "",
          left: 40
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          top: 30,
          right: 55,
          itemWidth: 5,
          itemHeight: 5,
          textStyle: {
            fontSize: 13,
            lineHeight: 16
          },
          formatter: function formatter(params) {
            var data = option.series[0].data;
            var total = 0;
            var tarValue = 0;

            for (var i = 0, l = data.length; i < l; i++) {
              total += data[i].value;

              if (data[i].name === params) {
                tarValue = data[i].value;
              }
            }

            var p = (tarValue / total * 100).toFixed(2);

            if (tarValue === 0) {
              return params + " |" + " " + 0 + "%" + " | " + tarValue;
            } else {
              return params + " |" + " " + p + "%" + " | " + tarValue;
            }
          }
        },
        series: [{
          name: "暂无数据",
          type: "pie",
          color: ["#1890FF", "#FAD337", "#F04864", "#6DD8D8", "#ffc0cb"],
          radius: ["45%", "60%"],
          center: ["35%", "50%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center"
          },
          labelLine: {
            show: false
          },
          data: this.directList.length !== 0 ? this.directList : this.data
        }]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 业绩统计时间检索
    productsOnChange: function productsOnChange(date, dateString) {
      var _this8 = this;

      if (this.show) {
        this.performanceList = [];
        performance({
          beginDate: dateString[0],
          finishDate: dateString[1]
        }).then(function (res) {
          var performanceOrder = res.data.slice(0, 8);
          performanceOrder.map(function (res) {
            _this8.performanceList.push({
              name: res.userName,
              value: res.orderNum,
              money: res.userMoney
            });
          });

          _this8.myChart1();
        });
      } else {
        this.performanceList = [];
        performanceUser({
          beginDate: dateString[0],
          finishDate: dateString[1]
        }).then(function (res) {
          var performanceOrder = res.data.slice(0, 8);
          performanceOrder.map(function (res) {
            _this8.performanceList.push({
              name: res.productName,
              value: res.orderNum,
              money: res.productMoney
            });
          });

          _this8.myChart1();
        });
      }
    },
    achievement: function achievement(index) {
      this.achievementIndex = index;

      if (this.achievementIndex === 0) {
        this.achievementYue();
      } else {
        this.achievementJin();
      }
    },
    // 业绩统计今
    achievementJin: function achievementJin() {
      var _this9 = this;

      if (this.show) {
        this.performanceList = [];
        performance({
          beginDate: this.systemDate,
          finishDate: this.systemDates
        }).then(function (res) {
          var performanceOrder = res.data.slice(0, 8);
          performanceOrder.map(function (res) {
            _this9.performanceList.push({
              name: res.userName,
              value: res.orderNum,
              money: res.userMoney
            });
          });

          _this9.myChart1();
        });
      } else {
        this.performanceList = [];
        performanceUser({
          beginDate: this.systemDate,
          finishDate: this.systemDates
        }).then(function (res) {
          var performanceOrder = res.data.slice(0, 8);
          performanceOrder.map(function (res) {
            _this9.performanceList.push({
              name: res.productName,
              value: res.orderNum,
              money: res.productMoney
            });
          });

          _this9.myChart1();
        });
      }
    },
    // 业绩统计产品下拉事件
    productsChange: function productsChange(e) {
      var _this10 = this;

      this.performanceList = [];
      performance({
        productId: e
      }).then(function (res) {
        var performanceOrder = res.data.slice(0, 8);
        performanceOrder.map(function (res) {
          _this10.performanceList.push({
            name: res.userName,
            value: res.orderNum,
            money: res.userMoney
          });
        });

        _this10.myChart1();
      });
    },
    // 业绩统计月
    achievementYue: function achievementYue() {
      var _this11 = this;

      if (this.show) {
        this.performanceList = [];
        performance({
          beginDate: this.dateNum,
          finishDate: this.systemDates
        }).then(function (res) {
          var performanceOrder = res.data.slice(0, 8);
          performanceOrder.map(function (res) {
            _this11.performanceList.push({
              name: res.userName,
              value: res.orderNum,
              money: res.userMoney
            });
          });

          _this11.myChart1();
        });
      } else {
        this.performanceList = [];
        performanceUser({
          beginDate: this.dateNum,
          finishDate: this.systemDates
        }).then(function (res) {
          var performanceOrder = res.data.slice(0, 8);
          performanceOrder.map(function (res) {
            _this11.performanceList.push({
              name: res.productName,
              value: res.orderNum,
              money: res.productMoney
            });
          });

          _this11.myChart1();
        });
      }
    },
    gather: function gather(index) {
      this.gatherIndex = index;

      if (this.gatherIndex === 0) {
        this.gatherJin();
      } else {
        this.gatherYue();
      }
    },
    // 汇总统计日
    gatherJin: function gatherJin() {
      var _this12 = this;

      if (this.show) {
        collect({
          beginDate: this.systemDate,
          finishDate: this.systemDates
        }).then(function (res) {
          _this12.newSumList = res.data;
        });
      } else {
        user({
          beginDate: this.systemDate,
          finishDate: this.systemDates
        }).then(function (res) {
          _this12.newSumList = res.data;
        });
      }
    },
    // 汇总统计月
    gatherYue: function gatherYue() {
      var _this13 = this;

      if (this.show) {
        collect({
          beginDate: this.dateNum,
          finishDate: this.systemDates
        }).then(function (res) {
          _this13.newSumList = res.data;
        });
      } else {
        user({
          beginDate: this.dateNum,
          finishDate: this.systemDates
        }).then(function (res) {
          _this13.newSumList = res.data;
        });
      }
    },
    // 汇总统计时间检索
    finshOnChange: function finshOnChange(date, dateString) {
      var _this14 = this;

      if (this.show) {
        collect({
          beginDate: dateString[0],
          finishDate: dateString[1]
        }).then(function (res) {
          _this14.newSumList = res.data;
        });
      } else {
        user({
          beginDate: dateString[0],
          finishDate: dateString[1]
        }).then(function (res) {
          _this14.newSumList = res.data;
        });
      }
    },
    // 第二个饼图
    myChart1: function myChart1() {
      var myChart1 = this.$echarts.init(document.getElementById("myChart1"));
      var option = {
        title: {
          text: "",
          left: 40
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          top: 30,
          right: 145,
          itemWidth: 5,
          itemHeight: 5,
          textStyle: {
            fontSize: 13,
            lineHeight: 16
          },
          formatter: function formatter(params) {
            var data = option.series[0].data;
            var total = 0;
            var tarValue = 0;
            var tarMoney = 0;

            for (var i = 0, l = data.length; i < l; i++) {
              total += data[i].value;

              if (data[i].name === params) {
                tarMoney = data[i].money;
                tarValue = data[i].value;
              }
            }

            var p = (tarValue / total * 100).toFixed(2);

            if (tarValue === 0) {
              return params + " | " + tarValue + "单" + " | " + "" + tarMoney + "元";
            } else {
              return params + " | " + tarValue + "单" + " | " + "" + tarMoney + "元";
            }
          }
        },
        series: [{
          name: "访问来源",
          type: "pie",
          color: ["#1890FF", "#FAD337", "#F04864", "#6DD8D8", "#ffc0cb"],
          radius: ["45%", "60%"],
          center: ["35%", "50%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center"
          },
          labelLine: {
            show: false
          },
          data: this.performanceList
        }]
      };
      myChart1.setOption(option);
      window.addEventListener("resize", function () {
        myChart1.resize();
      });
    },
    // 时间格式化
    timestampToTime: function timestampToTime(value) {
      var date = new Date(value);
      var y = date.getFullYear(); // 年

      var MM = date.getMonth() + 1; // 月

      MM = MM < 10 ? "0" + MM : MM;
      var d = date.getDate(); // 日

      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    },
    // 获取接口所需时间
    addDate: function addDate() {
      var nowDate = new Date(); // 获取今天日期

      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      }; // 获取明日日期

      var date1 = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate() + 1
      };
      this.systemDate = date.year + "-" + date.month + "-" + date.day; // 今日时间

      this.systemDates = date1.year + "-" + date1.month + "-" + date1.day; // 明日时间
      // 获取本月的第一天

      var setDate = nowDate.setDate(1);
      this.dateNum = this.timestampToTime(setDate);
    },
    // 获取所有产品
    products: function products() {
      var _this15 = this;

      productsListAll().then(function (res) {
        if (res.code === 200) {
          _this15.option = res.data;
        }
      });
    }
  }
};