var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout",
        [
          _c(
            "a-layout-sider",
            { attrs: { width: 200 } },
            [
              _c(
                "div",
                {
                  staticClass: "allApp",
                  on: {
                    click: function($event) {
                      _vm.params = {}
                      _vm.getCcme()
                    }
                  }
                },
                [_vm._v(" 所有审批类型(" + _vm._s(_vm.listNumAll) + ") ")]
              ),
              _c(
                "a-menu",
                {
                  staticStyle: { "min-height": "600px" },
                  attrs: { mode: "inline", defaultSelectedKeys: ["1"] }
                },
                _vm._l(_vm.items, function(group) {
                  return _c(
                    "a-sub-menu",
                    {
                      key: group.id,
                      on: {
                        titleClick: function($event) {
                          return _vm.subMenu(group)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "title" }, slot: "title" },
                        [
                          _c("a-icon", { attrs: { type: "mail" } }),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(group.groupName) +
                                    "(" +
                                    _vm._s(group.notifierMeNum) +
                                    ") "
                                )
                              ]),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(group.groupName) +
                                    "(" +
                                    _vm._s(group.notifierMeNum) +
                                    ")"
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._l(group.approFlowList, function(item) {
                        return _c(
                          "a-menu-item",
                          {
                            key: item.id,
                            on: {
                              click: function($event) {
                                return _vm.menuItem(item)
                              }
                            }
                          },
                          [
                            _c(
                              "a-tooltip",
                              [
                                _c("template", { slot: "title" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.typeName) +
                                      "(" +
                                      _vm._s(item.notifierMeNum) +
                                      ") "
                                  )
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(item.typeName) +
                                    "(" +
                                    _vm._s(item.notifierMeNum) +
                                    ") "
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-layout-content",
            [
              _c(
                "a-row",
                {
                  staticClass: "approval_title",
                  attrs: { type: "flex", align: "middle" }
                },
                [
                  _c(
                    "a-form-item",
                    {
                      staticClass: "mg_r20",
                      attrs: {
                        label: "审批时间",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 16 }
                      }
                    },
                    [
                      _c("a-range-picker", {
                        staticStyle: { width: "240px" },
                        on: { change: _vm.onChange }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      staticClass: "mg_r20",
                      attrs: {
                        label: "审批类型",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 16 }
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "status",
                                {
                                  rules: [
                                    { required: false, message: "请选择" }
                                  ]
                                }
                              ],
                              expression:
                                "['status', { rules: [{ required: false, message: '请选择' }] }]"
                            }
                          ],
                          staticStyle: {
                            width: "200px",
                            "margin-left": "20px"
                          },
                          attrs: { allowClear: true },
                          on: { change: _vm.selectApproval }
                        },
                        _vm._l(_vm.approvalTypelist, function(status, index2) {
                          return _c(
                            "a-select-option",
                            { key: index2, attrs: { value: status.value } },
                            [_vm._v(" " + _vm._s(status.state) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-table",
                {
                  staticStyle: { "margin-left": "24px" },
                  attrs: {
                    pagination: _vm.pagination,
                    "data-source": _vm.data,
                    rowKey: function(record, index) {
                      return index
                    }
                  },
                  on: { change: _vm.handleTableChange }
                },
                [
                  _c("a-table-column", {
                    attrs: { title: "标题", "data-index": "remark" }
                  }),
                  _c("a-table-column", {
                    attrs: { title: "审批类型", "data-index": "approName" }
                  }),
                  _c("a-table-column", {
                    attrs: { title: "发起日期", "data-index": "approCreate" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(text) {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.formatDateAll(text, "d")) + " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("a-table-column", {
                    attrs: { title: "当前节点状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(record) {
                          return [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "f500 f20",
                                  style: {
                                    color: [
                                      "#2294FF",
                                      "#fb9d06",
                                      "#2294FF",
                                      "#de0000",
                                      "#999",
                                      "#fb9d06",
                                      "#de0000"
                                    ][record.nodeApproverStatu]
                                  }
                                },
                                [_vm._v("·")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    [
                                      "待审批",
                                      "审批中",
                                      "通过",
                                      "驳回",
                                      "撤回",
                                      "其他人通过",
                                      "其他人驳回"
                                    ][record.approStatu]
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("a-table-column", {
                    attrs: { title: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(record) {
                          return [
                            record.needAppro
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      type: "link",
                                      disabled: !record.needAppro
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editorData(record)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class: record.needAppro
                                          ? "colorFFB81E"
                                          : "colorC0C0C0"
                                      },
                                      [_vm._v(" 审批 ")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            record.needAppro
                              ? _c("a-divider", { attrs: { type: "vertical" } })
                              : _vm._e(),
                            _c(
                              "a",
                              {
                                staticStyle: { color: "#FCAF4F" },
                                attrs: { type: "link" },
                                on: {
                                  click: function($event) {
                                    return _vm.statusDetail(record)
                                  }
                                }
                              },
                              [_vm._v(" 详情 ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("new-approval", {
        attrs: { show: _vm.createApp },
        on: {
          "update:show": function($event) {
            _vm.createApp = $event
          }
        }
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            footer: null,
            title: _vm.ApprovalTitle,
            width: 570
          },
          on: {
            ok: function($event) {
              _vm.showStatus = false
            }
          },
          model: {
            value: _vm.showStatus,
            callback: function($$v) {
              _vm.showStatus = $$v
            },
            expression: "showStatus"
          }
        },
        [
          [
            _c(
              "div",
              { staticClass: "approval" },
              [
                _c(
                  "a-row",
                  {
                    staticClass: "approval-top mg_b10",
                    attrs: { type: "flex" }
                  },
                  [
                    _c("a-col", { attrs: { span: 3 } }, [
                      _c("img", {
                        staticStyle: { width: "48px", "border-radius": "50%" },
                        attrs: {
                          src: _vm.statusData.userAvatar
                            ? _vm.statusData.userAvatar
                            : _vm.defaultAvatar,
                          alt: "失败"
                        }
                      })
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 20 } },
                      [
                        _c("a-row", {}, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.statusData.userName))
                          ]),
                          _c("span", { staticClass: "sub_tit" }, [
                            _vm._v("(申请人)")
                          ])
                        ]),
                        _c("a-row", { staticClass: "mg_t5" }, [
                          _c(
                            "span",
                            {
                              staticClass: "f12",
                              style: {
                                color: [
                                  "#2294FF",
                                  "#fb9d06",
                                  "#2294FF",
                                  "#de0000",
                                  "#999",
                                  "#fb9d06",
                                  "#de0000"
                                ][_vm.statusData.status]
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  [
                                    "待审批",
                                    "审批中",
                                    "审批通过",
                                    "驳回",
                                    "撤回",
                                    "其他人通过",
                                    "其他人驳回"
                                  ][_vm.statusData.status]
                                )
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  [
                    _c(
                      "a-col",
                      { staticClass: "sub_tit text-right", attrs: { span: 4 } },
                      [_vm._v("审批编号 :")]
                    ),
                    _c(
                      "a-col",
                      { staticClass: "fs14 c3 pl10", attrs: { span: 18 } },
                      [_vm._v(_vm._s(_vm.statusData.approNumber))]
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  [
                    _c(
                      "a-col",
                      {
                        staticClass: "sub_tit mt10 text-right",
                        attrs: { span: 4 }
                      },
                      [_vm._v("审批类型 :")]
                    ),
                    _c(
                      "a-col",
                      { staticClass: "fs14 c3 mt10 pl10", attrs: { span: 18 } },
                      [_vm._v(_vm._s(_vm.statusData.approName))]
                    )
                  ],
                  1
                ),
                _c("a-divider"),
                _c(
                  "a-row",
                  { staticClass: "detail-form" },
                  [
                    _c("k-form-build", {
                      ref: "kfd",
                      attrs: { value: _vm.jsonData }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "pd_12 mg_t16 mg_b16 flws c3" }, [
                      _c("strong", [_vm._v("审批流程")])
                    ]),
                    _vm._l(_vm.statusData.userApproNodeList, function(
                      item,
                      id
                    ) {
                      return _c(
                        "div",
                        {
                          key: id,
                          staticClass: "bl-orange",
                          class: {
                            lastone:
                              _vm.statusData.userApproNodeList.length - 2 === id
                          }
                        },
                        [
                          item.nodeType == "start"
                            ? _c(
                                "a-row",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    "margin-left": "-19px"
                                  }
                                },
                                [
                                  _c("a-col", { attrs: { span: 3 } }, [
                                    _c("img", {
                                      staticClass: "avatar-mid",
                                      class: _vm.statusData.userAvatar
                                        ? ""
                                        : "bor0",
                                      attrs: {
                                        src: _vm.statusData.userAvatar
                                          ? _vm.statusData.userAvatar
                                          : _vm.defaultAvatarDone,
                                        alt: "加载失败"
                                      }
                                    })
                                  ]),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _c("a-row", [
                                        _c("strong", [_vm._v("发起申请")]),
                                        _c(
                                          "span",
                                          { staticClass: "sub_tit fr" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.statusData.gmtCreate
                                                    ? _vm.formatDateAll(
                                                        _vm.statusData
                                                          .gmtCreate,
                                                        "d"
                                                      )
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("a-row", { staticClass: "sub_tit" }, [
                                        _vm._v(_vm._s(_vm.statusData.userName))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.nodeType == "task" &&
                          item.userFlowApproverList &&
                          item.userFlowApproverList.length === 1
                            ? _c(
                                "a-row",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    "margin-left": "-19px",
                                    "padding-top": "24px"
                                  }
                                },
                                [
                                  _c("a-col", { attrs: { span: 3 } }, [
                                    _c("img", {
                                      staticClass: "avatar-mid",
                                      class: item.userFlowApproverList[0]
                                        .approverUserAvatar
                                        ? ""
                                        : "bor0",
                                      attrs: {
                                        src: item.userFlowApproverList[0]
                                          .approverUserAvatar
                                          ? item.userFlowApproverList[0]
                                              .approverUserAvatar
                                          : _vm.defaultAvatar,
                                        alt: "加载失败"
                                      }
                                    })
                                  ]),
                                  _c(
                                    "a-col",
                                    {
                                      staticClass: "approval-one-notifier",
                                      attrs: { span: 20 }
                                    },
                                    [
                                      _c("a-row", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              item.userFlowApproverList[0]
                                                .approverUserName
                                            )
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                "·" +
                                                  " " +
                                                  _vm.getStatusTitle(
                                                    item.approStatus
                                                  )
                                              )
                                          )
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "sub_tit fr" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.gmtNodeEnd
                                                    ? _vm.formatDateAll(
                                                        item.gmtNodeEnd,
                                                        "d"
                                                      )
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      item.userFlowApproverList[0]
                                        .approverOpinion
                                        ? _c(
                                            "a-row",
                                            {
                                              staticClass: "notifier flex-start"
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "opinion" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "“" +
                                                          item
                                                            .userFlowApproverList[0]
                                                            .approverOpinion +
                                                          " ”"
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.notifierlist.userNameList &&
                                      _vm.notifierlist.userNameList.length > 0
                                        ? _c(
                                            "a-row",
                                            {
                                              staticClass: "notifier pl20 flex"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "color222 title"
                                                },
                                                [_vm._v("抄送人：")]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  staticClass:
                                                    "flex-start notifierlist"
                                                },
                                                _vm._l(
                                                  _vm.notifierlist.userNameList,
                                                  function(notifier, idx4) {
                                                    return _c(
                                                      "div",
                                                      { key: idx4 },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(notifier)
                                                          )
                                                        ]),
                                                        _vm.notifierlist
                                                          .userNameList.length -
                                                          idx4 >
                                                        1
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "pr10"
                                                              },
                                                              [_vm._v(" , ")]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.nodeType == "task" &&
                          item.userFlowApproverList &&
                          item.userFlowApproverList.length > 1
                            ? _c(
                                "a-row",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    "margin-left": "-19px",
                                    "padding-top": "24px"
                                  }
                                },
                                [
                                  _c("a-col", { attrs: { span: 3 } }, [
                                    item.approStatus < 2
                                      ? _c("img", {
                                          staticClass: "avatar-mid bor0",
                                          attrs: {
                                            src: _vm.defaultAvatarWait,
                                            alt: "加载失败"
                                          }
                                        })
                                      : _vm._e(),
                                    (item.approStatus === 2) |
                                    (item.approStatus === 5)
                                      ? _c("img", {
                                          staticClass: "avatar-mid bor0",
                                          attrs: {
                                            src: _vm.defaultAvatarDone,
                                            alt: "加载失败"
                                          }
                                        })
                                      : _vm._e(),
                                    (item.approStatus === 3) |
                                    (item.approStatus === 4) |
                                    (item.approStatus === 6)
                                      ? _c("img", {
                                          staticClass: "avatar-mid bor0",
                                          attrs: {
                                            src: _vm.defaultAvatarBack,
                                            alt: "加载失败"
                                          }
                                        })
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _c("a-row", [
                                        _c("strong", [
                                          _vm._v(_vm._s(item.nodeName))
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                "·" +
                                                  " " +
                                                  _vm.getStatusTitle(
                                                    item.approStatus
                                                  )
                                              )
                                          )
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "border-type" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getActivateType(
                                                    item.activateType
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.showPeople(id)
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "arrow-icon",
                                              attrs: {
                                                src:
                                                  _vm.showApprovaler === id
                                                    ? _vm.arrowUp
                                                    : _vm.arrowDown,
                                                alt: ""
                                              }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "sub_tit fr" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.gmtNodeEnd
                                                    ? _vm.formatDateAll(
                                                        item.gmtNodeEnd,
                                                        "d"
                                                      )
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "a-row",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.showApprovaler === id,
                                              expression:
                                                "showApprovaler === id"
                                            }
                                          ],
                                          staticClass: "approvaler-wrap"
                                        },
                                        [
                                          _vm._l(
                                            item.userFlowApproverList,
                                            function(approvaler, idx3) {
                                              return _c(
                                                "a-row",
                                                {
                                                  key: idx3,
                                                  staticClass: "approvaler-item"
                                                },
                                                [
                                                  _c(
                                                    "a-row",
                                                    {
                                                      staticClass:
                                                        "flex approvaler-info"
                                                    },
                                                    [
                                                      _c("a-col", [
                                                        _c("img", {
                                                          staticStyle: {
                                                            width: "30px",
                                                            "border-radius":
                                                              "50%",
                                                            "z-index": "5"
                                                          },
                                                          attrs: {
                                                            src: approvaler.approverUserAvatar
                                                              ? approvaler.approverUserAvatar
                                                              : _vm.defaultAvatar,
                                                            alt: "加载失败"
                                                          }
                                                        })
                                                      ]),
                                                      _c(
                                                        "a-col",
                                                        {
                                                          staticClass:
                                                            "username"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                approvaler.approverUserName
                                                              ) +
                                                              " "
                                                          ),
                                                          approvaler.status > 1
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "·" +
                                                                        " " +
                                                                        _vm.getStatusTitle(
                                                                          approvaler.status
                                                                        )
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  approvaler.approverOpinion
                                                    ? _c("a-row", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "opinion"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  " “ " +
                                                                    approvaler.approverOpinion +
                                                                    " ” "
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm.notifierlist.userNameList &&
                                          _vm.notifierlist.userNameList.length >
                                            0
                                            ? _c(
                                                "a-row",
                                                {
                                                  staticClass:
                                                    "notifier pl20 flex"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "color222 title"
                                                    },
                                                    [_vm._v("抄送人：")]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticClass:
                                                        "flex-start notifierlist"
                                                    },
                                                    _vm._l(
                                                      _vm.notifierlist
                                                        .userNameList,
                                                      function(notifier, idx4) {
                                                        return _c(
                                                          "div",
                                                          { key: idx4 },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(notifier)
                                                              )
                                                            ]),
                                                            _vm.notifierlist
                                                              .userNameList
                                                              .length -
                                                              idx4 >
                                                            1
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "pr10"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " , "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  ],
                  2
                ),
                _vm.showApprovalHandle
                  ? _c(
                      "a-form",
                      {
                        staticStyle: { "padding-bottom": "40px" },
                        attrs: { form: _vm.form }
                      },
                      [
                        _c(
                          "a-form-item",
                          { attrs: { label: "审批意见" } },
                          [
                            _c("a-textarea", {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "remark",
                                    {
                                      rules: [
                                        {
                                          required:
                                            _vm.statusData.remakeRequire,
                                          message: "请填写审批意见"
                                        }
                                      ]
                                    }
                                  ],
                                  expression:
                                    "[\n                'remark',\n                {\n                  rules: [\n                    {\n                      required: statusData.remakeRequire,\n                      message: '请填写审批意见'\n                    }\n                  ]\n                }\n              ]"
                                }
                              ],
                              attrs: {
                                placeholder: _vm.statusData.remakeTips
                                  ? _vm.statusData.remakeTips
                                  : "填写审批意见",
                                autoSize: { minRows: 5, maxRows: 10 }
                              }
                            })
                          ],
                          1
                        ),
                        _vm.showApprovalHandle
                          ? _c(
                              "div",
                              { staticClass: "drawerFoot" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        _vm.showStatus = false
                                      }
                                    }
                                  },
                                  [_vm._v("取消")]
                                ),
                                _c("a-button", { on: { click: _vm.reject } }, [
                                  _vm._v("拒绝")
                                ]),
                                _c(
                                  "a-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.agree }
                                  },
                                  [_vm._v("通过")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            title: "提示",
            visible: _vm.confirmAlert,
            confirmLoading: _vm.confirmLoading
          },
          on: {
            ok: _vm.confirmHandle,
            cancel: function($event) {
              _vm.confirmAlert = false
            }
          }
        },
        [
          _c("p", { staticClass: "f500" }, [
            _vm._v(
              "确定" + _vm._s(_vm.approvalHandle ? "同意" : "拒绝") + "该审批吗"
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }