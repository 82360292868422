//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import XqFormMixin from "xqjr-plugin-form/mixins/XqFormMixin";
var FormMixin = XqFormMixin();
export default {
  name: "",
  components: {},
  mixins: [FormMixin],
  props: {
    dataSource: {
      type: Object,
      default: function _default() {}
    },
    editable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: true
    },
    dynamicData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      spouseInfo: false,
      intentionKeys: ["amount", "productId", "deadline", "repaymentType", "loanToDo", "remark"]
    };
  },
  methods: {
    onValuesChangeCustom: function onValuesChangeCustom(values) {}
  }
};