//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import store from "xqjr-module-auth/store/index"; // eslint-disable-line no-unused-vars

import { approFlowGroupsUpdate, approFlowGroupsCreate, approFlowGroupsDelete } from "@/api/index";
export default {
  name: "",
  inject: ["reload"],
  components: {},
  props: {
    newGroupOptions: {
      type: Object,
      default: function _default() {
        return {
          show: false,
          obj: {}
        };
      }
    }
  },
  data: function data() {
    return {
      visible: false,
      obj: {
        moduleType: process.env.VUE_APP_SUBJECT_TYPE,
        groupName: "",
        groupDesc: ""
      }
    };
  },
  computed: {},
  watch: {
    newGroupOptions: {
      handler: function handler(val) {
        this.visible = val.show;
        val.obj.moduleType = process.env.VUE_APP_SUBJECT_TYPE;
        this.obj = val.obj;
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // 删除分组
    deleteGroup: function deleteGroup(item) {
      var _this = this;

      this.$confirm({
        title: "确定删除?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: function onOk() {
          approFlowGroupsDelete(item.id).then(function (data) {
            if (data.success) {
              _this.$notification["success"]({
                message: "删除成功"
              });

              _this.visible = false;

              _this.reload();
            }
          });
        }
      });
    },
    saveData: function saveData() {
      var _this2 = this;

      this.obj.companyId = localStorage.getItem("lesseeId");

      if (this.obj.id) {
        if (this.obj.groupName !== "") {
          approFlowGroupsUpdate(this.obj).then(function (data) {
            if (data.success) {
              _this2.$notification["success"]({
                message: "修改成功"
              });

              _this2.reload();

              _this2.visible = false;
            }
          });
        } else {
          this.$message.warning("请输入分组名称");
        }
      } else {
        approFlowGroupsCreate(this.obj).then(function (data) {
          if (data.success) {
            _this2.$notification["success"]({
              message: "新增成功"
            });

            _this2.reload();

            _this2.visible = false;
          }
        });
      }
    }
  }
};