//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import XqFormMixin from "xqjr-plugin-form/mixins/XqFormMixin";
var FormMixin = XqFormMixin();
import { detailReq } from "@/views/Customer/api";
export default {
  name: "",
  components: {},
  mixins: [FormMixin],
  props: {
    dataSource: {
      type: Object,
      default: function _default() {}
    },
    editable: {
      type: Boolean,
      default: true
    },
    dynamicData: {
      type: Object,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      default: true
    },
    status: {
      type: String,
      default: function _default() {}
    },
    customId: {
      type: String,
      default: ""
    },
    orderInfoIds: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      tempRecords: null
    };
  },
  watch: {
    editable: function editable(val) {
      var _this = this;

      if (val === false) {
        detailReq(this.customId).then(function (res) {
          if (res.code === 200) {
            _this.tempRecords = res.data.reportVO;
          }
        });
      }
    }
  },
  created: function created() {
    var _this2 = this;

    detailReq(this.customId).then(function (res) {
      if (res.code === 200) {
        _this2.tempRecords = res.data.reportVO;
      }
    });
  },
  mounted: function mounted() {},
  methods: {
    onValuesChangeCustom: function onValuesChangeCustom(values) {}
  }
};