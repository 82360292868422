import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
import { retrieveReq, customerInfos, allGroupAndFlowByCompanyUser, allCanUseByFlow, getFlowProcessByApproInfo, userAppros, orderInfos, userApprosList } from "../api";
import RecordForm from "@/views/orderMange/FormEry.json";
import { CurdMixin } from "xqjr-plugin-common";
import { formatDate } from "@/utils/date"; // ----------------------------------------------------- 构建表格配置，包括基本项、操作

var columns = RecordForm.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
});
columns.push({
  width: 120,
  title: "操作",
  dataIndex: "crudAction",
  scopedSlots: {
    customRender: "crudAction"
  },
  type: "action",
  fixed: "right"
}); // ----------------------------------------------------- 生成 mixin

var mixin = new CurdMixin({
  retrieveReq: retrieveReq,
  RecordForm: RecordForm,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

export default {
  components: {},
  filters: {
    filterType: function filterType(val) {
      if (val === 1) {
        return "分销";
      } else if (val === 2) {
        return "直销";
      } else {
        return "";
      }
    }
  },
  mixins: [mixin],
  props: {},
  data: function data() {
    return {
      pageSize: 20,
      confirmAlert: false,
      orderInfoId: ""
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          approvalStatus: "拒绝该审批"
        }
      };
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    goTo: function goTo(record) {
      this.confirmAlert = true;
      this.orderInfoId = record.id;
    },
    confirmHandle: function confirmHandle() {
      var _this = this;

      var no = {
        id: this.orderInfoId,
        approvalStatus: "未建审批",
        auditStatus: 1,
        rejectReason: this.rejectReason
      };
      orderInfos(no).then(function (res) {
        _this.$message.success("操作成功");

        _this.confirmAlert = false;

        _this.retrieveRecord();
      });
    },
    // 筛选
    serchEnter: function serchEnter() {}
  }
};