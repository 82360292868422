var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    [
      _c(
        "a-spin",
        { staticClass: "xq-auto-wrapper", attrs: { spinning: _vm.spinning } },
        [
          _c(
            "div",
            { staticClass: "xq-auto-search" },
            [
              _vm._v(" 姓名 : "),
              _c("a-input", {
                staticStyle: { width: "200px", "margin-right": "20px" },
                attrs: { placeholder: "请输入", allowClear: "" },
                on: { change: _vm.serchEnter },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              }),
              _vm._v(" 手机号 : "),
              _c("a-input", {
                staticStyle: { width: "200px", "margin-right": "20px" },
                attrs: { placeholder: "请输入", allowClear: "" },
                on: { change: _vm.serchEnter },
                model: {
                  value: _vm.phone,
                  callback: function($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone"
                }
              }),
              _vm._v(" 状态 : "),
              _c(
                "a-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "请选择", allowClear: "" },
                  on: { change: _vm.serchEnter },
                  model: {
                    value: _vm.auditStatus,
                    callback: function($$v) {
                      _vm.auditStatus = $$v
                    },
                    expression: "auditStatus"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: "2" } }, [
                    _vm._v(" 同意 ")
                  ]),
                  _c("a-select-option", { attrs: { value: "4" } }, [
                    _vm._v(" 拒绝 ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "center" }, [
            _c("div", { staticClass: "xq-auto-action" }, [
              _c("div", { staticClass: "xq-auto-action-left" }),
              _c("div", { staticClass: "xq-auto-action-right" })
            ]),
            _c(
              "div",
              { staticClass: "xq-auto-content" },
              [
                _c(
                  "a-table",
                  {
                    staticClass: "xq-auto-content-table",
                    attrs: {
                      columns: _vm.columns,
                      "data-source": _vm.tableData,
                      pagination: _vm.pagination,
                      rowKey: function(record) {
                        return record.orderInfoVO.id
                      }
                    },
                    on: { change: _vm.onTableChange },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.columns, function(col, index) {
                          return {
                            key: col.dataIndex,
                            fn: function(text, record) {
                              return [
                                col.dataIndex === "name"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.customerInfosVO.customerVO
                                              .name
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "phone"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.customerInfosVO.customerVO.phone.substr(
                                              0,
                                              3
                                            ) +
                                              "****" +
                                              record.customerInfosVO.customerVO.phone.substr(
                                                7
                                              )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "productName"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.orderInfoVO.productName
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "rejectReason"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("rejectType")(
                                              record.orderInfoVO.rejectReason
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "sourceType"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("filterType")(
                                              record.orderInfoVO.sourceType
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "incomingNum"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.orderInfoVO.incomingNum
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "currentAddressDetail"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.customerInfoVO
                                              .currentAddressDetail
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "deadline"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(record.orderInfoVO.deadline) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "auditStatus"
                                  ? _c(
                                      "span",
                                      { key: index },
                                      [
                                        record.orderInfoVO.auditStatus == 2
                                          ? [
                                              _c(
                                                "a-tag",
                                                { attrs: { color: "#45B970" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        record.orderInfoVO
                                                          .approvalStatus +
                                                          "--" +
                                                          "已同意"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          : [
                                              _c(
                                                "a-tag",
                                                { attrs: { color: "#FA5051" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        record.orderInfoVO
                                                          .approvalStatus +
                                                          "--" +
                                                          "已拒绝"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  : col.dataIndex === "crudAction"
                                  ? _c("span", { key: index }, [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.statusDetail(record, 2)
                                            }
                                          }
                                        },
                                        [_vm._v("详情")]
                                      )
                                    ])
                                  : _c("table-cell", {
                                      key: index,
                                      attrs: {
                                        text: text,
                                        record: record,
                                        column: col,
                                        dynamicData: _vm.dynamicData
                                      }
                                    })
                              ]
                            }
                          }
                        })
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._l(_vm.columns, function(col, index) {
                      return _c(
                        "template",
                        { slot: "custom_" + col.dataIndex },
                        [
                          _c(
                            "span",
                            { key: index },
                            [
                              col.help
                                ? _c(
                                    "a-tooltip",
                                    { attrs: { title: col.help } },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: { type: "question-circle" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" " + _vm._s(col.customTitle) + " ")
                            ],
                            1
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "xq-auto-content-pagination" },
                  [
                    _c(
                      "div",
                      { staticClass: "xq-auto-content-pagination-total" },
                      [_vm._v("共 " + _vm._s(_vm.total) + " 条")]
                    ),
                    _c("a-pagination", {
                      attrs: {
                        pageSize: _vm.pageSize,
                        total: _vm.total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ["20", "50", "100"]
                      },
                      on: {
                        change: _vm.onPageChange,
                        showSizeChange: _vm.onPageSizeChange
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm.jsonData
            ? _c("k-form-build", {
                ref: "kfb",
                attrs: { value: _vm.jsonData, dynamicData: _vm.dynamicData },
                on: {
                  change: function($event) {
                    return _vm.onFormChange(arguments[0], arguments[1], "kfb")
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }