import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import FormJson from "./intentionEdittableForm";
import { orderInfoReq, getAllProduct, updateOrderInfoReq } from "@/views/Customer/api";
import IntentionEdittable from "./intentionEdittable";
export default {
  name: "",
  components: {
    IntentionEdittable: IntentionEdittable
  },
  props: {
    disabled: {
      type: Boolean,
      default: true
    },
    status: {
      type: String,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      FormJson: FormJson,
      editable: false,
      update: true,
      keep: false,
      tempRecord: null,
      dynamicData: {}
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    this.buildDynamicData();
    orderInfoReq(this.$route.params.orderInfoId).then(function (res) {
      if (res.data) {
        _this.tempRecord = res.data;
      }
    }).catch(function (err) {
      console.log("debug log --> ", err);
    });
  },
  methods: {
    quxiao: function quxiao() {
      var _this2 = this;

      this.editable = false;
      orderInfoReq(this.$route.params.orderInfoId).then(function (res) {
        if (res.data) {
          _this2.tempRecord = res.data;
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    getData: function getData() {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        try {
          _this3.$refs["kfb"].getData().then(function (values) {
            var columnKeys = Object.keys(_this3.FormJson);
            var tableKeys = columnKeys.filter(function (item) {
              return _this3.FormJson[item]["type"] === "table";
            });

            if (tableKeys.length > 0) {
              var valueKeys = Object.keys(values);

              var _loop = function _loop(i) {
                var tableKey = tableKeys[i];
                var groupKeys = [];
                var filteredKeys = valueKeys.filter(function (valueKey) {
                  return valueKey.match(tableKey);
                });
                var filteredValues = [];

                for (var j = 0; j < filteredKeys.length; j++) {
                  var filteredKey = filteredKeys[j];
                  var splitKeys = filteredKey.split("_".concat(tableKey, "_"));

                  if (!groupKeys.includes(splitKeys[1])) {
                    groupKeys.push(splitKeys[1]);
                  }

                  filteredValues.push({
                    key: filteredKey,
                    value: values[filteredKey],
                    valueKey: splitKeys[0],
                    groupKey: splitKeys[1]
                  });
                  delete values[filteredKey];
                } // values[tableKey] = makeList(filteredValues, groupKeys);

              };

              for (var i = 0; i < tableKeys.length; i++) {
                _loop(i);
              }
            }

            updateOrderInfoReq(Object.assign({}, _this3.tempRecord, values)).then(function (res) {
              if (res.success === true) {
                _this3.$message.success("修改成功");

                _this3.update = false;

                _this3.$emit("data", values);

                resolve(values);
              } else {
                _this3.$message.error("修改失败");
              }
            }).catch(function (err) {
              _this3.$message.error(err.message);

              console.log("debug log --> ", err);
            });
          });
        } catch (err) {
          reject(err);
        }
      });
    },
    buildDynamicData: function buildDynamicData() {
      var tempDynamicData = {};
      var data = [];
      getAllProduct().then(function (res) {
        res.data.map(function (item) {
          data.push({
            value: item.id,
            label: item.name
          });
        });
      });
      tempDynamicData["repaymentTypeList"] = data;
      this.dynamicData = tempDynamicData;
    },
    chainObj: function chainObj(source) {
      var _this4 = this;

      var tail = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      var value = arguments.length > 2 ? arguments[2] : undefined;
      var label = arguments.length > 3 ? arguments[3] : undefined;
      var formatter = arguments.length > 4 ? arguments[4] : undefined;

      if (tail && tail.length > 0) {
        var tailArr = tail.split(".");
        var temp = JSON.parse(JSON.stringify(source));

        for (var i = 0; i < tailArr.length; i++) {
          temp = temp[tailArr[i]];
        }

        return temp.map(function (item) {
          if (formatter && _this4[formatter]) {
            return Object.assign({}, _this4[formatter](item), {
              item: item
            });
          } else {
            return {
              value: item[value],
              label: item[label],
              item: item
            };
          }
        });
      } else {
        return source.map(function (item) {
          if (formatter && _this4[formatter]) {
            return Object.assign({}, _this4[formatter](item), {
              item: item
            });
          } else {
            return {
              value: item[value],
              label: item[label],
              item: item
            };
          }
        });
      }
    }
  }
};