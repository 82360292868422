import "core-js/modules/es.array.concat";
var channelId = localStorage.getItem("lesseeId");
export var RecordForm = {
  list: [{
    type: "input",
    label: "推广名",
    icon: "icon-write",
    customize: {
      create: true,
      edit: true,
      table: true,
      detail: false,
      search: true,
      sort: false,
      url: "",
      tail: "",
      value: "",
      label: ""
    },
    options: {
      type: "text",
      width: "100%",
      defaultValue: "",
      placeholder: "",
      clearable: false,
      maxLength: null,
      hidden: false,
      disabled: false
    },
    model: "name",
    key: "input_name",
    rules: [{
      required: true,
      message: "必填项"
    }]
  }, {
    type: "input",
    label: "推广码链接",
    icon: "icon-write",
    customize: {
      create: false,
      edit: false,
      table: true,
      detail: false,
      search: false,
      sort: false,
      url: "",
      tail: "",
      value: "",
      label: ""
    },
    options: {
      type: "text",
      width: "100%",
      defaultValue: "",
      placeholder: "",
      clearable: false,
      maxLength: null,
      hidden: false,
      disabled: false
    },
    model: "extendLink",
    key: "input_extendLink"
  }, {
    type: "input",
    label: "是否启用",
    icon: "icon-write",
    customize: {
      create: false,
      edit: false,
      table: true,
      detail: false,
      search: false,
      sort: false,
      url: "",
      tail: "",
      value: "",
      label: ""
    },
    options: {
      type: "text",
      width: "100%",
      defaultValue: "",
      placeholder: "",
      clearable: false,
      maxLength: null,
      hidden: false,
      disabled: false
    },
    model: "enabled",
    key: "input_enabled",
    rules: [{
      required: true,
      message: "必填项"
    }]
  }, {
    type: "select",
    label: "选择产品",
    icon: "icon-write",
    customize: {
      create: true,
      edit: true,
      table: false,
      detail: false,
      search: false,
      sort: false,
      url: "/finance/api/v1/products/listAll?channelId=".concat(channelId),
      tail: "data",
      value: "id",
      label: "name"
    },
    options: {
      type: "select",
      width: "100%",
      multiple: true,
      placeholder: "",
      clearable: false,
      maxLength: null,
      hidden: false,
      disabled: false,
      dynamicKey: "enumOptions2",
      dynamic: true,
      options: []
    },
    model: "productIds",
    key: "input_productIds",
    rules: [{
      required: true,
      message: "必填项"
    }]
  }, {
    type: "input",
    label: "联系人电话",
    icon: "icon-write",
    customize: {
      create: true,
      edit: true,
      table: false,
      detail: true,
      search: false,
      sort: false,
      url: "",
      tail: "",
      value: "",
      label: ""
    },
    options: {
      type: "text",
      width: "100%",
      defaultValue: "",
      placeholder: "",
      clearable: false,
      maxLength: null,
      hidden: false,
      disabled: false
    },
    model: "contactPhone",
    key: "input_1599702668308",
    rules: [{
      required: true,
      message: "必填项"
    }]
  }, {
    type: "select",
    label: "推广模板",
    icon: "icon-xiala",
    customize: {
      create: true,
      edit: true,
      table: false,
      detail: true,
      search: false,
      sort: false,
      url: "/finance/api/v1/templates/listAll?type=1&source:".concat(process.env.VUE_APP_SOURCE, "&channelId=").concat(channelId),
      tail: "data",
      value: "id",
      label: "name"
    },
    options: {
      width: "100%",
      multiple: false,
      disabled: false,
      clearable: true,
      hidden: false,
      placeholder: "",
      dynamicKey: "enumOptions",
      dynamic: true,
      options: [],
      showSearch: false
    },
    model: "backgroundSpread",
    key: "select_1599702795993",
    rules: [{
      required: true,
      message: "必填项"
    }]
  }, {
    type: "select",
    label: "H5模板",
    icon: "icon-xiala",
    customize: {
      create: true,
      edit: true,
      table: false,
      detail: true,
      search: false,
      sort: false,
      url: "/finance/api/v1/templates/listAll?type=3&source:".concat(process.env.VUE_APP_SOURCE, "&channelId=").concat(channelId),
      tail: "data",
      value: "id",
      label: "name"
    },
    options: {
      width: "100%",
      multiple: false,
      disabled: false,
      clearable: false,
      hidden: false,
      placeholder: "",
      dynamicKey: "",
      dynamic: true,
      options: [],
      showSearch: false
    },
    model: "template",
    key: "select_1599702795993",
    rules: [{
      required: true,
      message: "必填项"
    }]
  }, {
    type: "textarea",
    label: "备注",
    icon: "icon-edit",
    customize: {
      create: true,
      edit: true,
      table: false,
      detail: true,
      search: false,
      sort: false,
      url: "",
      tail: "",
      value: "",
      label: ""
    },
    options: {
      width: "100%",
      minRows: 4,
      maxRows: 6,
      maxLength: null,
      defaultValue: "",
      clearable: false,
      hidden: false,
      disabled: false,
      placeholder: "请输入产品介绍"
    },
    model: "remark",
    key: "textarea_1599702690916",
    rules: [{
      required: false,
      message: "必填项"
    }]
  }],
  config: {
    layout: "horizontal",
    labelCol: {
      span: 4
    },
    customize: {
      batch: true
    },
    wrapperCol: {
      span: 18
    },
    hideRequiredMark: false,
    customStyle: ""
  }
};