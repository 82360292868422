var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "approval-wrapper" },
    [
      _c(
        "a-tabs",
        {
          attrs: { defaultActiveKey: _vm.status },
          on: { change: _vm.callback }
        },
        [
          _c(
            "a-tab-pane",
            { key: "1" },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _vm._v(" 待办池 "),
                  _c("a-badge", {
                    staticClass: "badge",
                    attrs: { count: _vm.countNum }
                  })
                ],
                1
              ),
              _c("my-approval", {
                on: { confirmHandlesList: _vm.confirmHandlesList }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2" },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _vm._v(" 待处理 "),
                  _c("a-badge", {
                    staticClass: "badge",
                    attrs: { count: _vm.countNum1, showZero: "" }
                  })
                ],
                1
              ),
              _c("my-setting", { ref: "setting" })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "已处理" } },
            [_c("my-processed", { ref: "processed" })],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "4", attrs: { tab: "所有订单" } },
            [_c("order-List")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }