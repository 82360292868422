var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _vm._v(" 客户姓名 : "),
          _c("a-input", {
            staticStyle: { width: "200px", "margin-right": "20px" },
            attrs: { placeholder: "请输入", allowClear: "" },
            on: { change: _vm.search },
            model: {
              value: _vm.from.customerName,
              callback: function($$v) {
                _vm.$set(_vm.from, "customerName", $$v)
              },
              expression: "from.customerName"
            }
          })
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataList,
          rowKey: "id",
          pagination: _vm.paginationOpt
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function(text, record) {
              return _c("span", {}, [
                _vm._v(_vm._s(record.customerInfosVO.customerVO.name))
              ])
            }
          },
          {
            key: "tableAction",
            fn: function(text, record) {
              return _c("span", {}, [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.show(record)
                      }
                    }
                  },
                  [_vm._v("查看")]
                )
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }