var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.needTitle
        ? _c(
            "div",
            {
              style: {
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "500",
                borderBottom: "1px solid #efefef",
                paddingBottom: "20px"
              }
            },
            [_vm._v(" 风控查询 ")]
          )
        : _vm._e(),
      _c(
        "a-form",
        {
          staticClass: "form",
          style: {
            width: "600px",
            margin: "50px auto 50px",
            paddingRight: "70px"
          },
          attrs: {
            form: _vm.checkForm,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
          }
        },
        [
          _c(
            "a-form-item",
            { attrs: { colon: true, label: "姓名" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name",
                      { rules: [{ required: true, message: "请输入姓名" }] }
                    ],
                    expression:
                      "['name', { rules: [{ required: true, message: '请输入姓名' }] }]"
                  }
                ],
                attrs: { placeholder: "请输入姓名" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { colon: true, label: "手机号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "mobile",
                      { rules: [{ required: true, message: "请输入手机号" }] }
                    ],
                    expression:
                      "['mobile', { rules: [{ required: true, message: '请输入手机号' }] }]"
                  }
                ],
                attrs: { placeholder: "请输入手机号" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { colon: true, label: "身份证号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "idCard",
                      { rules: [{ required: true, message: "请输入身份证号" }] }
                    ],
                    expression:
                      "['idCard', { rules: [{ required: true, message: '请输入身份证号' }] }]"
                  }
                ],
                attrs: { placeholder: "请输入身份证号" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { style: { textAlign: "center" } },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", size: "large" },
              on: { click: _vm.check }
            },
            [_vm._v("立即查询")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }