import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import FormJson from "./BaseinfoEditableForm";
import { updateReq, detailReq, customerCredits, customerOperates, customerAssets, guaranteeGoods, customerSpouseInfos, customerSpouseCredits, updateOrderInfoReq, orderInfoReq } from "@/views/Customer/api";
import BaseinfoCustomer from "./baseinfoCustomer";
import SpouseCreditList from "./spouseCreditList";
import GuaranteeList from "./guaranteeList";
import Operate from "./operate";
import AssetList from "./assetList";
import SpouseInfo from "./spouseInfo";
import SpouseCredit from "./spouseCredit";
export default {
  name: "",
  components: {
    BaseinfoCustomer: BaseinfoCustomer,
    SpouseCreditList: SpouseCreditList,
    AssetList: AssetList,
    Operate: Operate,
    GuaranteeList: GuaranteeList,
    SpouseInfo: SpouseInfo,
    SpouseCredit: SpouseCredit
  },
  props: {
    status: {
      type: String,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      FormJson: FormJson,
      form: this.$form.createForm(this),
      activeKey: 0,
      show: false,
      editable: false,
      tempRecord: null,
      dynamicData: {},
      expandIconPosition: "left"
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.buildDynamicData();
    this.updateCustomerInfo();
  },
  methods: {
    updateCustomerInfo: function updateCustomerInfo() {
      var _this = this;

      detailReq(this.$route.params.id).then(function (res) {
        if (res.data) {
          if (res.data.guaranteeList && res.data.guaranteeList.length > 0) {
            res.data.guaranteeList.map(function (item) {
              item.agencySatisfied = item.agencySatisfied === true ? 1 : 0;
            });
          }

          _this.tempRecord = res.data;

          if (res.data.customerVO && res.data.customerVO.marriedStatus === 2) {
            _this.show = true;
          } else {
            _this.show = false;
          }

          _this.orderList();
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 获取已婚信息
    married: function married(data) {
      if (data === 2) {
        this.show = true;
        this.editable = true;
      } else if (data === 1 || data === 3 || data === 4) {
        this.show = false;
        this.editable = true;
      }
    },
    // 获取订单信息
    orderList: function orderList() {
      var _this2 = this;

      if (this.$route.params.status !== "3") {
        orderInfoReq(this.$route.params.orderInfoId).then(function (res) {
          _this2.tempRecord.creditExtend = res.data.creditExtend;
          _this2.tempRecord.assetExtend = res.data.assetExtend;
          _this2.tempRecord.guaranteeExtend = res.data.guaranteeExtend;
          _this2.tempRecord.spouseCreditExtend = res.data.spouseCreditExtend;
        });
      }
    },
    // 基本资料
    fatherMethod: function fatherMethod() {
      var _this3 = this;

      this.$refs["kfb"].getData().then(function (values) {
        var id = _this3.$route.params.id;
        values.customerVO.currentAddressId = values.currentAddressId;
        values.customerVO.currentAddress = values.currentAddress;
        var info = values.customerVO;
        updateReq({
          id: id,
          info: info
        }).then(function (res) {
          _this3.$message.success("更新成功");

          _this3.$emit("baseInfoData", values);

          _this3.updateCustomerInfo();
        });
      });
    },
    // 信用状态
    spouseList: function spouseList() {
      var _this4 = this;

      this.$refs["cred"].getData().then(function (values) {
        var customerCreditAddDTOs = values.creditList;
        customerCredits(_this4.$route.params.id, customerCreditAddDTOs).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success("更新成功");

            _this4.updateCustomerInfo();

            if (_this4.$route.params.status !== "3") {
              var orderInfoUpdateDTO = {
                id: _this4.$route.params.orderInfoId,
                creditExtend: values.creditExtend
              };
              updateOrderInfoReq(orderInfoUpdateDTO).then(function (res) {
                if (res.code === 200) {
                  _this4.orderList();
                }
              });
            }
          }
        });
      });
    },
    // 资产信息
    assetList: function assetList() {
      var _this5 = this;

      this.$refs["asset"].getData().then(function (values) {
        var customerAssetAddDTO = values.assetList;
        customerAssets(_this5.$route.params.id, customerAssetAddDTO).then(function (res) {
          if (res.code === 200) {
            _this5.$message.success("更新成功");

            _this5.updateCustomerInfo();

            if (_this5.$route.params.status !== "3") {
              var orderInfoUpdateDTO = {
                id: _this5.$route.params.orderInfoId,
                assetExtend: values.assetExtend
              };
              updateOrderInfoReq(orderInfoUpdateDTO).then(function (res) {
                if (res.code === 200) {
                  _this5.orderList();
                }
              });
            }
          }
        });
      });
    },
    // 经营状况
    operateList: function operateList() {
      var _this6 = this;

      this.$refs["operate"].getData().then(function (values) {
        var customerOperateAddDTO = Object.assign({
          customerId: _this6.$route.params.id
        }, values["operateVO"]);
        customerOperates(customerOperateAddDTO).then(function (res) {
          if (res.code === 200) {
            _this6.$message.success("更新成功");

            _this6.updateCustomerInfo();
          }
        });
      });
    },
    // 借款人配偶基本信息
    spouseInfo: function spouseInfo() {
      var _this7 = this;

      this.$refs["spouse"].getData().then(function (values) {
        var customerSpouseInfoAddDTO = Object.assign({
          customerId: _this7.$route.params.id
        }, values["spouseInfoVO"]);
        customerSpouseInfos(customerSpouseInfoAddDTO).then(function (res) {
          if (res.code === 200) {
            _this7.$message.success("更新成功");

            _this7.updateCustomerInfo();
          }
        });
      });
    },
    // 借款人配偶信用状况
    spouseRowList: function spouseRowList() {
      var _this8 = this;

      this.$refs["rowList"].getData().then(function (values) {
        var customerSpouseCreditAddDTOs = values.spouseCreditList;
        customerSpouseCredits(_this8.$route.params.id, customerSpouseCreditAddDTOs).then(function (res) {
          if (res.code === 200) {
            _this8.$message.success("更新成功");

            _this8.updateCustomerInfo();

            if (_this8.$route.params.status !== "3") {
              var orderInfoUpdateDTO = {
                id: _this8.$route.params.orderInfoId,
                spouseCreditExtend: values.spouseCreditExtend
              };
              updateOrderInfoReq(orderInfoUpdateDTO).then(function (res) {
                if (res.code === 200) {
                  _this8.orderList();
                }
              });
            }
          }
        });
      });
    },
    // 担保物信息
    guaranteeLists: function guaranteeLists() {
      var _this9 = this;

      this.$refs["guarante"].getData().then(function (values) {
        var guaranteeGoodsAddDTOs = values.guaranteeList;
        guaranteeGoods(_this9.$route.params.id, guaranteeGoodsAddDTOs).then(function (res) {
          if (res.code === 200) {
            _this9.$message.success("更新成功");

            _this9.updateCustomerInfo();

            if (_this9.$route.params.status !== "3") {
              var orderInfoUpdateDTO = {
                id: _this9.$route.params.orderInfoId,
                guaranteeExtend: values.guaranteeExtend
              };
              updateOrderInfoReq(orderInfoUpdateDTO).then(function (res) {
                if (res.code === 200) {
                  _this9.orderList();
                }
              });
            }
          }
        });
      });
    },
    buildDynamicData: function buildDynamicData() {
      var tempDynamicData = {};
      tempDynamicData["health"] = [{
        value: "1",
        label: "健康"
      }];
      tempDynamicData["sexList"] = [{
        value: 1,
        label: "男"
      }, {
        value: 2,
        label: "女"
      }];
      tempDynamicData["idCardTypeList"] = [{
        value: "MAINLAND",
        label: "身份证"
      }];
      tempDynamicData["marriedStatusList"] = [{
        value: 1,
        label: "未婚"
      }, {
        value: 2,
        label: "已婚"
      }, {
        value: 3,
        label: "离异"
      }, {
        value: 4,
        label: "丧偶"
      }];
      tempDynamicData["companyName"] = [{
        value: "1",
        label: "自雇人士"
      }, {
        value: "2",
        label: "受薪人士"
      }];
      tempDynamicData["educationLevelList"] = [{
        value: 1,
        label: "初中及以下"
      }, {
        value: 2,
        label: "高中及中等专业学校"
      }, {
        value: 3,
        label: "大专"
      }, {
        value: 4,
        label: "本科"
      }, {
        value: 5,
        label: "硕士"
      }, {
        value: 6,
        label: "博士及以上"
      }];
      tempDynamicData["positionList"] = [{
        value: 1,
        label: "法人"
      }, {
        value: 2,
        label: "股东"
      }, {
        value: 3,
        label: "高级领导"
      }, {
        value: 4,
        label: "中级领导"
      }, {
        value: 5,
        label: "一般员工"
      }, {
        value: 6,
        label: "其他"
      }];
      tempDynamicData["agencySatisfiedList"] = [{
        value: 1,
        label: "是"
      }, {
        value: 0,
        label: "否"
      }];
      tempDynamicData["typeList"] = [{
        value: 1,
        label: "党政及国家机关"
      }, {
        value: 2,
        label: "事业单位"
      }, {
        value: 3,
        label: "民办非企业单位（有限公司）"
      }, {
        value: 4,
        label: "社会团体"
      }, {
        value: 5,
        label: "个体工商户"
      }];
      this.dynamicData = tempDynamicData;
    }
  }
};