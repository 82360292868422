export default {
  name: {
    model: "name",
    type: "input",
    wrapper: {
      label: "姓名"
    },
    customize: {
      allowClear: true,
      placeholder: ""
    },
    decorator: {
      rules: [{
        required: true,
        message: "必填项"
      }]
    }
  },
  phone: {
    model: "phone",
    type: "input",
    wrapper: {
      label: "手机号"
    },
    customize: {
      allowClear: true,
      placeholder: ""
    },
    decorator: {
      rules: [{
        required: true,
        message: "必填项"
      }]
    }
  },
  idCard: {
    model: "idCard",
    type: "input",
    wrapper: {
      label: "身份证号"
    },
    customize: {
      allowClear: true,
      placeholder: ""
    },
    decorator: {
      rules: [{
        required: true,
        message: "必填项"
      }]
    }
  }
};