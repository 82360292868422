import "core-js/modules/es.array.concat";
var companyId = localStorage.getItem("lesseeId");
var loan = process.env.VUE_APP_SUBJECT_TYPE;
export default {
  fields: {
    logo: {
      model: "logo",
      type: "upload",
      wrapper: {
        label: "产品 Logo"
      },
      customize: {
        action: "/file/api/v1/general/uploadImg",
        accept: ".jpg, .jpeg, .png, .bmp, .gif",
        listType: "picture-card",
        multiple: false
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        size: 10,
        limit: 1
      }
    },
    name: {
      model: "name",
      type: "input",
      wrapper: {
        label: "产品名称"
      },
      customize: {
        placeholder: "请输入产品名称",
        allowClear: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      }
    },
    address: {
      model: "address",
      type: "area",
      wrapper: {
        label: "产品地区"
      },
      customize: {
        allowClear: true,
        multiple: true,
        placeholder: "请选择产品地区"
      },
      decorator: {
        initialValue: undefined
      },
      extend: {
        level: 3
      }
    },
    deadlineMin: {
      model: "deadlineMin",
      type: "number",
      wrapper: {
        label: "最低期限(月)"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入最低期限"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }, {
          type: "number",
          min: 0,
          message: "不允许为负数"
        }]
      }
    },
    deadlineMax: {
      model: "deadlineMax",
      type: "number",
      wrapper: {
        label: "最高期限(月)"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入最高期限"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }, {
          type: "number",
          min: 0,
          message: "不允许为负数"
        }]
      }
    },
    interestRateMin: {
      model: "interestRateMin",
      type: "number",
      wrapper: {
        label: "最低年利率(%)"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入最低年利率"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }, {
          type: "number",
          min: 0,
          message: "不允许为负数"
        }]
      }
    },
    interestRateMax: {
      model: "interestRateMax",
      type: "number",
      wrapper: {
        label: "最高年利率(%)"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入最高年利率"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }, {
          type: "number",
          min: 0,
          message: "不允许为负数"
        }]
      }
    },
    type: {
      model: "type",
      type: "select",
      wrapper: {
        label: "存款方式"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择存款方式",
        options: [{
          value: 3,
          label: "定期存款"
        }, {
          value: 4,
          label: "活期存款"
        }]
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      }
    },
    calculation: {
      model: "calculation",
      type: "select",
      wrapper: {
        label: "计算方式"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择计算方式",
        options: [{
          value: 1,
          label: "按当月实际天数计算"
        }, {
          value: 2,
          label: "按每月30天计算"
        }]
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      }
    },
    amountMin: {
      model: "amountMin",
      type: "number",
      wrapper: {
        label: "最低额度(元)"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入最低额度"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }, {
          type: "number",
          min: 0,
          message: "不允许为负数"
        }]
      }
    },
    amountMax: {
      model: "amountMax",
      type: "number",
      wrapper: {
        label: "最高额度(元)"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入最高额度"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }, {
          type: "number",
          min: 0,
          message: "不允许为负数"
        }]
      }
    },
    lateFeeRule: {
      model: "lateFeeRule",
      type: "select",
      wrapper: {
        label: "滞纳金"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择滞纳金"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: false,
          message: "必填项"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "lateFeeRuleList",
        dynamicUrl: "/finance/api/v1/formulas/listAll",
        dynamicTail: "data",
        dynamicValueKey: "id",
        dynamicLabelKey: "formula"
      }
    },
    applicableGroup: {
      model: "applicableGroup",
      type: "input",
      wrapper: {
        label: "适用群体"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入适用群体"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    background: {
      model: "background",
      type: "select",
      wrapper: {
        label: "产品模板"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择产品模板"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: false,
          message: "必填项"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "backgroundList",
        dynamicUrl: "/finance/api/v1/templates/listAll",
        dynamicTail: "data",
        dynamicValueKey: "id",
        dynamicLabelKey: "name"
      }
    },
    ruleGroup: {
      model: "ruleGroup",
      type: "select",
      wrapper: {
        label: "规则组"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择规则组"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "ruleGroupList",
        dynamicUrl: "/finance/api/v1/ruleGroups/listAll",
        dynamicTail: "data",
        dynamicValueKey: "id",
        dynamicLabelKey: "name"
      }
    },
    approveId: {
      model: "approveId",
      type: "select",
      wrapper: {
        label: "审批分组"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择审批组"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "approveIdList",
        dynamicUrl: "/approval/api/v1/approFlows/allByCompany/".concat(companyId, "/").concat(loan),
        dynamicTail: "data",
        dynamicValueKey: "id",
        dynamicLabelKey: "typeName"
      }
    },
    feature: {
      model: "feature",
      type: "input",
      wrapper: {
        label: "产品特色"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入产品特色"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      }
    },
    desc: {
      model: "desc",
      type: "textarea",
      wrapper: {
        label: "产品介绍"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入产品介绍"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      }
    },
    photo: {
      model: "photo",
      type: "upload",
      wrapper: {
        label: "产品图片"
      },
      customize: {
        action: "/file/api/v1/general/uploadImg",
        accept: ".jpg, .jpeg, .png, .bmp, .gif",
        listType: "picture-card",
        multiple: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: false,
          message: "必填项"
        }]
      },
      extend: {
        size: 10,
        limit: 99
      }
    }
  },
  validators: {
    deadlineMax: "validateDeadlineMax",
    amountMax: "validateAmountMax",
    interestRateMax: "validateInterestRateMax"
  },
  config: {
    createLoanProd: {
      fieldKeys: ["logo", "name", "address", "deadlineMin", "deadlineMax", "interestRateMin", "interestRateMax", "calculation", "amountMin", "amountMax", "lateFeeRule", "applicableGroup", "background", "feature", "desc", "ruleGroup", "approveId", "photo"],
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    updateLoanProd: {
      fieldKeys: ["logo", "name", "address", "deadlineMin", "deadlineMax", "interestRateMin", "interestRateMax", "calculation", "amountMin", "amountMax", "lateFeeRule", "applicableGroup", "background", "feature", "desc", "ruleGroup", "approveId", "photo"],
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    createDepositProd: {
      fieldKeys: ["logo", "name", "address", "type", "deadlineMin", "deadlineMax", "interestRateMin", "interestRateMax", "amountMin", "amountMax", "applicableGroup", "background", "feature", "desc", "ruleGroup", "approveId", "photo"],
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    updateDepositProd: {
      fieldKeys: ["logo", "name", "address", "type", "deadlineMin", "deadlineMax", "interestRateMin", "interestRateMax", "amountMin", "amountMax", "applicableGroup", "background", "feature", "desc", "ruleGroup", "approveId", "photo"],
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    search: {
      fieldKeys: ["name"],
      customize: {
        layout: "inline",
        labelCol: {
          span: 8
        },
        wrapperCol: {
          span: 16
        }
      }
    },
    tableLoanProd: {
      fields: [{
        model: "name",
        title: "产品名称"
      }, {
        model: "deadlineMin",
        title: "最低期限(月)"
      }, {
        model: "deadlineMax",
        title: "最高期限(月)"
      }, {
        model: "interestRateMin",
        title: "最低年利率(%)"
      }, {
        model: "interestRateMax",
        title: "最高年利率(%)"
      }, {
        model: "amountMin",
        title: "最低额度(元)"
      }, {
        model: "amountMax",
        title: "最高额度(元)"
      }],
      customize: {
        rowKey: "id",
        pagination: {
          pageSize: 20
        }
      },
      extend: {
        batch: true,
        action: {
          width: 110
        }
      }
    },
    tableDepositProd: {
      fields: [{
        model: "name",
        title: "产品名称"
      }, {
        model: "deadlineMin",
        title: "最低期限(月)"
      }, {
        model: "deadlineMax",
        title: "最高期限(月)"
      }, {
        model: "interestRateMin",
        title: "最低年利率(%)"
      }, {
        model: "interestRateMax",
        title: "最高年利率(%)"
      }, {
        model: "amountMin",
        title: "最低额度(元)"
      }, {
        model: "amountMax",
        title: "最高额度(元)"
      }],
      customize: {
        rowKey: "id",
        pagination: {
          pageSize: 20
        }
      },
      extend: {
        batch: true,
        action: {
          width: 110
        }
      }
    }
  }
};