var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticClass: "box", attrs: { gutter: 16 } },
    [
      _c(
        "a-row",
        { staticClass: "box_top" },
        [
          _c("div", { staticClass: "box_title" }, [
            _c("img", {
              staticClass: "img",
              attrs: { src: require("../../assets/images/logos.png") }
            }),
            _c("span", { staticClass: "title" }, [_vm._v("个人统计")])
          ]),
          _c(
            "a-row",
            [
              _c("a-col", { staticClass: "top_title", attrs: { span: 3 } }, [
                _c("div", { staticClass: "left_top" }, [_vm._v("销售额")]),
                _c("div", { staticClass: "left_bottom" }, [
                  _vm._v(_vm._s(_vm.individualOrder.money))
                ])
              ]),
              _c(
                "a-col",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "padding-left": "20px",
                    "padding-right": "20px"
                  },
                  attrs: { span: 21 }
                },
                [
                  [
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("未建审批")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(
                          " " + _vm._s(_vm.individualOrder.orderStatus1) + " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("已建审批未领办")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(
                          " " + _vm._s(_vm.individualOrder.orderStatus2) + " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("已领办未处理")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(
                          " " + _vm._s(_vm.individualOrder.orderStatus3) + " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("材料审核同意")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(
                          " " + _vm._s(_vm.individualOrder.orderStatus4) + " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("材料审核拒绝")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(
                          " " + _vm._s(_vm.individualOrder.orderStatus5) + " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("实地调查同意")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(
                          " " + _vm._s(_vm.individualOrder.orderStatus6) + " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("实地调查拒绝")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(
                          " " + _vm._s(_vm.individualOrder.orderStatus7) + " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("放款审批同意")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(
                          " " + _vm._s(_vm.individualOrder.orderStatus8) + " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("放款审批拒绝")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(
                          " " + _vm._s(_vm.individualOrder.orderStatus9) + " "
                        )
                      ])
                    ])
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c("a-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              { staticClass: "box_center" },
              [
                _c("div", { staticClass: "box_title" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: require("../../assets/images/logos.png") }
                  }),
                  _c("div", { staticClass: "title" }, [_vm._v("总量统计")])
                ]),
                _c(
                  "a-row",
                  { staticClass: "centers" },
                  [
                    [
                      _c(
                        "a-col",
                        { staticClass: "cnetrt_center", attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "center_top" }, [
                            _vm._v("未建审批")
                          ]),
                          _c("div", { staticClass: "center_bottom" }, [
                            _vm._v(_vm._s(_vm.groupOrder.orderStatus1))
                          ])
                        ]
                      ),
                      _c(
                        "a-col",
                        { staticClass: "cnetrt_center", attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "center_top" }, [
                            _vm._v("已建审批未领办")
                          ]),
                          _c("div", { staticClass: "center_bottom" }, [
                            _vm._v(_vm._s(_vm.groupOrder.orderStatus2))
                          ])
                        ]
                      ),
                      _c(
                        "a-col",
                        { staticClass: "cnetrt_center", attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "center_top" }, [
                            _vm._v("已领办未处理")
                          ]),
                          _c("div", { staticClass: "center_bottom" }, [
                            _vm._v(_vm._s(_vm.groupOrder.orderStatus3))
                          ])
                        ]
                      ),
                      _c(
                        "a-col",
                        { staticClass: "cnetrt_center", attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "center_top" }, [
                            _vm._v("材料审核同意")
                          ]),
                          _c("div", { staticClass: "center_bottom" }, [
                            _vm._v(_vm._s(_vm.groupOrder.orderStatus4))
                          ])
                        ]
                      ),
                      _c(
                        "a-col",
                        { staticClass: "cnetrt_center", attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "center_top" }, [
                            _vm._v("材料审核拒绝")
                          ]),
                          _c("div", { staticClass: "center_bottom" }, [
                            _vm._v(_vm._s(_vm.groupOrder.orderStatus5))
                          ])
                        ]
                      ),
                      _c(
                        "a-col",
                        { staticClass: "cnetrt_center", attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "center_top" }, [
                            _vm._v("实地调查同意")
                          ]),
                          _c("div", { staticClass: "center_bottom" }, [
                            _vm._v(_vm._s(_vm.groupOrder.orderStatus6))
                          ])
                        ]
                      ),
                      _c(
                        "a-col",
                        { staticClass: "cnetrt_center", attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "center_top" }, [
                            _vm._v("实地调查拒绝")
                          ]),
                          _c("div", { staticClass: "center_bottom" }, [
                            _vm._v(_vm._s(_vm.groupOrder.orderStatus7))
                          ])
                        ]
                      ),
                      _c(
                        "a-col",
                        { staticClass: "cnetrt_center", attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "center_top" }, [
                            _vm._v("放款审批同意")
                          ]),
                          _c("div", { staticClass: "center_bottom" }, [
                            _vm._v(_vm._s(_vm.groupOrder.orderStatus8))
                          ])
                        ]
                      ),
                      _c(
                        "a-col",
                        { staticClass: "cnetrt_center", attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "center_top" }, [
                            _vm._v("放款审批拒绝")
                          ]),
                          _c("div", { staticClass: "center_bottom" }, [
                            _vm._v(_vm._s(_vm.groupOrder.orderStatus9))
                          ])
                        ]
                      )
                    ]
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { span: 16 } }, [
            _c("div", { staticClass: "box_center_right" }, [
              _c("div", { staticClass: "box_title" }, [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: require("../../assets/images/logos.png") }
                }),
                _c("div", { staticClass: "title" }, [_vm._v(" 每日趋势 ")]),
                _c(
                  "div",
                  { staticClass: "btns" },
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "link" }, on: { click: _vm.day1 } },
                      [_vm._v("15日")]
                    ),
                    _c(
                      "a-button",
                      { attrs: { type: "link" }, on: { click: _vm.day2 } },
                      [_vm._v("30日")]
                    ),
                    _c(
                      "a-button",
                      { attrs: { type: "link" }, on: { click: _vm.day3 } },
                      [_vm._v("全部")]
                    )
                  ],
                  1
                )
              ]),
              _c("div", {
                staticStyle: { flex: "1", height: "390px" },
                attrs: { id: "myChart" }
              })
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box_fotter" },
        [
          _c("div", { staticClass: "box_title" }, [
            _c("img", {
              staticClass: "img",
              attrs: { src: require("../../assets/images/logos.png") }
            }),
            _c("div", { staticClass: "title" }, [_vm._v(" 个人单量统计 ")]),
            _c(
              "div",
              { staticClass: "btns" },
              [
                _c(
                  "a-button",
                  {
                    attrs: { type: "link" },
                    on: {
                      click: function($event) {
                        _vm.visible3 = true
                      }
                    }
                  },
                  [_vm._v(" 个人放款额统计 ")]
                ),
                _c(
                  "a-button",
                  {
                    attrs: { type: "link" },
                    on: {
                      click: function($event) {
                        _vm.visible2 = true
                      }
                    }
                  },
                  [_vm._v(" 个人单量统计 ")]
                )
              ],
              1
            )
          ]),
          _c(
            "a-row",
            [
              _c("a-col", {
                staticClass: "box_fotter_chart",
                attrs: { id: "myChart3", span: 12 }
              }),
              _c("a-col", {
                staticClass: "box_fotter_chart",
                attrs: { id: "myChart4", span: 12 }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "渠道总单量列表", width: "50%", footer: "" },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.chenkoutNum1,
              pagination: false
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.columns, function(col, index) {
                  return {
                    key: col.dataIndex,
                    fn: function(text, record) {
                      return [
                        col.dataIndex === "num"
                          ? _c("span", { key: index }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    record.value == 0
                                      ? 0
                                      : (
                                          (record.value / _vm.countNum1) *
                                          100
                                        ).toFixed(2)
                                  ) +
                                  " % "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "渠道放款额列表", width: "50%", footer: "" },
          model: {
            value: _vm.visible1,
            callback: function($$v) {
              _vm.visible1 = $$v
            },
            expression: "visible1"
          }
        },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.chenkoutNum2,
              pagination: false
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.columns, function(col, index) {
                  return {
                    key: col.dataIndex,
                    fn: function(text, record) {
                      return [
                        col.dataIndex === "num"
                          ? _c("span", { key: index }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    record.value == 0
                                      ? 0
                                      : (
                                          (record.value / _vm.countNum2) *
                                          100
                                        ).toFixed(2)
                                  ) +
                                  " % "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "个人单量统计", width: "50%", footer: "" },
          model: {
            value: _vm.visible2,
            callback: function($$v) {
              _vm.visible2 = $$v
            },
            expression: "visible2"
          }
        },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.chenkoutNum3,
              pagination: false
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.columns, function(col, index) {
                  return {
                    key: col.dataIndex,
                    fn: function(text, record) {
                      return [
                        col.dataIndex === "num"
                          ? _c("span", { key: index }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    record.value == 0
                                      ? 0
                                      : (
                                          (record.value / _vm.countNum3) *
                                          100
                                        ).toFixed(2)
                                  ) +
                                  " % "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "个人放款额统计", width: "50%", footer: "" },
          model: {
            value: _vm.visible3,
            callback: function($$v) {
              _vm.visible3 = $$v
            },
            expression: "visible3"
          }
        },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.chenkoutNum4,
              pagination: false
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.columns, function(col, index) {
                  return {
                    key: col.dataIndex,
                    fn: function(text, record) {
                      return [
                        col.dataIndex === "num"
                          ? _c("span", { key: index }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    record.value == 0
                                      ? 0
                                      : (
                                          (record.value / _vm.countNum4) *
                                          100
                                        ).toFixed(2)
                                  ) +
                                  "% "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }