import "core-js/modules/es.array.join";
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getBrPreLoanReport } from "@/api/client";
export default {
  props: {
    needTitle: {
      type: Boolean,
      default: true
    },
    customerInfo: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      checkForm: this.$form.createForm(this)
    };
  },
  watch: {
    customerInfo: {
      handler: function handler(newVal, oldVal) {
        var _this = this;

        if (newVal && newVal["orderInfoId"]) {
          this.$nextTick(function () {
            _this.checkForm.setFieldsValue({
              name: _this.customerInfo.name,
              mobile: _this.customerInfo.phone,
              idCard: _this.customerInfo.idCard
            });
          });
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {},
  methods: {
    check: function check(e) {
      var _this2 = this;

      e.preventDefault(e);
      this.checkForm.validateFields(function (err, values) {
        if (!err) {
          getBrPreLoanReport(values).then(function (res) {
            _this2.checkForm.resetFields();

            _this2.$router.push({
              path: "/report",
              query: {
                info: res.data.join(",")
              }
            });
          }).catch(function (error) {
            console.log("debug log -error-> ", error);
          });
        }
      });
    }
  }
};