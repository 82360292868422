import "core-js/modules/es.array.concat";
import request from "@/utils/request"; // 查询用户可查看的分组

export function allBySite(module_type) {
  return request({
    url: "/approval/api/v1/approFlowGroups/allGroupAndFlowByUser/".concat(module_type),
    method: "GET"
  });
} // 人员审批列表

export function listUserApprosApprovedByMe(params) {
  return request({
    url: "/approval/api/v1/userFlowApprovers/listUserApprosApprovedByMe",
    method: "GET",
    params: params
  });
} // 拿到表单

export function allCanUseByFlow(id) {
  return request({
    url: "/approval/api/v1/approFlowForms/allCanUseByFlow/".concat(id),
    method: "GET"
  });
} // 新增审批表单 根据用户填写的信息获取匹配的流程(展示和用户自选审批人、抄送人使用)

export function getFlowProcessByApproInfo(data) {
  return request({
    url: "/approval/api/v1/userAppros/getFlowProcessByApproInfo",
    method: "POST",
    data: data
  });
} // 获取抄送人

export function listAll() {
  return request({
    url: "/user/api/v1/users/listAll",
    method: "GET"
  });
} // 拿到所有角色

export function roles() {
  return request({
    url: "/permission/api/v1/roles",
    method: "GET"
  });
} // 用户选择抄送，审批人

export function userAppros(data) {
  return request({
    url: "/approval/api/v1/userAppros",
    method: "POST",
    data: data
  });
} // 审批详情

export function userApproDetails(userApproId) {
  return request({
    url: "/approval/api/v1/userAppros/userApproDetails/".concat(userApproId),
    method: "GET"
  });
} // 审批通过

export function pass(id, data) {
  return request({
    url: "/approval/api/v1/userFlowApprovers/pass/".concat(id),
    method: "PUT",
    data: data
  });
} // 审批拒绝

export function reject(id, data) {
  return request({
    url: "/approval/api/v1/userFlowApprovers/reject/".concat(id),
    method: "PUT",
    data: data
  });
} // 领办

export function receiver(data) {
  return request({
    url: "/finance/api/v1/receiver",
    method: "PUT",
    data: data
  });
} // 待办池列表

export function listUserApprosApprovedByReceiver(params) {
  return request({
    url: "/finance/api/v1/receiver/listUserApprosApprovedByReceiver",
    method: "GET",
    params: params
  });
} // 待处理列表

export function listUserApprosApprovedByReceiverMe(params) {
  return request({
    url: "/finance/api/v1/receiver/pageUserApprosApproverByReceiverMe",
    method: "GET",
    params: params
  });
} // 新增订单信息

export function customerInfos(data) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "POST",
    data: data
  });
} // 根据ID获取审批分组

export function allGroupAndFlowByCompanyUser(companyId, module_type) {
  return request({
    url: "/approval/api/v1/approFlowGroups/allGroupAndFlowByCompanyUser/".concat(companyId, "/").concat(module_type),
    method: "GET"
  });
} // 查询订单列表

export function orderInfos() {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "GET"
  });
} // 查询订单列表

export function updateOrderInfos(data) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "PUT",
    data: data
  });
} // 搜索

export function search(params) {
  return request({
    url: "/finance/api/v1/receiver/search",
    method: "GET",
    params: params
  });
} // 获取订单列表

export function retrieveReq(params) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "GET",
    params: params
  });
} // 获取订单列表

export function batch(data) {
  return request({
    url: "/finance/api/v1/receiver/batch",
    method: "PUT",
    data: data
  });
}