var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.status != 2
        ? _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-bottom": "20px" } },
            [
              _vm.editable
                ? [
                    _c(
                      "a-space",
                      [
                        _c("a-button", { on: { click: _vm.quxiao } }, [
                          _vm._v("取消")
                        ]),
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.getData }
                          },
                          [_vm._v("保存")]
                        )
                      ],
                      1
                    )
                  ]
                : _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.editable = true
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  )
            ],
            2
          )
        : _vm._e(),
      _c("ReportEditable", {
        ref: "kfb",
        attrs: {
          dataSource: _vm.tempRecord,
          editable: _vm.editable,
          customId: _vm.customId,
          orderInfoIds: _vm.orderInfoIds,
          disabled: _vm.disabled,
          status: _vm.status,
          formFields: _vm.FormJson["fields"],
          formConfig: _vm.FormJson["config"]
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }