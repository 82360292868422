import "core-js/modules/es.array.concat";
import request from "@/utils/request"; // 获取订单信息

export function retrieveReq(params) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "GET",
    params: params
  });
} // 获取审批池

export function approve(params) {
  return request({
    url: "/finance/api/v1/orderInfos/approve",
    method: "GET",
    params: params
  });
} // 新增订单信息

export function customerInfos(data) {
  return request({
    url: "/finance/api/v1/orderInfos/saveOrder",
    method: "POST",
    data: data
  });
} // 根据ID获取审批分组

export function allGroupAndFlowByCompanyUser(companyId, module_type) {
  return request({
    url: "/approval/api/v1/approFlowGroups/allGroupAndFlowByCompanyUser/".concat(companyId, "/").concat(module_type),
    method: "GET"
  });
} // 拿到表单

export function allCanUseByFlow(id) {
  return request({
    url: "/approval/api/v1/approFlowForms/allCanUseByFlow/".concat(id),
    method: "GET"
  });
} // 新增审批表单 根据用户填写的信息获取匹配的流程(展示和用户自选审批人、抄送人使用)

export function getFlowProcessByApproInfo(data) {
  return request({
    url: "/approval/api/v1/userAppros/getFlowProcessByApproInfo",
    method: "POST",
    data: data
  });
} // 新增审批

export function userAppros(data) {
  return request({
    url: "/approval/api/v1/userAppros",
    method: "POST",
    data: data
  });
} // 修改订单

export function orderInfos(data) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "PUT",
    data: data
  });
} // 新增金融系统审批领办表

export function userApprosList(data) {
  return request({
    url: "/finance/api/v1/userAppros",
    method: "POST",
    data: data
  });
} // 查看所有产品信息

export function listAll(params) {
  return request({
    url: "/finance/api/v1/products/listAll",
    method: "GET",
    params: params
  });
} // 查看字段

export function fieldSetsListAll(params) {
  return request({
    url: "/finance/api/v1/fieldSets/listAll",
    method: "GET",
    params: params
  });
} // 查看默认字段规则

export function defaultListAll(params) {
  return request({
    url: "/finance/api/v1/fieldRules/default/listAll",
    method: "GET",
    params: params
  });
} // 分页查询字段设置

export function fieldRules(params) {
  return request({
    url: "/finance/api/v1/fieldRules/listAll",
    method: "GET",
    params: params
  });
}