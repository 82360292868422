import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
import { customerInfosUser as retrieveReq } from "@/views/kehuguanli/api";
import RecordForm from "@/views/kehuguanli/Customer/Form.json";
import { CurdMixin } from "xqjr-plugin-common";
import IDCardCell from "xqjr-plugin-common/AutoGenerate/TableCell/IDCardCell"; // ----------------------------------------------------- 构建表格配置，包括基本项、操作

var columns = RecordForm.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
});
columns.push({
  width: 120,
  title: "操作",
  dataIndex: "crudAction",
  scopedSlots: {
    customRender: "crudAction"
  },
  type: "action",
  fixed: "right"
}); // ----------------------------------------------------- 生成 mixin

var mixin = new CurdMixin({
  retrieveReq: retrieveReq,
  RecordForm: RecordForm,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

export default {
  components: {
    IDCardCell: IDCardCell
  },
  mixins: [mixin],
  data: function data() {
    return {
      pageSize: 20
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // 查看审批节点
    statusDetail: function statusDetail(record, status) {
      this.$router.push("/Customer/".concat(record.id, "/").concat(record.id, "/").concat(record.id, "/").concat(record.id, "/").concat(status));
    }
  }
};