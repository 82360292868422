import request from "@/utils/request"; // 获取客户列表

export function customerInfos(params) {
  return request({
    url: "/finance/api/v1/customerInfos",
    method: "GET",
    params: params
  });
} // 新增客户

export function createReq(data) {
  return request({
    url: "/finance/api/v1/customerInfos",
    method: "POST",
    data: data
  });
} // 删除客户

export function deleteReq(customerInfoId) {
  return request({
    url: "/finance/api/v1/customerInfos/".concat(customerInfoId),
    method: "DELETE"
  });
} // 获取个人客户列表

export function customerInfosUser(params) {
  return request({
    url: "/finance/api/v1/customerInfos/user",
    method: "GET",
    params: params
  });
}