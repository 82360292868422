var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new_approval" },
    [
      _c(
        "a-layout",
        [
          _c(
            "a-layout-content",
            { style: { overflow: "initial" } },
            [
              _c(
                "a-modal",
                {
                  attrs: {
                    maskClosable: false,
                    width: 750,
                    title: "新建审批",
                    visible: _vm.show
                  },
                  on: {
                    cancel: function($event) {
                      return _vm.$emit("update:show", false)
                    },
                    ok: function($event) {
                      return _vm.$emit("update:show", false)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "cont" },
                    _vm._l(_vm.peoApplit, function(group, group_idx) {
                      return _c(
                        "div",
                        { key: group_idx },
                        [
                          _c("div", { staticClass: "title mg_b20" }, [
                            _vm._v(_vm._s(group.groupName))
                          ]),
                          _c(
                            "a-row",
                            { staticClass: "text_c", attrs: { type: "flex" } },
                            _vm._l(group.approFlowList, function(
                              item,
                              item_idx
                            ) {
                              return _c(
                                "a-col",
                                {
                                  key: item_idx,
                                  staticClass: "item",
                                  on: {
                                    click: function($event) {
                                      return _vm.createItem(item)
                                    }
                                  }
                                },
                                [
                                  _c("a-row", { staticClass: "mg_b10" }, [
                                    _c("img", {
                                      staticStyle: { width: "48px" },
                                      attrs: { src: item.icon, alt: "加载失败" }
                                    })
                                  ]),
                                  _c("a-row", { staticClass: "typeName" }, [
                                    _vm._v(_vm._s(item.typeName))
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "a-drawer",
                    {
                      attrs: {
                        destroyOnClose: true,
                        width: 540,
                        title: "发起审批",
                        visible: _vm.inner
                      },
                      on: {
                        close: function($event) {
                          _vm.inner = false
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "out-box" },
                        [
                          _c("div", { staticClass: "start-title" }, [
                            _vm._v(_vm._s(_vm.title))
                          ]),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "姓名",
                                labelCol: { span: 4 },
                                wrapperCol: { span: 18 }
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "姓名",
                                  autosize: { minRows: 2, maxRows: 6 }
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "手机号",
                                labelCol: { span: 4 },
                                wrapperCol: { span: 18 }
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "手机号",
                                  autosize: { minRows: 2, maxRows: 6 }
                                },
                                model: {
                                  value: _vm.phone,
                                  callback: function($$v) {
                                    _vm.phone = $$v
                                  },
                                  expression: "phone"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "备注",
                                labelCol: { span: 4 },
                                wrapperCol: { span: 18 }
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "备注",
                                  autosize: { minRows: 2, maxRows: 6 }
                                },
                                model: {
                                  value: _vm.remark,
                                  callback: function($$v) {
                                    _vm.remark = $$v
                                  },
                                  expression: "remark"
                                }
                              })
                            ],
                            1
                          ),
                          _c("k-form-build", {
                            ref: "kfd",
                            attrs: { value: _vm.jsonData },
                            on: { submit: _vm.submitData }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }