import request from "@/utils/request"; // 分页查询项目

export function retrieveReq(params) {
  return request({
    url: "/finance/api/v1/productTypemenager",
    method: "GET",
    params: params
  });
} // 新增项目

export function createReq(data) {
  return request({
    url: "/finance/api/v1/productTypemenager",
    method: "POST",
    data: data
  });
} // 修改项目

export function updateReq(data) {
  return request("/finance/api/v1/productTypemenager", {
    method: "PUT",
    data: data
  });
} // 删除项目

export function deleteReq(projectId) {
  return request({
    url: "/finance/api/v1/productTypemenager/".concat(projectId),
    method: "DELETE"
  });
} // 删除项目

export function deleteBatchReq(data) {
  return request({
    url: "/finance/api/v1/productTypemenager",
    method: "DELETE",
    data: data
  });
} // 根据项目id查询详情

export function detailReq(projectId) {
  return request({
    url: "/finance/api/v1/productTypemenager/".concat(projectId),
    method: "GET"
  });
}