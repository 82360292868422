var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.needTitle
      ? _c(
          "div",
          {
            style: {
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "500",
              borderBottom: "1px solid #efefef",
              paddingBottom: "20px"
            }
          },
          [_vm._v(" 房屋估值 ")]
        )
      : _vm._e(),
    _vm.formItem
      ? _c(
          "div",
          [
            _c(
              "a-form",
              {
                style: { width: "600px", margin: "50px auto 50px" },
                attrs: {
                  form: _vm.form,
                  labelCol: { span: 6 },
                  wrapperCol: { span: 18 }
                }
              },
              [
                _c(
                  "a-form-item",
                  { attrs: { label: "地区选择" } },
                  [
                    _c("a-cascader", {
                      attrs: {
                        options: _vm.areaOptions,
                        loadData: _vm.loadAreaData,
                        changeOnSelect: "",
                        placeholder: "请选择区域"
                      },
                      on: { change: _vm.onAreaChange }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  { attrs: { label: "选择楼盘" } },
                  [
                    _c(
                      "a-auto-complete",
                      {
                        attrs: {
                          disabled: _vm.projectSelectDisabled,
                          placeholder: "搜索并选择楼盘"
                        },
                        on: {
                          change: _vm.onProjectChange,
                          select: _vm.onProjectSelect
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "dataSource" },
                          _vm._l(_vm.projectOptions, function(projOpt) {
                            return _c(
                              "a-select-option",
                              {
                                key: projOpt.value,
                                attrs: { value: projOpt.value }
                              },
                              [_vm._v(" " + _vm._s(projOpt.label) + " ")]
                            )
                          }),
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  { attrs: { label: "房产选择" } },
                  [
                    _c("a-cascader", {
                      attrs: {
                        disabled: _vm.houseSelectDisabled,
                        options: _vm.houseOptions,
                        loadData: _vm.loadHouseData,
                        changeOnSelect: "",
                        placeholder: "请选择房产"
                      },
                      on: { change: _vm.onHouseChange }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  { attrs: { label: "面积" } },
                  [
                    _c("a-input", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["buildArea"],
                          expression: "['buildArea']"
                        }
                      ],
                      attrs: { disabled: _vm.houseSelectDisableds },
                      on: { change: _vm.changeBuildArea }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { style: { textAlign: "center" } },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          disabled: _vm.assetEnquityDisabled,
                          type: "primary",
                          size: "large"
                        },
                        on: { click: _vm.assetEnquity }
                      },
                      [_vm._v(" 估值 ")]
                    )
                  ],
                  1
                ),
                _vm.result
                  ? _c(
                      "div",
                      {
                        style: {
                          width: "600px",
                          margin: "50px auto 50px",
                          lineHeight: "2"
                        }
                      },
                      [
                        _c("div", [_vm._v("估值结果：")]),
                        _c("div", [
                          _vm._v(
                            "地区：" +
                              _vm._s(_vm.areaText ? _vm.areaText : "---")
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "楼盘：" +
                              _vm._s(_vm.project ? _vm.project["label"] : "---")
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "房产：" +
                              _vm._s(_vm.houseText ? _vm.houseText : "---")
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "面积：" +
                              _vm._s(_vm.buildArea ? _vm.buildArea : "---")
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "总价：" +
                              _vm._s(
                                _vm.result
                                  ? parseFloat(
                                      _vm.buildArea * _vm.result["unitPrice"]
                                    )
                                  : 0
                              ) +
                              "元"
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "单价：" +
                              _vm._s(
                                _vm.result ? _vm.result["unitPrice"] : "---"
                              ) +
                              "元/平米"
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.revaluation
      ? _c(
          "div",
          {
            style: { width: "600px", margin: "50px auto 50px", lineHeight: "2" }
          },
          [
            _c("div", [_vm._v("估值结果：")]),
            _c("div", [
              _vm._v(
                "地区：" +
                  _vm._s(
                    _vm.housingValuationVO
                      ? _vm.housingValuationVO.areaText
                      : "---"
                  )
              )
            ]),
            _c("div", [
              _vm._v(
                "楼盘：" +
                  _vm._s(
                    _vm.housingValuationVO
                      ? _vm.housingValuationVO.projectName
                      : "---"
                  )
              )
            ]),
            _c("div", [
              _vm._v(
                " 房产：" +
                  _vm._s(
                    _vm.housingValuationVO
                      ? _vm.housingValuationVO.buildingName +
                          _vm.housingValuationVO.floor +
                          "层" +
                          _vm.housingValuationVO.houseName
                      : "---"
                  ) +
                  " "
              )
            ]),
            _c("div", [
              _vm._v(
                "面积：" +
                  _vm._s(
                    _vm.housingValuationVO
                      ? _vm.housingValuationVO.buildArea
                      : "---"
                  )
              )
            ]),
            _c("div", [
              _vm._v(
                " 总价：" +
                  _vm._s(
                    _vm.housingValuationVO
                      ? parseFloat(
                          _vm.housingValuationVO.buildArea *
                            _vm.housingValuationVO.unitPrice
                        )
                      : 0
                  ) +
                  "元 "
              )
            ]),
            _c("div", [
              _vm._v(
                "单价：" +
                  _vm._s(
                    _vm.housingValuationVO
                      ? _vm.housingValuationVO.unitPrice
                      : "---"
                  ) +
                  "元/平米"
              )
            ]),
            _c(
              "div",
              { style: { textAlign: "center" } },
              [
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary", size: "large" },
                    on: { click: _vm.revaluAtion }
                  },
                  [_vm._v(" 重新估值 ")]
                )
              ],
              1
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }