import XqFormBuild from "xqjr-plugin-form/FormBuild";
export default {
  extends: XqFormBuild,
  methods: {
    validateDeadlineMax: function validateDeadlineMax(rule, value, callback) {
      var form = this.form;

      if ((!!value || value === 0) && value < form.getFieldValue("deadlineMin")) {
        callback("最高期限必须大于最低期限");
      } else {
        callback();
      }
    },
    validateAmountMax: function validateAmountMax(rule, value, callback) {
      var form = this.form;

      if ((!!value || value === 0) && value < form.getFieldValue("amountMin")) {
        callback("最高额度必须大于最低额度");
      } else {
        callback();
      }
    },
    validateInterestRateMax: function validateInterestRateMax(rule, value, callback) {
      var form = this.form;

      if ((!!value || value === 0) && value < form.getFieldValue("interestRateMin")) {
        callback("最高年利率必须大于最低年利率");
      } else {
        callback();
      }
    }
  }
};