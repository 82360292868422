import "core-js/modules/es.array.concat";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { reviewUrl } from "@/views/Customer/api";
export default {
  props: {
    customId: {
      type: String,
      default: ""
    },
    orderInfoIds: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      src: "",
      editModalVisible: false,
      dataSource: [{
        key: "0",
        name: "调查报告",
        templateName: "researchReport"
      }, {
        key: "1",
        name: "借款合同",
        templateName: "loanContract"
      }, {
        key: "2",
        name: "抵押合同",
        templateName: "mortgageContract"
      }, {
        key: "3",
        name: "保证合同",
        templateName: "contractGuaranty"
      }, {
        key: "4",
        name: "审查审批表(贷审会)",
        templateName: "loanReview"
      }, {
        key: "5",
        name: "审查审批表(不上会)",
        templateName: "examination"
      }, {
        key: "6",
        name: "个人贷款申请表",
        templateName: "loanApplication"
      }],
      columns: [{
        title: "合同名称",
        dataIndex: "name",
        width: "40%",
        align: "center"
      }, {
        title: "操作",
        dataIndex: "operation",
        width: "20%",
        align: "center",
        scopedSlots: {
          customRender: "operation"
        }
      }]
    };
  },
  methods: {
    downloadRecord: function downloadRecord(record) {
      var templateName = record.templateName;
      var customerId = this.customId;
      var url = "/finance/api/v1/customerInfos/downloadReport/".concat(this.orderInfoIds, "/").concat(customerId, "/").concat(templateName);
      var link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "合同.docx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadPreview: function downloadPreview(record) {
      var _this = this;

      this.editModalVisible = true;
      var customerId = this.customId;
      var templateName = record.templateName;
      reviewUrl(this.orderInfoIds, customerId, templateName).then(function (res) {
        if (res.code === 200) {
          _this.src = res.data;
        }
      });
    },
    editCancel: function editCancel() {
      this.editModalVisible = false;
    }
  }
};