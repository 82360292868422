var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
          }
        },
        [
          _c(
            "a-row",
            _vm._l(_vm.intentionKeys, function(item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("XqItem", {
                        attrs: {
                          form: _vm.form,
                          dataSource: _vm.dataSource,
                          item: _vm.formFields[item],
                          dynamicData: _vm.dynamicData,
                          editable: _vm.editable
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }