//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import XqFormMixin from "xqjr-plugin-form/mixins/XqFormMixin";
var FormMixin = XqFormMixin();
import { detailReq } from "@/views/Customer/api";
export default {
  mixins: [FormMixin],
  data: function data() {
    return {
      editables: false,
      spouseInfoVOKeys: ["name", "sex", "idCardType", "idCard", "educationLevel", "yearlyIncome", "currentBankAccount", "health", "companyName", "position", "phone", "extend"]
    };
  },
  mounted: function mounted() {},
  methods: {
    quxiao: function quxiao() {
      this.editables = false;
      this.$emit("cancel");
    },
    onValuesChangeCustom: function onValuesChangeCustom(values) {},
    save: function save() {
      this.editables = false;
      this.$emit("spouseInfo");
    }
  }
};