export default {
  fields: {
    areaText: {
      model: "areaText",
      type: "text",
      wrapper: {
        label: "地址"
      },
      customize: {},
      decorator: {
        initialValue: null,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        size: 10,
        limit: 10
      }
    },
    buildArea: {
      model: "buildArea",
      type: "text",
      wrapper: {
        label: "面积（平方）"
      },
      customize: {},
      decorator: {
        initialValue: null,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        size: 10,
        limit: 10
      }
    },
    unitPrice: {
      model: "unitPrice",
      type: "text",
      wrapper: {
        label: "单价"
      },
      customize: {},
      decorator: {
        initialValue: null,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        size: 10,
        limit: 10
      }
    },
    bank: {
      model: "bank",
      type: "text",
      wrapper: {
        label: "总价"
      },
      customize: {},
      decorator: {
        initialValue: null,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        size: 10,
        limit: 10
      }
    },
    gmtCreate: {
      model: "gmtCreate",
      type: "text",
      wrapper: {
        label: "更新时间"
      },
      customize: {},
      decorator: {
        initialValue: null,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        size: 10,
        limit: 10
      }
    }
  },
  config: {
    form: {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 18
      }
    },
    create: {
      fieldKeys: [],
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    update: {
      fieldKeys: ["fileType", "fileUrl"],
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    search: {},
    table: {
      fields: [{
        model: "areaText",
        title: "地址",
        width: 200
      }, {
        model: "buildArea",
        title: "面积（平方）",
        width: 200
      }, {
        model: "unitPrice",
        title: "单价",
        width: 200
      }, {
        model: "bank",
        title: "总价",
        width: 200
      }, {
        model: "gmtCreate",
        title: "更新时间",
        width: 200
      }],
      customize: {
        rowKey: "id",
        pagination: {
          pageSize: 20
        }
      },
      extend: {
        batch: true,
        action: {
          width: 150
        }
      }
    }
  }
};