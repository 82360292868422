var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _vm._v(" 客户姓名 : "),
          _c("a-input", {
            staticStyle: { width: "200px", "margin-right": "20px" },
            attrs: { placeholder: "请输入", allowClear: "" },
            on: { change: _vm.search },
            model: {
              value: _vm.from.customerName,
              callback: function($$v) {
                _vm.$set(_vm.from, "customerName", $$v)
              },
              expression: "from.customerName"
            }
          }),
          _vm._v(" 当前状态 : "),
          _c(
            "a-select",
            {
              staticStyle: { width: "200px" },
              attrs: { allowClear: "", placeholder: "请选择" },
              on: { change: _vm.changeHandele },
              model: {
                value: _vm.statusType,
                callback: function($$v) {
                  _vm.statusType = $$v
                },
                expression: "statusType"
              }
            },
            [
              _c("a-select-option", { attrs: { value: "0" } }, [
                _vm._v("逾期")
              ]),
              _c("a-select-option", { attrs: { value: "1" } }, [_vm._v("正常")])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c("a-button", { attrs: { type: "primary" } }, [_vm._v(" 一键导出 ")])
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataList,
          rowKey: "id",
          pagination: _vm.paginationOpt,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function(text, record) {
              return _c("span", {}, [
                _vm._v(_vm._s(record.customerInfosVO.customerVO.name))
              ])
            }
          },
          {
            key: "overStatus",
            fn: function(text, record) {
              return _c("span", {}, [
                _vm._v(_vm._s(_vm._f("filterType")(record.overStatus)))
              ])
            }
          },
          {
            key: "tableAction",
            fn: function(text, record) {
              return _c("span", {}, [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.show(record)
                      }
                    }
                  },
                  [_vm._v("查看")]
                )
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }