var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      attrs: { form: _vm.form, labelCol: { span: 6 }, wrapperCol: { span: 18 } }
    },
    [
      _c("a-divider", [_vm._v("借款人及家庭基本信息")]),
      _c(
        "a-row",
        [
          _c("XqFormObject", {
            attrs: {
              form: _vm.form,
              item: _vm.formFields["customerVO"],
              dataSource: _vm.dataSource,
              editable: _vm.editable
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.info, function(key) {
                  return {
                    key: key,
                    fn: function(ref) {
                      var field = ref.field
                      return [
                        _c(
                          "a-col",
                          { key: key, attrs: { span: 12 } },
                          [
                            _c("XqItem", {
                              attrs: {
                                form: _vm.form,
                                item: field["item"],
                                dataSource: field["dataSource"],
                                dynamicData: _vm.dynamicData,
                                editable: _vm.editable,
                                rowKey: field["rowKey"]
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          }),
          _c("XqFormObject", {
            attrs: {
              form: _vm.form,
              item: _vm.formFields["customerOccupationVO"],
              dataSource: _vm.dataSource,
              editable: _vm.editable
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.customerOccupationVOKeys, function(key) {
                  return {
                    key: key,
                    fn: function(ref) {
                      var field = ref.field
                      return [
                        _c(
                          "a-col",
                          { key: key, attrs: { span: 12 } },
                          [
                            _c("XqItem", {
                              attrs: {
                                form: _vm.form,
                                item: field["item"],
                                dataSource: field["dataSource"],
                                dynamicData: _vm.dynamicData,
                                editable: _vm.editable,
                                rowKey: field["rowKey"]
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          }),
          _vm._l(_vm.data1, function(item1) {
            return [
              _c(
                "a-col",
                { key: item1, attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      dynamicData: _vm.dynamicData,
                      item: _vm.formFields[item1],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              )
            ]
          }),
          _c("a-divider", [_vm._v("借款人信用状况")]),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("XqFormTable", {
                attrs: {
                  form: _vm.form,
                  dataSource: _vm.dataSource,
                  dynamicData: _vm.dynamicData,
                  item: _vm.formFields["creditList"],
                  editable: _vm.editable
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { staticStyle: { "margin-top": "20px" }, attrs: { span: 24 } },
            [
              _c("XqItem", {
                attrs: {
                  form: _vm.form,
                  dataSource: _vm.dataSource,
                  dynamicData: _vm.dynamicData,
                  item: _vm.formFields["creditExtend"],
                  editable: _vm.editable
                }
              })
            ],
            1
          ),
          _vm.showSpouseInfo
            ? [
                _c("a-divider", [_vm._v("借款人配偶基本信息")]),
                _c("XqFormObject", {
                  attrs: {
                    form: _vm.form,
                    item: _vm.formFields["spouseInfoVO"],
                    dataSource: _vm.dataSource,
                    editable: _vm.editable
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.spouseInfoVOKeys, function(key) {
                        return {
                          key: key,
                          fn: function(ref) {
                            var field = ref.field
                            return [
                              _c(
                                "a-col",
                                { key: key, attrs: { span: 12 } },
                                [
                                  _c("XqItem", {
                                    attrs: {
                                      form: _vm.form,
                                      item: field["item"],
                                      dataSource: field["dataSource"],
                                      dynamicData: _vm.dynamicData,
                                      editable: _vm.editable,
                                      rowKey: field["rowKey"]
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      })
                    ],
                    null,
                    true
                  )
                }),
                _c("a-divider", [_vm._v("借款人配偶信用状况")]),
                _c(
                  "a-col",
                  { attrs: { span: 24 } },
                  [
                    _c("XqFormTable", {
                      attrs: {
                        form: _vm.form,
                        dataSource: _vm.dataSource,
                        dynamicData: _vm.dynamicData,
                        item: _vm.formFields["spouseCreditList"],
                        editable: _vm.editable
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-col",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { span: 24 }
                  },
                  [
                    _c("XqItem", {
                      attrs: {
                        form: _vm.form,
                        dataSource: _vm.dataSource,
                        dynamicData: _vm.dynamicData,
                        item: _vm.formFields["spouseCreditExtend"],
                        editable: _vm.editable
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c("a-divider", [_vm._v("资产信息")]),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("XqFormTable", {
                attrs: {
                  form: _vm.form,
                  dataSource: _vm.dataSource,
                  dynamicData: _vm.dynamicData,
                  item: _vm.formFields["assetList"],
                  editable: _vm.editable
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { staticStyle: { "margin-top": "20px" }, attrs: { span: 24 } },
            [
              _c("XqItem", {
                attrs: {
                  form: _vm.form,
                  dataSource: _vm.dataSource,
                  dynamicData: _vm.dynamicData,
                  item: _vm.formFields["assetExtend"],
                  editable: _vm.editable
                }
              })
            ],
            1
          ),
          _c("a-divider", [_vm._v("经营情况")]),
          _c("XqFormObject", {
            attrs: {
              form: _vm.form,
              item: _vm.formFields["operateVO"],
              dataSource: _vm.dataSource,
              editable: _vm.editable
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.operateVOKeys, function(key) {
                  return {
                    key: key,
                    fn: function(ref) {
                      var field = ref.field
                      return [
                        _c(
                          "a-col",
                          { key: key, attrs: { span: 12 } },
                          [
                            _c("XqItem", {
                              attrs: {
                                form: _vm.form,
                                item: field["item"],
                                dataSource: field["dataSource"],
                                dynamicData: _vm.dynamicData,
                                editable: _vm.editable,
                                rowKey: field["rowKey"]
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          }),
          _c("a-divider", [_vm._v("担保物信息")]),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("XqFormTable", {
                attrs: {
                  form: _vm.form,
                  dataSource: _vm.dataSource,
                  dynamicData: _vm.dynamicData,
                  item: _vm.formFields["guaranteeList"],
                  editable: _vm.editable
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            {
              staticStyle: { "margin-top": "20px", "margin-bottom": "40px" },
              attrs: { span: 24 }
            },
            [
              _c("XqItem", {
                attrs: {
                  form: _vm.form,
                  dataSource: _vm.dataSource,
                  dynamicData: _vm.dynamicData,
                  item: _vm.formFields["guaranteeExtend"],
                  editable: _vm.editable
                }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }