import "core-js/modules/es.array.find";
import "core-js/modules/es.array.map";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _toConsumableArray from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import * as houseApi from "./api";
export default {
  name: "",
  components: {},
  props: {
    needTitle: {
      type: Boolean,
      default: true
    },
    customerInfo: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      form: this.$form.createForm(this, {
        name: "form"
      }),
      revaluation: true,
      areaOptions: [],
      formItem: false,
      houseSelectDisableds: true,
      provinceId: null,
      cityId: null,
      areaId: null,
      projectSelectDisabled: true,
      projectOptions: [],
      projectId: null,
      project: {},
      houseSelectDisabled: true,
      houseOptions: [],
      buildingId: null,
      building: {},
      floor: null,
      house: {},
      buildArea: "",
      assetEnquityDisabled: true,
      result: null,
      areaText: "",
      houseText: "",
      housingValuationVO: {}
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.list();
  },
  mounted: function mounted() {
    this.initProviceData();
  },
  methods: {
    list: function list() {
      this.housingValuationVO = this.customerInfo.housingValuationVO;
    },
    revaluAtion: function revaluAtion() {
      this.formItem = true;
      this.revaluation = false;
    },
    changeBuildArea: function changeBuildArea(e) {
      if (e.data !== "") {
        this.assetEnquityDisabled = false;
      }
    },
    assetEnquity: function assetEnquity() {
      var _this = this;

      this.form.validateFields(function (err, values) {
        if (!err) {
          _this.buildArea = values.buildArea;
          houseApi.assetEnquiryReq({
            cityId: _this.cityId,
            areaId: _this.areaId,
            projectId: _this.projectId,
            projectName: _this.project.label,
            buildingId: _this.buildingId,
            buildingName: _this.building["label"],
            floor: _this.floor,
            houseId: _this.house["value"],
            houseName: _this.house["label"],
            buildArea: values.buildArea,
            customInfoId: _this.customerInfo.id,
            areaText: _this.areaText
          }).then(function (res) {
            _this.result = res.data;
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      });
    },
    onHouseChange: function onHouseChange(value, selectedOptions) {
      if (value.length > 2) {
        this.houseSelectDisableds = false;
        this.building = selectedOptions[0];
        this.house = selectedOptions[2];
        var tempHouseText = "";

        for (var i = 0; i < selectedOptions.length; i++) {
          tempHouseText += selectedOptions[i]["label"];
        }

        this.houseText = tempHouseText;
      } else {
        this.assetEnquityDisabled = true;
      }
    },
    loadHouseData: function loadHouseData(selectedOptions) {
      var _this2 = this;

      var targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      switch (targetOption.type) {
        case "build":
          this.buildingId = targetOption.value;
          houseApi.floorListReq({
            cityId: this.cityId,
            buildingId: this.buildingId
          }).then(function (res) {
            var resArr = res.data.map(function (item) {
              return {
                value: item.floor,
                label: item.floor + "层",
                isLeaf: false,
                type: "floor"
              };
            });
            targetOption.loading = false;
            targetOption.children = resArr;
            _this2.houseOptions = _toConsumableArray(_this2.houseOptions);
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
          break;

        case "floor":
          this.floor = targetOption.value;
          houseApi.houseListReq({
            cityId: this.cityId,
            buildingId: this.buildingId,
            floor: this.floor
          }).then(function (res) {
            var resArr = res.data.map(function (item) {
              return {
                value: item.houseId,
                label: item.houseName,
                isLeaf: true,
                type: "house",
                buildArea: item.buildArea,
                housePurposeType: item.housePurposeType
              };
            });
            targetOption.loading = false;
            targetOption.children = resArr;
            _this2.houseOptions = _toConsumableArray(_this2.houseOptions);
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
          break;

        default:
          break;
      }
    },
    onProjectSelect: function onProjectSelect(value) {
      var _this3 = this;

      this.projectId = value;
      this.project = this.projectOptions.find(function (item) {
        return item.value === value;
      });
      this.houseSelectDisabled = false;
      this.assetEnquityDisabled = true;
      houseApi.buildingListReq({
        cityId: this.cityId,
        projectId: this.projectId
      }).then(function (res) {
        _this3.houseOptions = res.data.map(function (item) {
          return {
            value: item.buildingId,
            label: item.buildingName,
            isLeaf: false,
            type: "build"
          };
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    onProjectChange: _.throttle(function (value) {
      var _this4 = this;

      houseApi.projectListReq({
        areaId: this.areaId,
        cityId: this.cityId,
        key: value.replace(/\s+/g, "")
      }).then(function (res) {
        _this4.projectOptions = res.data.map(function (item) {
          return {
            value: item.projectId,
            label: item.projectName
          };
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }, 500),
    loadAreaData: function loadAreaData(selectedOptions) {
      var _this5 = this;

      var targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      switch (targetOption.type) {
        case "province":
          houseApi.cityListReq({
            provinceId: targetOption.value
          }).then(function (res) {
            var resArr = res.data.map(function (item) {
              return {
                value: item.cityId,
                label: item.cityName,
                isLeaf: false,
                type: "city"
              };
            });
            targetOption.loading = false;
            targetOption.children = resArr;
            _this5.areaOptions = _toConsumableArray(_this5.areaOptions);
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
          break;

        case "city":
          houseApi.areaListReq({
            cityId: targetOption.value
          }).then(function (res) {
            var resArr = res.data.map(function (item) {
              return {
                value: item.areaId,
                label: item.areaName,
                isLeaf: true,
                type: "area"
              };
            });
            targetOption.loading = false;
            targetOption.children = resArr;
            _this5.areaOptions = _toConsumableArray(_this5.areaOptions);
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
          break;

        default:
          break;
      }
    },
    onAreaChange: function onAreaChange(value, selectedOptions) {
      if (value.length > 2) {
        this.provinceId = value[0];
        this.cityId = value[1];
        this.areaId = value[2];
        this.dataList = value;
        console.log(this.dataList);
        this.projectSelectDisabled = false;
        this.houseSelectDisabled = true;
        this.assetEnquityDisabled = true;
        var tempAreaText = "";

        for (var i = 0; i < selectedOptions.length; i++) {
          tempAreaText += selectedOptions[i]["label"];
        }

        this.areaText = tempAreaText;
      } else {
        this.projectSelectDisabled = true;
        this.houseSelectDisabled = true;
        this.assetEnquityDisabled = true;
      }
    },
    initProviceData: function initProviceData() {
      var _this6 = this;

      houseApi.provinceListReq().then(function (res) {
        _this6.areaOptions = res.data.map(function (item) {
          return {
            value: item.provinceId,
            label: item.provinceName,
            isLeaf: false,
            type: "province"
          };
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};