var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form } },
        [
          _c(
            "a-collapse",
            {
              attrs: { expandIconPosition: _vm.expandIconPosition },
              model: {
                value: _vm.activeKey,
                callback: function($$v) {
                  _vm.activeKey = $$v
                },
                expression: "activeKey"
              }
            },
            [
              _c(
                "a-collapse-panel",
                { key: "1", attrs: { header: "基本资料" } },
                [
                  _c("BaseinfoCustomer", {
                    ref: "kfb",
                    attrs: {
                      dataSource: _vm.tempRecord,
                      dynamicData: _vm.dynamicData,
                      editable: _vm.editable,
                      formFields: _vm.FormJson["fields"],
                      formConfig: _vm.FormJson["config"]
                    },
                    on: {
                      married: _vm.married,
                      fatherMethod: _vm.fatherMethod,
                      cancel: _vm.updateCustomerInfo
                    }
                  })
                ],
                1
              ),
              _c(
                "a-collapse-panel",
                { key: "2", attrs: { header: "借款人信用状态" } },
                [
                  _c("SpouseCreditList", {
                    ref: "cred",
                    attrs: {
                      dataSource: _vm.tempRecord,
                      dynamicData: _vm.dynamicData,
                      editable: _vm.editable,
                      formFields: _vm.FormJson["fields"],
                      formConfig: _vm.FormJson["config"]
                    },
                    on: {
                      spouseList: _vm.spouseList,
                      cancel: _vm.updateCustomerInfo
                    }
                  })
                ],
                1
              ),
              _c(
                "a-collapse-panel",
                { key: "3", attrs: { header: "资产信息" } },
                [
                  _c("AssetList", {
                    ref: "asset",
                    attrs: {
                      dataSource: _vm.tempRecord,
                      dynamicData: _vm.dynamicData,
                      editable: _vm.editable,
                      formFields: _vm.FormJson["fields"],
                      formConfig: _vm.FormJson["config"]
                    },
                    on: {
                      assetList: _vm.assetList,
                      cancel: _vm.updateCustomerInfo
                    }
                  })
                ],
                1
              ),
              _c(
                "a-collapse-panel",
                { key: "4", attrs: { header: "经营状况" } },
                [
                  _c("Operate", {
                    ref: "operate",
                    attrs: {
                      dataSource: _vm.tempRecord,
                      dynamicData: _vm.dynamicData,
                      editable: _vm.editable,
                      formFields: _vm.FormJson["fields"],
                      formConfig: _vm.FormJson["config"]
                    },
                    on: {
                      operateList: _vm.operateList,
                      cancel: _vm.updateCustomerInfo
                    }
                  })
                ],
                1
              ),
              _c(
                "a-collapse-panel",
                { key: "5", attrs: { header: "担保物信息" } },
                [
                  _c("GuaranteeList", {
                    ref: "guarante",
                    attrs: {
                      dataSource: _vm.tempRecord,
                      dynamicData: _vm.dynamicData,
                      editable: _vm.editable,
                      formFields: _vm.FormJson["fields"],
                      formConfig: _vm.FormJson["config"]
                    },
                    on: {
                      guaranteeLists: _vm.guaranteeLists,
                      cancel: _vm.updateCustomerInfo
                    }
                  })
                ],
                1
              ),
              _vm.show
                ? [
                    _c(
                      "a-collapse-panel",
                      { key: "6", attrs: { header: "借款人配偶基本信息" } },
                      [
                        _c("SpouseInfo", {
                          ref: "spouse",
                          attrs: {
                            dataSource: _vm.tempRecord,
                            dynamicData: _vm.dynamicData,
                            editable: _vm.editable,
                            formFields: _vm.FormJson["fields"],
                            formConfig: _vm.FormJson["config"]
                          },
                          on: {
                            spouseInfo: _vm.spouseInfo,
                            cancel: _vm.updateCustomerInfo
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-collapse-panel",
                      { key: "7", attrs: { header: "借款人配偶信用状况" } },
                      [
                        _c("SpouseCredit", {
                          ref: "rowList",
                          attrs: {
                            dataSource: _vm.tempRecord,
                            dynamicData: _vm.dynamicData,
                            editable: _vm.editable,
                            formFields: _vm.FormJson["fields"],
                            formConfig: _vm.FormJson["config"]
                          },
                          on: {
                            spouseRowList: _vm.spouseRowList,
                            cancel: _vm.updateCustomerInfo
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }