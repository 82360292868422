var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "xq-form-wrapper" },
    [
      _c(
        "div",
        { staticClass: "xq-form-search" },
        [
          _c("Extendform", {
            ref: "searchForm",
            attrs: {
              mode: "search",
              dataSource: {},
              dynamicData: _vm.dynamicData,
              formValidators: _vm.FormJson["validators"],
              formFields: _vm.FormJson["fields"],
              formConfig: _vm.FormJson["config"]["search"]
            }
          }),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.filterRecord }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "default" },
                      on: { click: _vm.resetRecord }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "xq-form-action" },
        [
          _c(
            "a-space",
            { staticClass: "xq-form-action-left" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.showCreateModal }
                },
                [_vm._v("新增产品")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-spin",
        { staticClass: "xq-form-content", attrs: { spinning: _vm.spinning } },
        [
          _c("XqTable", {
            staticClass: "xq-form-content-table",
            attrs: {
              customize: _vm.FormJson["config"]["tableLoanProd"]["customize"],
              dataSource: _vm.tableData,
              columns: _vm.columns,
              rowSelection: _vm.FormJson["config"]["tableLoanProd"]["extend"][
                "batch"
              ]
                ? {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange
                  }
                : null
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.columns, function(col, index) {
                  return {
                    key: "xq_" + col.dataIndex,
                    fn: function() {
                      return [
                        _c("span", { key: index }, [
                          _vm._v(" " + _vm._s(col.customTitle) + " ")
                        ])
                      ]
                    },
                    proxy: true
                  }
                }),
                _vm._l(_vm.columns, function(col, index) {
                  return {
                    key: col.dataIndex,
                    fn: function(text, record) {
                      return [
                        col.dataIndex === "tableAction"
                          ? _c(
                              "span",
                              { key: index },
                              [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.showUpdateModal(record)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" }
                                }),
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: "确认要删除记录吗？",
                                      okText: "确认",
                                      cancelText: "取消"
                                    },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.deleteRecord(record)
                                      }
                                    }
                                  },
                                  [_c("a", [_vm._v("删除")])]
                                )
                              ],
                              1
                            )
                          : _c("XqTableCell", {
                              key: index,
                              attrs: {
                                record: record,
                                field: _vm.FormJson["fields"][col.dataIndex],
                                dynamicData: _vm.dynamicData
                              }
                            })
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          }),
          _c(
            "div",
            { staticClass: "xq-form-content-pagination" },
            [
              _c("div", { staticClass: "xq-form-content-pagination-total" }, [
                _vm._v("共 " + _vm._s(_vm.total) + " 条")
              ]),
              _c("a-pagination", {
                attrs: {
                  pageSize: _vm.pageSize,
                  total: _vm.total,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ["20", "50", "100"]
                },
                on: {
                  change: _vm.onPageChange,
                  showSizeChange: _vm.onPageSizeChange
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: true,
            title: "新增产品",
            width: 800
          },
          model: {
            value: _vm.createModalVisible,
            callback: function($$v) {
              _vm.createModalVisible = $$v
            },
            expression: "createModalVisible"
          }
        },
        [
          _c("Extendform", {
            ref: "createForm",
            attrs: {
              dataSource: {},
              dynamicData: _vm.dynamicData,
              formValidators: _vm.FormJson["validators"],
              formFields: _vm.FormJson["fields"],
              formConfig: _vm.FormJson["config"]["createLoanProd"]
            }
          }),
          _c("XqModalFooter", {
            attrs: { slot: "footer" },
            on: { cancel: _vm.createCancel, ok: _vm.createOk },
            slot: "footer"
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: true,
            title: "修改产品",
            width: 800
          },
          model: {
            value: _vm.updateModalVisible,
            callback: function($$v) {
              _vm.updateModalVisible = $$v
            },
            expression: "updateModalVisible"
          }
        },
        [
          _c("Extendform", {
            ref: "updateForm",
            attrs: {
              dataSource: _vm.tempRecord,
              dynamicData: _vm.dynamicData,
              formFields: _vm.FormJson["fields"],
              formValidators: _vm.FormJson["validators"],
              formConfig: _vm.FormJson["config"]["updateLoanProd"]
            }
          }),
          _c("XqModalFooter", {
            attrs: { slot: "footer" },
            on: { cancel: _vm.updateCancel, ok: _vm.updateOk },
            slot: "footer"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }