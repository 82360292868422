import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import newApproval from "./common/new-approval"; // import { Approval, ApprovalApi, Common } from "@/api/approval/index.js";

import defaultAvatar from "./assets/icons/person1.png";
import defaultAvatarBack from "./assets/icons/person2.png";
import defaultAvatarDone from "./assets/icons/person3.png";
import defaultAvatarWait from "./assets/icons/person4.png";
import arrowUp from "./assets/icons/sjup.png";
import arrowDown from "./assets/icons/sjdown.png";
export default {
  name: "",
  components: {
    newApproval: newApproval
  },
  props: {},
  data: function data() {
    return {
      defaultAvatar: defaultAvatar,
      defaultAvatarBack: defaultAvatarBack,
      defaultAvatarDone: defaultAvatarDone,
      defaultAvatarWait: defaultAvatarWait,
      arrowUp: arrowUp,
      arrowDown: arrowDown,
      listNumAll: 0,
      ApprovalTitle: "审批详情",
      pagination: {
        pageNo: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "50", "100"],
        showQuickJumper: true,
        total: 10
      },
      jsonData: {
        list: [],
        config: {
          layout: "horizontal",
          labelCol: {
            span: 4
          },
          wrapperCol: {
            span: 18
          },
          hideRequiredMark: false,
          customStyle: ""
        }
      },
      editroDrawer: false,
      showApprovaler: false,
      showRemark: false,
      itemDetail: [],
      params: {
        approvId: null,
        creatUserId: null,
        gmtCreateStart: null,
        gmtCreateEnd: null
      },
      showStatus: false,
      createApp: false,
      approvalHandle: null,
      // 审批操作同意或拒绝
      showApprovalHandle: false,
      confirmAlert: false,
      confirmLoading: false,
      items: [],
      itemTypes: [],
      data: [],
      approvalUserDetail: {},
      statusData: {},
      userList: [],
      form: this.$form.createForm(this, {
        name: "coordinated"
      }),
      companyId: this.$store.state.hr.companyId,
      approvalTypelist: [{
        state: "审批中",
        value: 1
      }, {
        state: "通过",
        value: 2
      }, {
        state: "驳回",
        value: 3
      }]
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getCcme();
    this.getAllBySite();
    this.getApprovalPeople();
  },
  mounted: function mounted() {},
  methods: {
    // 操作审批
    editorData0: function editorData0(record) {
      var _this = this;

      this.ApprovalTitle = record.remark ? record.remark : "审批详情"; // eslint-disable-next-line no-undef

      Approval({
        url: ApprovalApi.hrAppDetail + record.userApproId
      }).then(function (_ref) {
        var data = _ref.data;

        if (data) {
          _this.statusData = data;
          _this.showStatus = true;
          _this.itemDetail = data.userApproInfoList;
          _this.jsonData.list = _this.transSourceToJson(_this.itemDetail);
          _this.statusData.approName = record.approName;
          _this.approvalUserDetail = data.userApproInfoList;
          _this.notifierlist = data.userApproNodeList.find(function (item) {
            return item.nodeType === "notifier";
          });
        }
      });
    },
    // 查看审批节点
    statusDetail0: function statusDetail0(record) {
      var _this2 = this;

      this.showApprovalHandle = false; // eslint-disable-next-line no-undef

      Approval({
        url: ApprovalApi.hrAppDetail + record.userApproId
      }).then(function (_ref2) {
        var data = _ref2.data;

        if (data) {
          _this2.showStatus = true;
          _this2.statusData = data;
          _this2.userApproverId = record.userApproverId;
          _this2.statusData.approName = record.approName;
          _this2.approvalUserDetail = data.userApproInfoList;
          _this2.notifierlist = data.userApproNodeList.find(function (item) {
            return item.nodeType === "notifier";
          });
        }
      });
    },
    // 查看审批节点
    statusDetail: function statusDetail(record) {
      var _this3 = this;

      this.ApprovalTitle = record.remark ? record.remark : "审批详情";
      this.showStatus = true;
      this.showApprovalHandle = false; // eslint-disable-next-line no-undef

      Approval({
        url: ApprovalApi.hrAppDetail + record.userApproId
      }).then(function (_ref3) {
        var data = _ref3.data;

        if (data) {
          _this3.statusData = data;
          _this3.userApproverId = record.userApproverId;
          _this3.statusData.approName = record.approName;
          _this3.approvalUserDetail = data.userApproInfoList;
          _this3.jsonData.list = _this3.transSourceToJson(data.userApproInfoList);
          _this3.notifierlist = data.userApproNodeList.find(function (item) {
            return item.nodeType === "notifier";
          }) ? data.userApproNodeList.find(function (item) {
            return item.nodeType === "notifier";
          }) : [];
        }
      });
    },
    // 操作审批节点
    editorData: function editorData(record) {
      var _this4 = this;

      this.ApprovalTitle = record.remark ? record.remark : "审批详情";
      this.showStatus = true;
      this.showApprovalHandle = true; // eslint-disable-next-line no-undef

      Approval({
        url: ApprovalApi.hrAppDetail + record.userApproId
      }).then(function (_ref4) {
        var data = _ref4.data;

        if (data) {
          _this4.statusData = data;
          _this4.userApproverId = record.userApproverId;
          _this4.itemDetail = data.userApproInfoList;
          _this4.jsonData.list = _this4.transSourceToJson(_this4.itemDetail);
          _this4.statusData.approName = record.approName;
          _this4.approvalUserDetail = data.userApproInfoList;
          _this4.notifierlist = data.userApproNodeList.find(function (item) {
            return item.nodeType === "notifier";
          }) ? data.userApproNodeList.find(function (item) {
            return item.nodeType === "notifier";
          }) : [];
        }
      });
    },
    transSourceToJson: function transSourceToJson(data) {
      var list = [];
      data.map(function (item) {
        if (item.props && item.props.length > 0) {
          var obj = JSON.parse(item.props);
          obj["options"]["defaultValue"] = item.value;
          obj["options"]["disabled"] = item.settingType !== "edit";
          obj["options"]["hidden"] = item.settingType === "hide";
          list.push(obj);
        }
      });
      return list;
    },
    // 选发起人
    onChangeUser: function onChangeUser(v) {
      this.params.creatUserId = v;
      this.getCcme();
    },
    // 抄送我的审批
    getCcme: function getCcme() {
      var _this5 = this;

      // eslint-disable-next-line no-undef
      Approval({
        // eslint-disable-next-line no-undef
        url: ApprovalApi.ccMe,
        params: _objectSpread(_objectSpread(_objectSpread({}, this.params), this.pagination), {}, {
          companyId: this.companyId,
          moduleType: "hr"
        })
      }).then(function (_ref5) {
        var data = _ref5.data;
        _this5.data = data.entities;
        _this5.pagination.pageNo = 1;
        _this5.pagination.total = data.entityCount;
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.pageNo = pagination.current;
      this.getCcme();
    },
    // 获取所有审批 bysite
    getAllBySite: function getAllBySite() {
      var _this6 = this;

      // eslint-disable-next-line no-undef
      Approval({
        // eslint-disable-next-line no-undef
        url: ApprovalApi.allByCompanyAndUser + this.companyId + "/hr"
      }).then(function (data) {
        _this6.items = data.data;
        var listNum = 0;

        _this6.items.forEach(function (item) {
          item.key = item.id;
          item.title = item.groupName;
          listNum += item.notifierMeNum;
        });

        _this6.listNumAll = listNum;
      }).catch(function (err) {
        return console.warn(err);
      });
    },
    // 获取选发起人列表
    getApprovalPeople: function getApprovalPeople() {
      var _this7 = this;

      // eslint-disable-next-line no-undef
      Approval({
        // eslint-disable-next-line no-undef
        url: Common.userList,
        params: {
          companyId: this.companyId
        }
      }).then(function (_ref6) {
        var data = _ref6.data;
        _this7.userList = data;
      });
    },
    // 筛选审批
    selectApproval: function selectApproval(e) {
      var _this8 = this;

      this.params.status = e; // eslint-disable-next-line no-undef

      Approval({
        // eslint-disable-next-line no-undef
        url: ApprovalApi.ccMe,
        params: _objectSpread(_objectSpread({}, this.params), {}, {
          companyId: this.companyId,
          moduleType: "hr"
        })
      }).then(function (_ref7) {
        var data = _ref7.data;
        _this8.data = data.entities;
        _this8.pagination.pageNo = 1;
        _this8.pagination.total = data.entityCount;
      });
    },
    // 审批组
    subMenu: function subMenu(item) {
      this.params.approvId = null;
      this.itemTypes = item.approFlowList;
      this.params.approvGroupId = item.id;
      this.getCcme();
    },

    /* menuItem */
    menuItem: function menuItem(item) {
      this.params.approvGroupId = null;
      this.params.approvId = item.id;
      this.getCcme();
    },
    // /* subMenu */
    // subMenu(group) {
    //   console.log('debug log --> ', group);
    //   this.itemTypes = group.approFlowList;
    // },
    // /* menuItem */
    // menuItem(item) {
    //   this.params.approvId = item.key;
    //   this.getCcme();
    // },

    /* 日期选择 */
    onChange: function onChange(date, dateString) {
      this.params.gmtCreateStart = null;
      this.params.gmtCreateEnd = null;

      if (date.length) {
        this.params.gmtCreateStart = date[0]._d.getTime();
        this.params.gmtCreateEnd = date[1]._d.getTime();
      }

      this.getCcme();
    },
    showPeople: function showPeople(idx) {
      this.showApprovaler = this.showApprovaler === idx ? null : idx;
    },
    // 通过审批
    onSure: function onSure() {
      var _this9 = this;

      this.$refs.kfd.getData().then(function (values) {
        var keys = Object.keys(values);

        var userApproInfoList = _this9.itemDetail.filter(function (val) {
          return val.settingType === "edit";
        });

        keys.map(function (key) {
          var idx = userApproInfoList.findIndex(function (item) {
            return item.formId === key;
          });

          if (idx > -1) {
            userApproInfoList[idx]["value"] = values[key];
          }
        });

        _this9.form.validateFields(function (err, values) {
          if (!err) {
            // eslint-disable-next-line no-undef
            Approval({
              // eslint-disable-next-line no-undef
              url: ApprovalApi.sureApp + _this9.userApproverId,
              method: "PUT",
              data: {
                remark: values.remark,
                userApproInfoList: userApproInfoList
              }
            }).then(function (data) {
              if (data.success) {
                _this9.editroDrawer = false;

                _this9.$notification["success"]({
                  message: "审批通过"
                });

                _this9.reload();
              }
            });
          }
        });
      });
    },
    // 驳回审批
    onBack: function onBack() {
      var _this10 = this;

      this.$refs.kfd.getData().then(function (values) {
        var keys = Object.keys(values);

        var userApproInfoList = _this10.itemDetail.filter(function (val) {
          return val.settingType === "edit";
        });

        keys.map(function (key) {
          var idx = userApproInfoList.findIndex(function (item) {
            return item.formId === key;
          });

          if (idx > -1) {
            userApproInfoList[idx]["value"] = values[key];
          }
        });

        _this10.form.validateFields(function (err, values) {
          if (!err) {
            // eslint-disable-next-line no-undef
            Approval({
              // eslint-disable-next-line no-undef
              url: ApprovalApi.rejectApp + _this10.userApproverId,
              method: "PUT",
              data: {
                remark: values.remark,
                userApproInfoList: userApproInfoList
              }
            }).then(function (data) {
              if (data.success) {
                _this10.editroDrawer = false;

                _this10.$notification["success"]({
                  message: "审批驳回"
                });

                _this10.reload();
              }
            });
          }
        });
      });
    },
    reject: function reject() {
      var _this11 = this;

      this.form.validateFields(function (err, values) {
        if (!err) {
          _this11.confirmAlert = true;
          _this11.approvalHandle = false;
        } else {
          _this11.$notification["warning"]({
            message: "请填写审批意见"
          });
        }
      });
    },
    agree: function agree() {
      var _this12 = this;

      this.form.validateFields(function (err, values) {
        if (!err) {
          _this12.confirmAlert = true;
          _this12.approvalHandle = true;
        } else {
          _this12.$notification["warning"]({
            message: "请填写审批意见"
          });
        }
      });
    },
    confirmHandle: function confirmHandle() {
      if (this.approvalHandle === true) {
        this.onSure();
      } else {
        this.onBack();
      }
    },
    getStatusTitle: function getStatusTitle(status) {
      // 0:待审批1:审批中2:通过3:驳回4:撤回5:其他人驳回
      if (status === 0) {
        return "待审批";
      } else if (status === 1) {
        return "审批中";
      } else if (status === 2) {
        return "已通过";
      } else if (status === 3) {
        return "已驳回";
      } else if (status === 4) {
        return "已撤回";
      } else if (status === 5) {
        return "其他人通过";
      } else if (status === 6) {
        return "其他人驳回";
      }

      return "其他";
    },
    getActivateType: function getActivateType(type) {
      // 0:依次审批;1:会签(需所有审批人同意);2:或签(一名审批人同意或拒绝即可)
      if (type === 0) {
        return "依次审批";
      } else if (type === 1) {
        return "会签";
      } else if (type === 2) {
        return "或签";
      }

      return "其他";
    }
  }
};