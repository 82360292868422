var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "a-row",
        { staticClass: "box_top" },
        [
          _c("a-col", { staticClass: "box-left", attrs: { span: 15 } }, [
            _c("div", { staticClass: "top_left" }, [
              _c("img", {
                staticClass: "top_left_img",
                attrs: { src: _vm.avatar, alt: "" }
              })
            ]),
            _c("div", { staticClass: "top_left_title" }, [
              _c("span", { staticClass: "top_left_title_top" }, [
                _vm._v(" " + _vm._s(_vm.name) + "你好 , 祝你开心每一天！ ")
              ])
            ])
          ]),
          _c(
            "div",
            [
              _c("a-col", { staticClass: "top_right", attrs: { span: 3 } }, [
                _c("span", { staticClass: "top_right_title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.show === true ? "意向客户待办池" : "意向客户待办池"
                    )
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "top_right_bottom" },
                  [
                    _c("span", { staticClass: "top_rigth_num" }, [
                      _vm._v(_vm._s(_vm.newSumList.potentialClients))
                    ]),
                    _c("a-icon", {
                      staticStyle: {
                        color: "#2697ff",
                        "font-size": "16px",
                        "margin-left": "5px",
                        cursor: "pointer"
                      },
                      attrs: { type: "right" },
                      on: { click: _vm.go }
                    })
                  ],
                  1
                )
              ]),
              _c("a-col", { staticClass: "top_right", attrs: { span: 3 } }, [
                _c("span", { staticClass: "top_right_title" }, [
                  _vm._v(
                    _vm._s(_vm.show === true ? "审批中" : "审批列表待办池")
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "top_right_bottom" },
                  [
                    _c("span", { staticClass: "top_rigth_num" }, [
                      _vm._v(" " + _vm._s(_vm.newSumList.awaitCommission))
                    ]),
                    _c("a-icon", {
                      staticStyle: {
                        color: "#2697ff",
                        "font-size": "16px",
                        "margin-left": "5px",
                        cursor: "pointer"
                      },
                      attrs: { type: "right" },
                      on: { click: _vm.go1 }
                    })
                  ],
                  1
                )
              ]),
              _c("a-col", { staticClass: "top_right", attrs: { span: 3 } }, [
                _c("span", { staticClass: "top_right_title" }, [
                  _vm._v(
                    _vm._s(_vm.show === true ? "审批通过" : "审批列表待审批")
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "top_right_bottom" },
                  [
                    _c("span", { staticClass: "top_rigth_num" }, [
                      _vm._v(" " + _vm._s(_vm.newSumList.awaitAudit))
                    ]),
                    _c("a-icon", {
                      staticStyle: {
                        color: "#2697ff",
                        "font-size": "16px",
                        "margin-left": "5px",
                        cursor: "pointer"
                      },
                      attrs: { type: "right" },
                      on: { click: _vm.go2 }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "box-center" }, [
        _c("div", { staticClass: "center-left" }, [
          _c("div", { staticClass: "center-left_top" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("../../assets//images/logos.png"), alt: "" }
            }),
            _c("span", { staticClass: "center_title" }, [_vm._v("推广统计")]),
            _c(
              "div",
              { staticClass: "center_left_top_right" },
              [
                _vm.show
                  ? _c(
                      "a-select",
                      {
                        staticStyle: { width: "150px" },
                        attrs: { allowClear: "", placeholder: "请选择" },
                        on: { change: _vm.handleChange }
                      },
                      _vm._l(_vm.options, function(item, index) {
                        return _c(
                          "a-select-option",
                          { key: index, attrs: { value: item.id } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c("a-range-picker", {
                  staticStyle: { width: "170px", "margin-right": "10px" },
                  on: { change: _vm.onChange }
                }),
                _vm._l(_vm.date, function(item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: { active: index === _vm.currentIndex },
                      staticStyle: {
                        "margin-right": "20px",
                        "font-size": "14px",
                        cursor: "pointer"
                      },
                      on: {
                        click: function($event) {
                          return _vm.popularize(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                })
              ],
              2
            )
          ]),
          _c("div", { staticClass: "center-center" }, [
            _c("div", {
              staticClass: "center-center-left",
              attrs: { id: "myChart" }
            }),
            _c("div", { staticClass: "xian" }),
            _c("div", { staticClass: "center-center-right" }, [
              _c("div", { staticClass: "center-center-right-title" }, [
                _vm._v("产品推广排名")
              ]),
              _c("div", { staticClass: "center-center-right-bottom" }, [
                _vm.productRankingList[0]
                  ? _c("div", { staticClass: "paiming" }, [
                      _c("img", {
                        staticStyle: { width: "20px" },
                        attrs: {
                          src: require("../../assets//images/pm.png"),
                          alt: ""
                        }
                      }),
                      _c("span", { staticClass: "daikuan" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productRankingList[0].productName) +
                            " "
                        )
                      ]),
                      _c("span", { staticClass: "danliang" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productRankingList[0].orderNum) +
                            "单 "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.productRankingList[1]
                  ? _c("div", { staticClass: "paiming" }, [
                      _c("img", {
                        staticStyle: { width: "20px" },
                        attrs: {
                          src: require("../../assets//images/pma.png"),
                          alt: ""
                        }
                      }),
                      _c("span", { staticClass: "daikuan" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productRankingList[1].productName) +
                            " "
                        )
                      ]),
                      _c("span", { staticClass: "danliang" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productRankingList[1].orderNum) +
                            "单 "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.productRankingList[2]
                  ? _c("div", { staticClass: "paiming" }, [
                      _c("img", {
                        staticStyle: { width: "20px" },
                        attrs: {
                          src: require("../../assets//images/pms.png"),
                          alt: ""
                        }
                      }),
                      _c("span", { staticClass: "daikuan" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productRankingList[2].productName) +
                            " "
                        )
                      ]),
                      _c("span", { staticClass: "danliang" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productRankingList[2].orderNum) +
                            "单 "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.productRankingList[3]
                  ? _c("div", { staticClass: "paiming" }, [
                      _c("div", { staticClass: "shuzi" }, [_vm._v("4")]),
                      _c("span", { staticClass: "daikuan" }, [
                        _vm._v(
                          " " + _vm._s(_vm.productRankingList[3].productName)
                        )
                      ]),
                      _c("span", { staticClass: "danliang" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productRankingList[3].orderNum) +
                            "单 "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.productRankingList[4]
                  ? _c("div", { staticClass: "paiming" }, [
                      _c("div", { staticClass: "shuzi" }, [_vm._v("5")]),
                      _c("span", { staticClass: "daikuan" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productRankingList[4].productName) +
                            " "
                        )
                      ]),
                      _c("span", { staticClass: "danliang" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productRankingList[4].orderNum) +
                            "单 "
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _c("div", { staticClass: "center-bottom" }, [
            _c("span", { staticClass: "beizhu" }, [
              _vm._v("注 : 仅展示推广量前八名")
            ]),
            _c("span", { staticClass: "gengduo", on: { click: _vm.chakan } }, [
              _vm._v("查看更多")
            ])
          ])
        ]),
        _c("div", { staticClass: "center-right" }, [
          _c("div", { staticClass: "center-left_top" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("../../assets//images/logos.png"), alt: "" }
            }),
            _c("span", { staticClass: "center_title" }, [_vm._v("业绩统计")]),
            _c(
              "div",
              { staticClass: "center_left_top_right" },
              [
                _vm.show
                  ? _c(
                      "a-select",
                      {
                        staticStyle: { width: "150px" },
                        attrs: { allowClear: "", placeholder: "请选择产品" },
                        on: { change: _vm.productsChange }
                      },
                      _vm._l(_vm.option, function(item, index) {
                        return _c(
                          "a-select-option",
                          { key: index, attrs: { value: item.id } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c("a-range-picker", {
                  staticStyle: { width: "170px", "margin-right": "10px" },
                  on: { change: _vm.productsOnChange }
                }),
                _vm._l(_vm.date, function(item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: { active: index === _vm.achievementIndex },
                      staticStyle: {
                        "margin-right": "20px",
                        "font-size": "14px",
                        cursor: "pointer"
                      },
                      on: {
                        click: function($event) {
                          return _vm.achievement(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                })
              ],
              2
            )
          ]),
          _c("div", {
            staticClass: "center-right_center",
            attrs: { id: "myChart1" }
          }),
          _c("div", { staticClass: "center-bottom" }, [
            _c("span", { staticClass: "beizhu" }, [
              _vm._v("注 : 仅展示业绩量前八名")
            ]),
            _c(
              "span",
              { staticClass: "gengduo", on: { click: _vm.chakanAll } },
              [_vm._v("查看更多")]
            )
          ])
        ])
      ]),
      _c(
        "a-row",
        { staticClass: "box-footer" },
        [
          _c("a-row", { staticClass: "center-left_top" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("../../assets//images/logos.png"), alt: "" }
            }),
            _c("span", { staticClass: "center_title" }, [_vm._v("汇总统计")]),
            _c(
              "div",
              { staticClass: "center_left_top_right" },
              [
                _vm._l(_vm.date1, function(item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: { active: index === _vm.gatherIndex },
                      staticStyle: {
                        "margin-right": "20px",
                        "font-size": "14px",
                        cursor: "pointer"
                      },
                      on: {
                        click: function($event) {
                          return _vm.gather(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                _c("a-range-picker", {
                  staticStyle: { width: "170px" },
                  on: { change: _vm.finshOnChange }
                })
              ],
              2
            )
          ]),
          _c(
            "a-row",
            [
              _c("a-col", { staticClass: "top_title", attrs: { span: 3 } }, [
                _c("div", { staticClass: "left_top" }, [_vm._v("总放款数")]),
                _c("div", { staticClass: "left_bottom" }, [
                  _vm._v(_vm._s(_vm.newSumList.sumLoan))
                ])
              ]),
              _c(
                "a-col",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "padding-left": "20px",
                    "padding-right": "20px"
                  },
                  attrs: { span: 21 }
                },
                [
                  [
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("新增客户")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(_vm._s(_vm.newSumList.newCustomer))
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("新增放款单数")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(_vm._s(_vm.newSumList.newLoanNum))
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("新增放款数(元)")
                      ]),
                      _c("div", { staticClass: "center_bottom" }, [
                        _vm._v(_vm._s(_vm.newSumList.newSumLoan))
                      ])
                    ]),
                    _c("div", { staticClass: "top_center" }, [
                      _c("div", { staticClass: "center_top" }, [
                        _vm._v("未结案客户数")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "center_bottom",
                          staticStyle: { color: "red" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.newSumList.noFinishedOrder) + " "
                          )
                        ]
                      )
                    ])
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "推广量列表", width: "50%", footer: "" },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.directNumList,
              pagination: false,
              rowKey: function(record) {
                return record.id
              }
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.columns, function(col, index) {
                  return {
                    key: col.dataIndex,
                    fn: function(text, record) {
                      return [
                        col.dataIndex === "num"
                          ? _c("span", { key: index }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    record.value == 0
                                      ? 0
                                      : (
                                          (record.value / _vm.countNum) *
                                          100
                                        ).toFixed(2)
                                  ) +
                                  " % "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "业绩量列表", width: "50%", footer: "" },
          model: {
            value: _vm.visibles,
            callback: function($$v) {
              _vm.visibles = $$v
            },
            expression: "visibles"
          }
        },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columns1,
              "data-source": _vm.performanceNumList,
              pagination: false,
              rowKey: function(record) {
                return record.id
              }
            }
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "推广量列表", width: "50%", footer: "" },
          model: {
            value: _vm.visible1,
            callback: function($$v) {
              _vm.visible1 = $$v
            },
            expression: "visible1"
          }
        },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.columnList,
              pagination: false,
              rowKey: function(record) {
                return record.id
              }
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.columns, function(col, index) {
                  return {
                    key: col.dataIndex,
                    fn: function(text, record) {
                      return [
                        col.dataIndex === "num"
                          ? _c("span", { key: index }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    record.value == 0
                                      ? 0
                                      : (
                                          (record.value / _vm.countNum1) *
                                          100
                                        ).toFixed(2)
                                  ) +
                                  " % "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "业绩量列表", width: "50%", footer: "" },
          model: {
            value: _vm.visibles2,
            callback: function($$v) {
              _vm.visibles2 = $$v
            },
            expression: "visibles2"
          }
        },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columns1,
              "data-source": _vm.column1List,
              pagination: false,
              rowKey: function(record) {
                return record.id
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }