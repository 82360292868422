var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "xq-auto-search",
          staticStyle: { "margin-bottom": "20px" }
        },
        [
          _vm._v(" 字段名称 : "),
          _c("a-input", {
            staticStyle: { width: "200px", "margin-right": "20px" },
            attrs: { placeholder: "请输入", allowClear: "" },
            on: { change: _vm.serchEnter },
            model: {
              value: _vm.fieldName,
              callback: function($$v) {
                _vm.fieldName = $$v
              },
              expression: "fieldName"
            }
          })
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.data,
          rowKey: "id",
          pagination: _vm.paginationOpt
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function(text, record) {
              return _c("span", {}, [_vm._v(_vm._s(record.fieldVO.name))])
            }
          },
          {
            key: "required",
            fn: function(text, record) {
              return _c(
                "span",
                {},
                [
                  _c("a-checkbox", {
                    attrs: { defaultChecked: record.required },
                    on: {
                      change: function($event) {
                        return _vm.onChange($event, record)
                      }
                    }
                  })
                ],
                1
              )
            }
          },
          {
            key: "enable",
            fn: function(text, record) {
              return _c(
                "span",
                {},
                [
                  _c("a-checkbox", {
                    attrs: { defaultChecked: record.enable },
                    on: {
                      change: function($event) {
                        return _vm.onChange1($event, record)
                      }
                    }
                  })
                ],
                1
              )
            }
          },
          {
            key: "redact",
            fn: function(text, record) {
              return _c(
                "span",
                {},
                [
                  _c("a-checkbox", {
                    attrs: { defaultChecked: record.redact },
                    on: {
                      change: function($event) {
                        return _vm.onChange2($event, record)
                      }
                    }
                  })
                ],
                1
              )
            }
          },
          {
            key: "rule",
            fn: function(text, record) {
              return _c(
                "span",
                {},
                [
                  _c("a-checkbox", {
                    attrs: { defaultChecked: record.rule },
                    on: {
                      change: function($event) {
                        return _vm.onChange3($event, record)
                      }
                    }
                  })
                ],
                1
              )
            }
          },
          {
            key: "type",
            fn: function(text, record) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { value: text },
                      on: {
                        change: function($event) {
                          return _vm.handleChange($event, record)
                        }
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v(" 单行文本输入框 ")
                      ]),
                      _c("a-select-option", { attrs: { value: 6 } }, [
                        _vm._v(" 多行文本输入框 ")
                      ]),
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(" 数字输入框 ")
                      ]),
                      _c("a-select-option", { attrs: { value: 3 } }, [
                        _vm._v(" 日期选择器 ")
                      ]),
                      _c("a-select-option", { attrs: { value: 4 } }, [
                        _vm._v(" 下拉选择器 ")
                      ]),
                      _c("a-select-option", { attrs: { value: 5 } }, [
                        _vm._v(" 地区选择器 ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }