import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
import { listUserApprosApprovedByReceiver as retrieveReq, allGroupAndFlowByCompanyUser, allCanUseByFlow, getFlowProcessByApproInfo, customerInfos, receiver, search, batch } from "./api";
import { updateOrderInfos } from "@/views/Customer/api";
import { formatDate } from "./date";
import RecordForm from "./FormApproval.json";
import { CurdMixin } from "xqjr-plugin-common";
import IDCardCell from "xqjr-plugin-common/AutoGenerate/TableCell/IDCardCell";
import axios from "axios"; // ----------------------------------------------------- 构建表格配置，包括基本项、操作

var columns = RecordForm.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
});
columns.push({
  width: 80,
  title: "操作",
  dataIndex: "crudAction",
  scopedSlots: {
    customRender: "crudAction"
  },
  type: "action",
  fixed: "right"
}); // ----------------------------------------------------- 生成 mixin

var mixin = new CurdMixin({
  retrieveReq: retrieveReq,
  RecordForm: RecordForm,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

export default {
  components: {},
  filters: {
    filterType: function filterType(val) {
      if (val === 1) {
        return "分销";
      } else if (val === 2) {
        return "直销";
      } else {
        return "";
      }
    }
  },
  mixins: [mixin],
  data: function data() {
    return {
      confirmAlert: false,
      confirmAlerts: false,
      confirmAlertl: false,
      flowId: "",
      userApproId: "",
      orderInfoId: "",
      peoApplit: [],
      formData: [],
      approvalInfo: [],
      name: "",
      phone: "",
      id: "",
      sort: 0,
      pageSize: 20,
      userFlowApproverQuerys: [],
      form: {
        customerName: "",
        phone: "",
        remark: ""
      }
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          moduleType: process.env.VUE_APP_SUBJECT_TYPE,
          hasAppro: false
        }
      };
    }
  },
  created: function created() {
    this.getGroups();
  },
  mounted: function mounted() {},
  methods: {
    // 根据选中中来push数据
    onSelectChange: function onSelectChange(selectedRowKeys, selectedRows) {
      var _this = this;

      this.selectedRowKeys = selectedRowKeys;
      this.userFlowApproverQuerys = [];

      if (selectedRows.length === 0) {
        this.userFlowApproverQuerys = [];
      } else {
        selectedRows.map(function (item) {
          if (item.orderInfoVO.userId == null && item.orderInfoVO.bankUserId == null && item.orderInfoVO.riskUserId == null) {
            _this.sort = 1;
          } else if (item.orderInfoVO.bankUserId == null && item.orderInfoVO.riskUserId == null) {
            _this.sort = 2;
          } else if (item.orderInfoVO.bankUserId == null) {
            _this.sort = 3;
          }

          _this.userFlowApproverQuerys.push({
            flowId: item.userApproNodeInfoVO.flowId,
            userApproId: item.userApproNodeInfoVO.userApproId,
            sort: _this.sort
          });
        });
      }
    },
    // 打开弹窗
    approvalRow: function approvalRow() {
      this.confirmAlertl = true;
    },
    // 批量领办
    confirmHandlesl: function confirmHandlesl() {
      var _this2 = this;

      batch(this.userFlowApproverQuerys).then(function (res) {
        if (res.code === 200) {
          _this2.$notification["success"]({
            message: "批量领办成功"
          });

          _this2.userFlowApproverQuerys = [];
          _this2.selectedRowKeys = [];
          _this2.confirmAlertl = false;

          _this2.$emit("confirmHandlesList");

          _this2.retrieveRecord();
        }
      });
    },
    // 领办
    setting: function setting(record) {
      if (record.orderInfoVO.userId == null && record.orderInfoVO.bankUserId == null && record.orderInfoVO.riskUserId == null) {
        this.sort = 1;
      } else if (record.orderInfoVO.bankUserId == null && record.orderInfoVO.riskUserId == null) {
        this.sort = 2;
      } else if (record.orderInfoVO.bankUserId == null) {
        this.sort = 3;
      }

      this.confirmAlerts = true;
      this.flowId = record.userApproNodeInfoVO.flowId;
      this.userApproId = record.userApproNodeInfoVO.userApproId;
      this.orderInfoId = record.orderInfoVO.id;
    },
    confirmHandles: function confirmHandles() {
      var _this3 = this;

      receiver({
        flowId: this.flowId,
        userApproId: this.userApproId,
        sort: this.sort
      }).then(function (res) {
        if (res.code === 200) {
          _this3.$notification["success"]({
            message: "领办成功"
          });

          _this3.retrieveRecord();

          _this3.selectedRowKeys = [];
          _this3.confirmAlerts = false;
          _this3.userFlowApproverQuerys = [];

          _this3.$emit("confirmHandlesList"); // updateOrderInfos({
          //   id: this.orderInfoId,
          //   approvalStatus: "已领办未处理"
          // }).then(res => {
          //   if (res.code === 200) {
          //     this.$notification["success"]({ message: "领办成功" });
          //     this.retrieveRecord();
          //     this.confirmAlerts = false;
          //   }
          // });

        } else {
          _this3.$notification["warning"]({
            message: "领办失败"
          });
        }
      });
    },
    // 订单
    orderInfo: function orderInfo() {
      this.confirmAlert = true;
      this.form = {};
    },
    // 获取审批分组
    getGroups: function getGroups() {
      var _this4 = this;

      this.peoApplit = [];
      var companyId = localStorage.getItem("lesseeId");
      allGroupAndFlowByCompanyUser("".concat(companyId), "".concat(process.env.VUE_APP_SUBJECT_TYPE)).then(function (res) {
        res.data.forEach(function (item) {
          if (item.approFlowList.length >= 1) {
            _this4.peoApplit.push(item);

            _this4.id = _this4.peoApplit[0].approFlowList[0].id;
          }
        });

        if (_this4.id) {
          allCanUseByFlow(_this4.id).then(function (res) {
            _this4.formData = res.data;
          });
        }
      });
    },
    // 新建订单
    orderHandles: function orderHandles() {
      var _this5 = this;

      this.approvalInfo = this.formData.map(function (val) {
        return {
          formId: val.id,
          value: val.value
        };
      });
      getFlowProcessByApproInfo({
        moduleType: process.env.VUE_APP_SUBJECT_TYPE,
        flowId: this.formData[0].flowId,
        userApproInfoList: this.approvalInfo
      }).then(function (res) {
        if (res.success) {
          _this5.submit = false;
          _this5.flowNodeList = res.data;
          _this5.flowId = res.data[0].flowId;
          _this5.rebackData = res.data;
          var userApproAddDTO = {
            moduleType: process.env.VUE_APP_SUBJECT_TYPE,
            flowNodeList: _this5.rebackData,
            flowId: _this5.flowId,
            userApproInfoList: _this5.approvalInfo
          };
          _this5.form.userApproAddDTO = userApproAddDTO;
          customerInfos(_this5.form).then(function (data) {
            _this5.id = data.data.id;

            _this5.$message.success("创建成功");

            _this5.confirmAlert = false;

            _this5.retrieveRecord();
          });
        }
      });
    },
    serchEnter: function serchEnter() {
      var _this6 = this;

      var params = {
        customerName: this.name,
        phone: this.phone
      };
      search(params).then(function (res) {
        _this6.tableData = res.data.entities;
        _this6.total = _this6.tableData.length;
      });
    }
  }
};