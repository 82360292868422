//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "",
  inject: ["reload"],
  components: {},
  props: {
    newGroupOptions: {
      type: Object,
      default: function _default() {
        return {
          show: false,
          obj: {}
        };
      }
    }
  },
  data: function data() {
    return {
      visible: false,
      obj: {
        moduleType: "hr",
        groupName: "",
        groupDesc: "" // companyId: this.$store.state.hr.companyId

      }
    };
  },
  computed: {},
  watch: {
    newGroupOptions: {
      handler: function handler(val) {
        this.visible = val.show;
        val.obj.moduleType = "hr";
        this.obj = val.obj;
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {// 删除分组
    // deleteGroup(item) {
    //   this.$confirm({
    //     title: '确定删除?',
    //     okText: '确定',
    //     okType: 'danger',
    //     cancelText: '取消',
    //     onOk: () => {
    //       Project({
    //         url: ProjectApi.postGroupList + `/${item.id}`,
    //         method: "DELETE"
    //       }).then(data => {
    //         if (data.success) {
    //           this.$notification["success"]({
    //             message: "删除成功"
    //           });
    //           this.visible = false;
    //           /*  this.$parent.getGroupList() */
    //           this.reload();
    //         }
    //       });
    //     }
    //   });
    // },
    // saveData() {
    //   if (this.obj.id) {
    //     Project({
    //       url: ProjectApi.postGroupList,
    //       method: "PUT",
    //       data: this.obj
    //     }).then(data => {
    //       if (data.success) {
    //         this.$notification["success"]({
    //           message: "修改成功"
    //         });
    //         /*  this.$parent.getGroupList() */
    //         this.reload();
    //       }
    //     });
    //   } else {
    //     Project({
    //       url: ProjectApi.postGroupList,
    //       method: "POST",
    //       data: this.obj
    //     }).then(data => {
    //       if (data.success) {
    //         this.$notification["success"]({
    //           message: "新增成功"
    //         });
    //         /* this.$parent.getGroupList() */
    //         this.reload();
    //       }
    //     });
    //   }
    //   this.visible = false;
    // }
  }
};