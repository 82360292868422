var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            labelCol: { span: 6 },
            wrapperCol: { span: 18 }
          }
        },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["amount"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["loanType"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["deadline"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["interestRate"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["loanToDo"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c("a-divider", [_vm._v("授信用途")]),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["loanReason"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["fundMeasure"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 22 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["loanAmount"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["riskControlMethod"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["riskControlMethod2"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["loanToDoDetail"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      item: _vm.formFields["returnResource"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c("a-divider", [_vm._v("结论")]),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.tempRecords,
                      item: _vm.formFields["operateRisk"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.tempRecords,
                      item: _vm.formFields["guaranteeRisk"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.tempRecords,
                      item: _vm.formFields["moralRisk"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                {
                  staticStyle: { "margin-bottom": "140px" },
                  attrs: { span: 12 }
                },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.tempRecords,
                      item: _vm.formFields["conclusion"],
                      editable: _vm.editable
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }