import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderInfos, agencyBatch } from "./api";
import { formatDate } from "@/utils/date";
var columns = [{
  title: "进件编号",
  dataIndex: "incomingNum",
  scopedSlots: {
    customRender: "incomingNum"
  }
}, {
  title: "用户姓名",
  dataIndex: "name",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "当前状态",
  dataIndex: "overStatus",
  scopedSlots: {
    customRender: "overStatus"
  }
}, {
  title: "放款金额",
  dataIndex: "amount",
  scopedSlots: {
    customRender: "amount"
  }
}, {
  title: "本期应还",
  dataIndex: "repayment",
  scopedSlots: {
    customRender: "repayment"
  }
}, {
  title: "本期实还",
  dataIndex: "yetRepayment",
  scopedSlots: {
    customRender: "yetRepayment"
  }
}, {
  title: "查看",
  dataIndex: "tableAction",
  scopedSlots: {
    customRender: "tableAction"
  }
}];
export default {
  filters: {
    filterType: function filterType(val) {
      if (val === 0) {
        return "未逾期";
      } else if (val === 1) {
        return "已逾期";
      } else {
        return "";
      }
    }
  },
  data: function data() {
    var _this = this;

    return {
      columns: columns,
      statusType: "",
      dataList: [],
      selectedRowKeys: [],
      id: "",
      confirmAlerts: false,
      from: {
        customerName: "",
        orderRepaymentStatus: ""
      },
      paginationOpt: {
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 10,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;

          _this.list(); // 显示列表的接口名称

        },
        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;

          _this.list();
        }
      }
    };
  },
  created: function created() {
    this.list();
  },
  methods: {
    bathConfirm: function bathConfirm() {
      var _this2 = this;

      agencyBatch(this.selectedRowKeys).then(function (res) {
        _this2.selectedRowKeys = [];

        _this2.list();

        _this2.$message.success("一键代扣成功");
      });
    },
    changeHandele2: function changeHandele2() {},
    changeHandele1: function changeHandele1() {},
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    list: function list() {
      var _this3 = this;

      orderInfos(_objectSpread(_objectSpread({}, this.from), {}, {
        financeCheck: 1,
        pageNo: this.paginationOpt.defaultCurrent,
        pageSize: this.paginationOpt.defaultPageSize
      })).then(function (res) {
        _this3.dataList = res.data.entities;
        _this3.paginationOpt.total = res.data.entityCount;
      });
    },
    search: function search() {
      this.list();
    },
    show: function show(record) {
      this.$router.push("/terminalDetails/".concat(record.id));
    }
  }
};