import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import request from "@/utils/request";
export function retrieveReq(params) {
  return request({
    url: "/finance/api/v1/housingValuations",
    method: "GET",
    params: params
  });
} // 查询订单信息带房屋估值和风控查询详情

export function orderInfos(params) {
  return request({
    url: "finance/api/v1/orderInfos",
    method: "GET",
    params: params
  });
} // 已完结

export function orderInfoOver(data) {
  return request({
    url: "finance/api/v1/orderInfos",
    method: "PUT",
    data: _objectSpread(_objectSpread({}, data), {}, {
      financeCheck: 1
    })
  });
} // 个人进行中

export function finish(params) {
  return request({
    url: "/finance/api/v1/orderInfos/finish/user",
    method: "GET",
    params: params
  });
}