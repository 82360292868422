import { render, staticRenderFns } from "./baseinfoCustomer.vue?vue&type=template&id=dfa4e9ce&scoped=true&"
import script from "./baseinfoCustomer.vue?vue&type=script&lang=js&"
export * from "./baseinfoCustomer.vue?vue&type=script&lang=js&"
import style0 from "./baseinfoCustomer.vue?vue&type=style&index=0&id=dfa4e9ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfa4e9ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/prod-e-loan/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dfa4e9ce')) {
      api.createRecord('dfa4e9ce', component.options)
    } else {
      api.reload('dfa4e9ce', component.options)
    }
    module.hot.accept("./baseinfoCustomer.vue?vue&type=template&id=dfa4e9ce&scoped=true&", function () {
      api.rerender('dfa4e9ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Customer copy/detail/baseinfoCustomer.vue"
export default component.exports